import { Component } from '@angular/core';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { RelatorioComponent } from '../relatorio.component';
import { Filtros } from './acessorio/filtro';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import totais from './acessorio/total';
import grupos from './acessorio/grupo';

@Component({
  templateUrl: './r2004.component.html',
})
export class R2004Component extends RelatorioComponent {
  public filtros: Filtro[] = new Filtros().filtros;
  public grupos = grupos;
  public roteiroContabeis: any[] = [];
  public roteiroContabilRateios: any[] = [];
  public menuCategoriaRoteiroContabilApelido: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 157).apelido;
  public usaLancamentos: boolean = false;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.roteiroContabeis = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RoteiroContabil').lista;
      this.roteiroContabilRateios = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'RoteiroContabilRateio').lista;
      this.vincular();
      this.ordenarAgrupar(this.roteiroContabeis);
      this.ehImprimirRelatorio();
    });
  }

  vincular(): void {
    this.roteiroContabeis.forEach((roteiroContabil) => {
      roteiroContabil.roteiroContabilRateios = this.roteiroContabilRateios.filter((roteiroContabilRateio) => roteiroContabilRateio.idRoteiroContabil == roteiroContabil.id);
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaLancamentos = this.definirRegra(relatorioAdicional, 280, this.usaLancamentos);
  }
}
