import { Component, Input } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { UtilNotificacao } from 'src/app/utilitario/util.notificacao';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibDialogo from 'src/app/biblioteca/bibDialogo';

@Component({
  selector: '<tr>[rodapeTotal]</tr>',
  templateUrl: './rodapeTotal.component.html',
  styleUrls: ['./rodapeTotal.component.css'],
})
export class RodapeTotalComponent {
  @Input() i: number;
  @Input() relatorioGrupo: RelatorioGrupo;
  @Input() rodapePagina: boolean = false;
  @Input() ehAgrupadoOcultado: boolean;
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public bibIcone = bibIcone;
  public bibDialogo = bibDialogo;

  constructor(private utilSessao: UtilSessao, private utilNotificacao: UtilNotificacao) { }

  ngOnInit(): void {
    this.utilNotificacao.ehAgrupaOcultado.subscribe((res) => {
      this.ehAgrupadoOcultado = res;
    });
  }
}
