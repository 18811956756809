import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';

const grupos: Grupo[] = [
  { rotulo: bibDialogo.vendedor, campo: 'vendedor' },
  { rotulo: bibDialogo.cliente, campo: 'cliente' },
  { rotulo: bibDialogo.gestor, campo: 'gestor' },
];

export default grupos;
