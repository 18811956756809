import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibIcone from 'src/app/biblioteca/bibIcone';
import bibImagem from 'src/app/biblioteca/bibImagem';
import { Criterio } from 'src/app/modelo/criterio';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { ParceiroContaContabilEspecifica } from 'src/app/modelo/parceiroContaContabilEspecifica';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';

@Component({
  selector: 'parceiroContaContabilEspecifica',
  templateUrl: './parceiroContaContabilEspecificaFrm.component.html',
})
export class ParceiroContaContabilEspecificaFrmComponent extends PaginaComponent {
  @Input() parceiroContasContabilEspecifica: ParceiroContaContabilEspecifica[] = [];
  @Input() excluirParceiroContasContabilEspecifica: number[] = [];
  @Input() edicaoParceiroContasContabilEspecifica: number[] = [];

  public estados: Estado[];
  public bibDialogo = bibDialogo;
  public bibIcone = bibIcone;
  public bibImagem = bibImagem;
  public focoLoja: number;
  public listaLojas: Loja[] = [];
  public criteriosEspecifica: Criterio[] = [new Criterio('EMPRESA_CONTA_CONTABIL_ESPECIFICA', 'S')];

  ngOnInit(): void {
    this.listaLojas = this.utilSessao.getLojas();
  }

  adicionar(): void {
    let parceiroContaContabilEspecifica = new ParceiroContaContabilEspecifica();
    this.parceiroContasContabilEspecifica.push(parceiroContaContabilEspecifica);
    //this.focoLoja = Math.random();
  }

  excluirParceiroContaContabilEspecifica(posicao: number, idParceiroContasContabilEspecifica: number): void {
    super.ehExcluir(posicao, this.parceiroContasContabilEspecifica, this.excluirParceiroContasContabilEspecifica, idParceiroContasContabilEspecifica);
  }

  setLoja(parceiroContaContabilEspecifica: ParceiroContaContabilEspecifica, loja: Loja): void {
    parceiroContaContabilEspecifica.idLoja = loja.id;
    parceiroContaContabilEspecifica.loja = loja.nomeFantasia;
  }

  editar(parceiroContaContabilEspecifica: ParceiroContaContabilEspecifica): void {
    if (this.edicaoParceiroContasContabilEspecifica.includes(parceiroContaContabilEspecifica.id)) {
      const posicao: number = this.edicaoParceiroContasContabilEspecifica.indexOf(parceiroContaContabilEspecifica.id, 0);
      this.edicaoParceiroContasContabilEspecifica.splice(posicao, 1);
    } else {
      this.edicaoParceiroContasContabilEspecifica.push(parceiroContaContabilEspecifica.id);
    }
  }
}
