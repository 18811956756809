<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [grupos]="grupos" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (campoGrupoEmt)="agruparRelatorio(movimentacaoProdutos, $event)" (agrupaTotalizadorEmt)="agruparTotalizador()" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
        <th *ngIf="agrupaLoja == false" class="r-0-5 clique" (click)="ordenar(movimentacaoProdutos, 'abreviacao')">{{ bibDialogo.loja }}</th>
        <th *ngIf="agrupaCliente == false" class="clique" [ngClass]="agrupaProduto ? 'r' : 'r-2-5'" (click)="ordenar(movimentacaoProdutos, 'cliente')">{{ bibDialogo.cliente }}</th>        
        <th class="r-1 text-right clique" (click)="ordenar(movimentacaoProdutos, 'dataNegociacao')">{{ bibDialogo.data + ' ' + bibDialogo.negociacao }}</th>
        <th class="r-0-5"></th>
        <th class="r-1 text-right clique" (click)="ordenar(movimentacaoProdutos, 'numero')">{{ bibDialogo.numero }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(movimentacaoProdutos, 'numeroFiscal')">{{ bibDialogo.numeroFiscal }}</th>
        <th *ngIf="agrupaProduto == false" class="r clique" (click)="ordenar(movimentacaoProdutos, 'produto')">{{ bibDialogo.produto }}</th>        
        <th class="r-0-5  clique" (click)="ordenar(movimentacaoProdutos, 'unidade')">{{ bibDialogo.unidadeAbreviacao }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(movimentacaoProdutos, 'quantidade')">{{ bibDialogo.quantidade }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(movimentacaoProdutos, 'quantidadeFaturada')">{{ bibDialogo.quantidadeFaturada }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(movimentacaoProdutos, 'quantidadePendenteFaturamento')">{{ bibDialogo.quantidadePendente }}</th>
        <th *ngIf="usaValorPendente" class="r-1 text-right">{{ bibDialogo.valorPendente }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos; let i = index">
          <a href="#" (click)="abrirModalProduto(movimentacaoProduto.idProduto)" *ngIf="agrupaProduto"><cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo></a>
          <cabecalhoGrupo *ngIf="agrupaProduto == false" [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
          <tr class="conteudo">
            <td *ngIf="agrupaLoja == false">{{ movimentacaoProduto.loja }}</td>
            <td *ngIf="agrupaCliente == false"><a href="#" (click)="abrirModalCliente(movimentacaoProduto.idCliente)">{{ movimentacaoProduto.cliente }}</a></td>
            <td class="text-right">{{ movimentacaoProduto.dataNegociacao | data }}</td>
            <td class="text-right"><atencao *ngIf="movimentacaoProduto.diaNegociacao > 0" [atencao]="movimentacaoProduto.diaNegociacao" [ajuda]="bibDialogo.dias + ' da ' + bibDialogo.negociacao.toLowerCase()" [tipo]="bibPropriedade.atencao.discreto"></atencao></td>
            <td *ngIf="movimentacaoProduto.idMovimentacao == null" class="text-right">{{ movimentacaoProduto.numero > 0 ? movimentacaoProduto.numero : '' }}</td>
            <td *ngIf="movimentacaoProduto.idMovimentacao" class="text-right"><a href="#" (click)="abrirModalMovimentacao(movimentacaoProduto.idMovimentacao)">{{ movimentacaoProduto.numero }}</a></td>
            <td *ngIf="movimentacaoProduto.idMovimentacaoFiscal == null" class="text-right">{{ movimentacaoProduto.numeroFiscal }}</td>
            <td *ngIf="movimentacaoProduto.idMovimentacaoFiscal" class="text-right"><a href="#" (click)="abrirModalMovimentacaoFiscal(movimentacaoProduto.idMovimentacaoFiscal)">{{ movimentacaoProduto.numeroFiscal }}</a></td>
            <td *ngIf="agrupaProduto == false"><a href="#" (click)="abrirModalProduto(movimentacaoProduto.idProduto)">{{ movimentacaoProduto.produto }}</a></td>
            <td>{{ movimentacaoProduto.unidade }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right">{{ movimentacaoProduto.quantidadeFaturada | monetario: this.empresaRegraCasasDecimais }}</td>
            <td class="text-right" [ngClass]="movimentacaoProduto.quantidadePendenteFaturamento == 0 ? 'tachado' : ''">{{ movimentacaoProduto.quantidadePendenteFaturamento | monetario: this.empresaRegraCasasDecimais }}</td>
            <td *ngIf="usaValorPendente" class="text-right" [ngClass]="movimentacaoProduto.quantidadePendenteFaturamento == 0 ? 'tachado' : ''">{{ movimentacaoProduto.valorPendenteFaturamento | monetario }}</td>
          </tr>
          <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" [ehAgrupadoOcultado]="ehAgrupadoOcultado"></tr>
        </ng-container>
      </tbody>
    <tfoot>
        <tr *ngIf="movimentacaoProdutos.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo" [rodapePagina]="true"></tr>
    </tfoot> 
</table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>