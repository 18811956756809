import { Type } from 'class-transformer';

export class ParceiroContaContabilEspecifica {
  @Type(() => Date)
  public data: Date = new Date();
  @Type(() => Date)
  public dataProxima: Date;
  public id: number;
  public idParceiro: number;
  public idLoja: number;
  public idContaContabil: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public loja: string;
  public contaContabil: string;

  public alterado: boolean = false;
}
