<ng-container *ngIf="ehAgrupadoOcultado && rodapePagina == false ? i >= 0 : true">
  <ng-container *ngIf="i == null || (relatorioGrupo.nomeCampoGrupo && (relatorioGrupo.lista[i][relatorioGrupo.nomeCampoGrupo] ? relatorioGrupo.lista[i][relatorioGrupo.nomeCampoGrupo] : '') != (relatorioGrupo.lista[i + 1] ? (relatorioGrupo.lista[i + 1][relatorioGrupo.nomeCampoGrupo] ? relatorioGrupo.lista[i + 1][relatorioGrupo.nomeCampoGrupo] : '') : ''))">
    <ng-container>
    <ng-container *ngFor="let linha of relatorioGrupo.linhas; let indiceLinha = index">
      <td *ngIf="ehAgrupadoOcultado && rodapePagina == false ? linha < 2 || linha >= (relatorioGrupo.totais[1].posicaoNova ? relatorioGrupo.totais[1].posicaoNova : relatorioGrupo.totais[1].posicao) : true" [ngClass]="[linha > 1 ? 'text-right' : '', ehAgrupadoOcultado ? 'linha-divisoria-td' : '']" class="espaco-topo" [colSpan]="ehAgrupadoOcultado && rodapePagina == false && linha == 1 ? (relatorioGrupo.totais[1].posicaoNova ? relatorioGrupo.totais[1].posicaoNova : relatorioGrupo.totais[1].posicao) - 1 : 1">
        <ng-container *ngFor="let total of relatorioGrupo.totais; let indice = index">
          <ng-container *ngIf="ehAgrupadoOcultado == null || ehAgrupadoOcultado == false || rodapePagina == false || (rodapePagina == true && indice > 0)">
            <span *ngIf="linha == (total.posicaoNova ? total.posicaoNova : total.posicao) && total.mostrar == true && relatorioGrupo.somaResultados" [ngClass]="[ehAgrupadoOcultado && rodapePagina == false || total.tipo == 'repetido' ? '' : 'border-top', ehModoClaro ? 'borda-preta' : 'borda-branca']">
              <icone *ngIf="indice > 0" class="float-left" [icone]="total.tipo == 'valorMaximo' ? bibIcone.setaCompletaCima : null" [mensagem]="bibDialogo.maiorAtraso"></icone>
              <span *ngIf="total.tipo != 'repetido' && (ehAgrupadoOcultado && rodapePagina == false ? indice > 0 : true)" [ngClass]="ehAgrupadoOcultado && rodapePagina == false ? '' : 'font-weight-bold'" [ngbTooltip]="total.descricao"> {{ relatorioGrupo.somaResultados.length > 0 ? (relatorioGrupo.somaResultados[i >= 0 ? i : relatorioGrupo.somaResultados.length - 1][total.posicao] ? (linha > 1 ? (relatorioGrupo.somaResultados[i >= 0 ? i : relatorioGrupo.somaResultados.length - 1][total.posicao] | monetario: (total.casasDecimais == 0 || total.casasDecimais ? total.casasDecimais : 2)) : relatorioGrupo.somaResultados[i >= 0 ? i : relatorioGrupo.somaResultados.length - 1][total.posicao]) : (0 | monetario)) : (0 | monetario) }}{{total.sufixo ? total.sufixo : ''}} </span>
              <span *ngIf="ehAgrupadoOcultado && rodapePagina == false && indice == 0 && (relatorioGrupo.totais[1].posicaoNova ? relatorioGrupo.totais[1].posicaoNova : relatorioGrupo.totais[1].posicao) > 4" [ngbTooltip]="total.descricao"> {{ relatorioGrupo.lista[i][relatorioGrupo.nomeCampoGrupo] ? (relatorioGrupo.nomeCampoGrupo.toUpperCase().indexOf('DATA') > -1 ? (relatorioGrupo.lista[i][relatorioGrupo.nomeCampoGrupo] | data) : relatorioGrupo.lista[i][relatorioGrupo.nomeCampoGrupo]) : '[' + relatorioGrupo.nomeCampoGrupo + ' EM BRANCO]' }}</span>
              <span *ngIf="total.tipo == 'repetido' && ehAgrupadoOcultado && rodapePagina == false && indice > 0" [ngClass]="ehAgrupadoOcultado && rodapePagina == false ? '' : 'font-weight-bold'" [ngbTooltip]="total.descricao"> {{ relatorioGrupo.somaResultados.length > 0 ? (relatorioGrupo.somaResultados[i >= 0 ? i : relatorioGrupo.somaResultados.length - 1][total.posicao] ? (linha > 1 ? relatorioGrupo.somaResultados[i >= 0 ? i : relatorioGrupo.somaResultados.length - 1][total.posicao] : relatorioGrupo.somaResultados[i >= 0 ? i : relatorioGrupo.somaResultados.length - 1][total.posicao]) : null) : null }}{{total.sufixo ? total.sufixo : ''}}</span>
              <ajuda *ngIf="total.campo == null && !relatorioGrupo.nomeCampoGrupo" class="ajuda-contador-registros naoImprimir" [ajuda]="bibDialogo.quantidadeDeRegistros"></ajuda>
            </span>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>
</ng-container>
