<ng-container class="border-bottom border-dark">
  <td class="p-0" [colSpan]="3">
    <table class="tabela-complementar">
      <tbody>
        <ng-container *ngFor="let roteiroContabil of roteiroContabeis">
          <tr>
            <td class="r-5"><atencao class="p-2" [atencao]="'D'" [ajuda]="bibDialogo.debito" [impressaoSimplificada]="true"></atencao>{{ roteiroContabil.numeracao + ' - ' + roteiroContabil.contaContabil }}</td>
            <td class="r-5"><atencao class="p-2" [atencao]="'C'" [ajuda]="bibDialogo.credito" [impressaoSimplificada]="true"></atencao>{{ roteiroContabil.numeracaoContrapartida + ' - ' + roteiroContabil.contaContabilContrapartida }}</td>
            <td class="r-1"><atencao class="p-2" [atencao]="roteiroContabil.tipoLancamentoContabil" [ajuda]="bibDialogo.tipo" [impressaoSimplificada]="true"></atencao></td>
            <td class="r-1  text-right">{{ roteiroContabil.percentual + '%' }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </td>
</ng-container>
