import { Component, HostListener, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Lote } from 'src/app/agrow/modelo/lote';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { ProdutoFormuladoDose } from 'src/app/agrow/modelo/produtoFormuladoDose';
import { ProdutoUnidade } from 'src/app/agrow/modelo/produtoUnidade';
import { TabelaPreco } from 'src/app/agrow/modelo/tabelaPreco';
import { TabelaPrecoConfiguracao } from 'src/app/agrow/modelo/tabelaPrecoConfiguracao';
import { PesquisaMovimentacaoComponent } from 'src/app/formulario/pesquisa/movimentacao/pesquisaMovimentacao.component';
import { PesquisaProdutoComponent } from 'src/app/formulario/pesquisa/produto/pesquisaProduto.component';
import { Criterio } from 'src/app/modelo/criterio';
import { Estoque } from 'src/app/modelo/estoque';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Imposto } from 'src/app/modelo/imposto';
import { Local } from 'src/app/modelo/local';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoChave } from 'src/app/modelo/movimentacaoChave';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoParceiro } from 'src/app/modelo/produtoParceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { Transporte } from 'src/app/modelo/transporte';
import { UnidadeParceiro } from 'src/app/modelo/unidadeParceiro';
import { MovimentacaoControlador } from 'src/app/pagina/movimentacao/frm/movimentacaoControlador';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UtilMovimentacaoImpostos } from 'src/app/utilitario/utilMovimentacaoImpostos';
import { MovimentacaoFrmComponent } from '../estrutura/movimentacaoFrm.component';
import { MovimentacaoProdutoImpostoFrmComponent } from './imposto/movimentacaoProdutoImpostoFrm.component';
import { TabelaPrecoModalComponent } from './tabelaPrecoModal/tabelaPrecoModal.component';
import { Equipamento } from 'src/app/agrow/modelo/equipamento';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

@Component({
  selector: 'movimentacaoProdutoFrm',
  templateUrl: './movimentacaoProdutoFrm.component.html',
  styleUrls: ['./movimentacaoProdutoFrm.component.css'],
})
export class MovimentacaoProdutoFrmComponent extends PaginaComponent {
  @Input() movimentacaoControlador: MovimentacaoControlador;
  @Input() focoIdSenha: number;
  private modalEmt: any;
  private modalPesquisaProdutoAberta: boolean;
  private movimentacaoProdutoIndice: number;
  public coluna: number;
  public componente: any;
  public criterios: Criterio[] = [];
  public descontoPercentualAcumulaDesconto: number = 0;
  public descontoValorAcumulaDesconto: number = 0;
  public focadoCampoMovimentacaoProduto: boolean = false;
  public focoIdVincular: number;
  public idProdutoPersonalizado = this.utilSessao.getEmpresa().idProdutoPersonalizado != null ? this.utilSessao.getEmpresa().idProdutoPersonalizado : 0;
  public imposto: Imposto = new Imposto();
  public linha: number = 0;
  public locais: Local[];
  public lojaLimitacoes: Loja[];
  public lotes: Lote[] = [];
  public modalVinculandoXml: boolean = false;
  public ordenando: Boolean = false;
  public ordens: any[];
  public quantidadeProdutoInicial: number = 0;
  public idMovimentacaoProOrigem: number;
  public paginacaoProduto: number = 20;
  public utilMovimentacaoImpostos: UtilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, this.casasDecimais);
  public produtoFormuladoPragaCulturas: ProdutoFormuladoDose[] = [];

  public estoque: any[] = [
    { id: 1, nome: this.bibDialogo.entrada.toUpperCase() },
    { id: -1, nome: this.bibDialogo.saida.toUpperCase() },
  ];

  public aplicacao: any[] = [
    { id: 1, nome: this.bibDialogo.terrestre.toUpperCase() },
    { id: 2, nome: this.bibDialogo.aerea.toUpperCase() },
    { id: 11, nome: this.bibDialogo.soloSulcoPlantio.toUpperCase() },
  ];

  public equipamentos: Equipamento[] = [];

  @Input()
  set casasDecimais(casasDecimais: any) {
    this.utilMovimentacaoImpostos = new UtilMovimentacaoImpostos(this.comunicacaoService, casasDecimais);
  }

  @HostListener('window:keyup', ['$event'])
  keyboardInput(event: KeyboardEvent): void {
    if (event.keyCode == this.bibAtalho.novo) {
      if (!this.movimentacaoControlador.transferenciaEntrada && this.movimentacaoControlador.movimentacao.idLoja && !this.movimentacaoControlador.bloqueado) {
        this.ehAbrirModalProduto();
      }
    }
    if (event.keyCode == this.bibAtalho.setaCima && this.focadoCampoMovimentacaoProduto) {
      event.preventDefault();
      this.atualizarLinhaColuna(this.linha - 1, this.coluna);
    }
    if (event.keyCode == this.bibAtalho.setaBaixo && this.focadoCampoMovimentacaoProduto) {
      event.preventDefault();
      this.atualizarLinhaColuna(this.linha + 1, this.coluna);
    }
  }

  ngOnInit(): void {
    this.casasDecimais = this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais;
    this.idMovimentacaoProOrigem = this.utilSessao.getIdentificacao('idProdutoOrigem')?.conteudo;
    this.modalEmt = this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      let ordem: number = 0;
      if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0) {
        for (const movimentacaoProduto of this.movimentacaoControlador.movimentacao.movimentacaoProdutos) {
          if (movimentacaoProduto.ordem > ordem) {
            ordem = movimentacaoProduto.ordem;
          }
        }
        ordem += 1;
      }

      if ((identificacao.nome == this.bibClasse.produto || identificacao.nome == 'ProdutoCodigoBarra') && (identificacao.conteudo && identificacao.conteudo.length > 0)) {
        let contagemFinalProduto: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length + identificacao.conteudo.length;
        setTimeout(() => this.utilSessao.iniciarProcesso());
        this.adicionarProdutoUmPorVez(identificacao.conteudo, 0, ordem, contagemFinalProduto);
        this.movimentacaoControlador.ehDescontoValor(this.movimentacaoControlador.movimentacao.descontoValor, false);
        this.movimentacaoControlador.totalizar();
        if (identificacao.nome == 'ProdutoCodigoBarra') {
          this.movimentacaoControlador.focoIdAdicionar = Math.random();
        } else {
          this.focoIdVincular = Math.random();
        }
        this.modalPesquisaProdutoAberta = false;
      }
      if (identificacao.nome == this.bibClasse.movimentacaoProduto) {
        if (this.modalVinculandoXml) {
          this.modalVinculandoXml = false;
          this.vincularMovimentacaoProdutoXml(this.plainToClass(MovimentacaoProduto, identificacao.conteudo));
        } else {
          let contagemFinal: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length + identificacao.conteudo.length;
          for (let i = 0; i < identificacao.conteudo.length; i++) {
            const movimentacaoProduto = identificacao.conteudo[i];
            this.movimentacaoControlador.atualizouValor = false;
            this.atualizarObservacaoMovimentacao(movimentacaoProduto);
            setTimeout(() => this.adicionarMovimentacaoProduto(this.plainToClass(MovimentacaoProduto, movimentacaoProduto, identificacao.conteudo), ordem + i, contagemFinal));
            if (movimentacaoProduto.idMovimentacaoProOrigem) {
              this.movimentacaoControlador.vinculoIdMovimentacaoProOrigens.push(movimentacaoProduto.idMovimentacaoProOrigem);
            }
          }
        }
        this.focoIdVincular = Math.random();
        this.listarMovimentacaoChaveFiscal();
      }
      if (identificacao.nome == this.bibClasse.movimentacaoProdutoImposto) {
        let movimentacaoProduto: MovimentacaoProduto = this.plainToClass(MovimentacaoProduto, identificacao.conteudo);
        if (movimentacaoProduto.cfopEmpresa) {
          this.movimentacaoControlador.movimentacao.movimentacaoProdutos[movimentacaoProduto.indice].cfopEmpresa = movimentacaoProduto.cfopEmpresa;
          if (this.movimentacaoControlador.cfopEmpresa.find((cfopEmpresa) => movimentacaoProduto.cfopEmpresa.id == cfopEmpresa.id) == undefined) {
            this.movimentacaoControlador.cfopEmpresa.push(movimentacaoProduto.cfopEmpresa);
          }
        }
        this.adicionarTemporario(this.utilMovimentacaoImpostos.ehPreenchidoImpostos(this.plainToClass(MovimentacaoProduto, movimentacaoProduto), this.movimentacaoControlador.entradaServico), this.movimentacaoControlador.movimentacao.movimentacaoProdutos);
        this.movimentacaoControlador.totalizar();
      }
      if (identificacao.nome == this.bibClasse.tabelaPrecoConfiguracao) {
        let tabelaPrecoConfiguracao: TabelaPrecoConfiguracao = this.plainToClass(TabelaPrecoConfiguracao, identificacao.conteudo);
        for (let indice = 0; indice < this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length; indice++) {
          if (indice == this.movimentacaoProdutoIndice) {
            this.movimentacaoProdutoIndice = null;
            this.movimentacaoControlador.movimentacao.movimentacaoProdutos[indice].idTabelaPrecoConfiguracao = tabelaPrecoConfiguracao.id;
            this.movimentacaoControlador.movimentacao.movimentacaoProdutos[indice].tabelaPrecoConfiguracao = tabelaPrecoConfiguracao;
            this.movimentacaoControlador.movimentacao.movimentacaoProdutos[indice].setValorUnitario(tabelaPrecoConfiguracao.valor, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
          }
        }
        this.movimentacaoControlador.totalizar();
      }
      if (identificacao.nome == this.bibDialogo.observacao) {
        if (!this.movimentacaoControlador.movimentacao.informacaoComplementar || !this.movimentacaoControlador.movimentacao.informacaoComplementar.includes(identificacao.conteudo)) {
          if (!this.movimentacaoControlador.movimentacao.informacaoComplementar) {
            this.movimentacaoControlador.movimentacao.informacaoComplementar = identificacao.conteudo;
          } else {
            this.movimentacaoControlador.movimentacao.informacaoComplementar += identificacao.conteudo;
          }
        }
        if (this.movimentacaoControlador.movimentacao.informacaoComplementar && this.movimentacaoControlador.movimentacao.informacaoComplementar.length > 400) {
          this.movimentacaoControlador.movimentacao.informacaoComplementar = this.movimentacaoControlador.util.truncarStringAdicionarReticencias(this.movimentacaoControlador.movimentacao.informacaoComplementar, 400);
        }
      }
    });
  }

  atualizarObservacaoMovimentacao(movimentacaoProduto: MovimentacaoProduto) {
    if (this.movimentacaoControlador.pedido && movimentacaoProduto.movimentacaoObservacao && movimentacaoProduto.movimentacaoObservacao.length > 0) {
      let observacao: string = "";
      if (this.movimentacaoControlador.movimentacao.observacao) {
        observacao = this.movimentacaoControlador.movimentacao.observacao;
      }
      if (observacao.indexOf(movimentacaoProduto.movimentacaoObservacao) < 0) {
        observacao += (observacao.length > 0 ? ' - ' : '') + movimentacaoProduto.movimentacaoObservacao;
        this.utilSessao.informacaoAlert = true;
        const resultado: Resultado = new Resultado(null, 'Observação atualizada conforme orçamento: ' + observacao.trim());
        this.utilSessao.setResultado(resultado);
      }
      this.movimentacaoControlador.movimentacao.observacao = observacao.trim();
    }
  }

  adicionarProdutoUmPorVez(produtos: any[], indice: number, ordem: number, contagemFinalProduto: number) {
    if (produtos.length > 1) {
      this.utilSessao.mensagemProcessandoNFe('Adicionando item ' + (indice + 1) + ' de ' + produtos.length + ' itens');
    }
    if (produtos.length > 0) {
      const produto: Produto = this.plainToClass(Produto, produtos[indice]);
      const movimentacaoProdutoNovo: MovimentacaoProduto = new MovimentacaoProduto();
      let valorUnitario: number = this.retornarValorUnitarioProduto(produto);
      this.adicionarProduto(produto).subscribe((res) => {
        let produtoUnidades: ProdutoUnidade[] = this.plainToClass(ProdutoUnidade, res) as any;
        if (produtoUnidades.length > 0) {
          movimentacaoProdutoNovo.produtoUnidades = produtoUnidades;
          movimentacaoProdutoNovo.produtoUnidades.push(this.movimentacaoControlador.adicionarUnidadeProduto(produto));
          produtoUnidades.forEach((produtoUnidade) => (produtoUnidade.nome = produtoUnidade.unidadeAbreviacao));
        }
        if (produto.usaLote == 'S') {
          this.ehListarLojaLimitacao();
          this.ehListarEstoque(produto.id, ordem, movimentacaoProdutoNovo);
        }
        if (produto.idProdutoFormulado) {
          movimentacaoProdutoNovo.idProdutoFormulado = produto.idProdutoFormulado;
          this.listarProdutoFormulado(movimentacaoProdutoNovo);
        }
        this.listarTabelaPrecoProduto(produto.idProduto, produto.idGrupo).subscribe((res) => {
          const tabelaPrecos: TabelaPreco[] = this.plainToClass(TabelaPreco, res) as any;
          this.setMovimentacaoProduto(movimentacaoProdutoNovo, produto.id, produto.nome, produto.abreviacao, produto.idEmpresa, produto.custoGerencial, produto.custoReposicao, produto.foto, produto.idUnidade, produto.idUnidade, produto.numero, this.movimentacaoControlador.saida ? produto.precoVenda : 0, produto.observacao, produto.mostrarDescricao, null, produto.quantidade ? produto.quantidade : 1, 'N', valorUnitario, 0, 0, 'N', produto.idLocal, produto.idTabelaPrecoConfiguracao, produto.tabelaPrecoConfiguracao, produto.pesoBruto, produto.tipo, produto.usaIcmsDesonerado, produto.custoNfe, tabelaPrecos.length > 0, this.util.coalesce(produto.margemLucro, this.util.coalesce(produto.grupoMargemLucro, 0), true), produto.pesoLiquido, ordem, contagemFinalProduto, null, 0.0, 0.0, 0.0, produto.idProdutoFormulado, null, null, null, null, null, null, null, null);
        });

        if (indice + 1 < produtos.length) {
          this.adicionarProdutoUmPorVez(produtos, indice + 1, ordem, contagemFinalProduto);
        } else {
          this.utilSessao.mensagemProcessandoNFe('');
          setTimeout(() => this.utilSessao.finalizarProcesso());
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.modalEmt != null) {
      this.modalEmt.unsubscribe();
    }
  }

  ehAbrirModalProduto(): void {
    if (this.movimentacaoControlador.movimentacao.idLoja) {
      if (this.movimentacaoControlador.usaNegociacao && (!this.movimentacaoControlador.movimentacao.negociacao || !this.movimentacaoControlador.movimentacao.parceiro)) {
        if (!this.movimentacaoControlador.movimentacao.parceiro) {
          alert(this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.selecioneParceiro, [this.movimentacaoControlador.tipoParceiro.toLowerCase()]));
        } else if (!this.movimentacaoControlador.movimentacao.negociacao) {
          alert(this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.selecioneNegociacao, [this.movimentacaoControlador.menuNegociacao.apelido.toLowerCase()]));
        }
      } else {
        if (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) {
          if (this.movimentacaoControlador.movimentacao.idParceiro == null) {
            this.utilSessao.setResultado(new Resultado(false, this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.selecioneParceiro, [this.movimentacaoControlador.tipoParceiro.toLowerCase()])));
            return;
          }
          if (this.movimentacaoControlador.movimentacao.idNaturezaOperacao == null) {
            alert(this.bibDialogo.selecioneNaturezaOperacao);
          } else {
            this.abrirModalProduto();
          }
        } else {
          this.abrirModalProduto();
        }
      }
    } else {
      alert(this.bibDialogo.selecioneALoja);
    }
  }

  abrirModalProduto(): void {
    this.ordenando = false;
    if (this.movimentacaoControlador.movimentacao.idLoja > 0 && this.movimentacaoControlador.movimentacao.idLoja != this.movimentacaoControlador.movimentacao.idLojaDestino) {
      this.quantidadeProdutoInicial = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
      this.utilSessao.setIdentificacao(new Identificacao('acrescimoPercentual', this.movimentacaoControlador.movimentacao.acrescimoPercentual));
      this.utilSessao.setIdentificacao(new Identificacao('compacto', this.movimentacaoControlador.compacto));
      this.utilSessao.setIdentificacao(new Identificacao('entrada', this.movimentacaoControlador.entrada));
      this.utilSessao.setIdentificacao(new Identificacao('idLoja', this.movimentacaoControlador.movimentacao.idLoja));
      this.utilSessao.setIdentificacao(new Identificacao('interna', this.movimentacaoControlador.interna));
      this.utilSessao.setIdentificacao(new Identificacao('mediaDiaPrazo', this.movimentacaoControlador.movimentacao.mediaDiaPrazo));
      this.utilSessao.setIdentificacao(new Identificacao('saida', this.movimentacaoControlador.saida));
      this.utilSessao.setIdentificacao(new Identificacao('retornaConsulta', true));
      this.utilSessao.setIdentificacao(new Identificacao('idCliente', this.movimentacaoControlador.movimentacao.idParceiro));
      this.utilSessao.setIdentificacao(new Identificacao('idColaborador', this.movimentacaoControlador.movimentacao.idColaborador));
      this.utilSessao.setIdentificacao(new Identificacao('orcamento', this.movimentacaoControlador.orcamento));
      this.utilSessao.setIdentificacao(new Identificacao('naturezaOperacaoUsaCustoReposicao', this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao));
      this.utilSessao.setIdentificacao(new Identificacao('clienteEspecial', this.movimentacaoControlador.clienteEspecial));
      this.utilSessao.setIdentificacao(new Identificacao('entradaServico', this.movimentacaoControlador.entradaServico));
      this.modalPesquisaProdutoAberta = true;
      this.componente = PesquisaProdutoComponent;
    } else {
      if (this.movimentacaoControlador.movimentacao.idLoja == this.movimentacaoControlador.movimentacao.idLojaDestino) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionarLojasDistintas));
      } else {
        alert(this.movimentacaoControlador.bibDialogo.selecioneLoja);
      }
    }
  }

  setDescontoPercentual(movimentacaoProduto: MovimentacaoProduto, descontoValor: number): void {
    movimentacaoProduto.setDescontoPercentual(descontoValor, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
    this.utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.totalizar();
  }

  setDescontoPercentualUnitario(movimentacaoProduto: MovimentacaoProduto, descontoValor: number): void {
    movimentacaoProduto.setDescontoPercentualUnitario(descontoValor, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
    this.utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.totalizar();
  }

  setDescontoValor(movimentacaoProduto: MovimentacaoProduto, descontoValor: number): void {
    movimentacaoProduto.setDescontoValor(descontoValor, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
    this.utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.totalizar();
  }

  setDescontoValorUnitario(movimentacaoProduto: MovimentacaoProduto, descontoValor: number): void {
    movimentacaoProduto.setDescontoValorUnitario(descontoValor, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
    this.utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.totalizar();
  }

  setQuantidade(movimentacaoProduto: MovimentacaoProduto, quantidade: number): void {
    movimentacaoProduto.setQuantidade(quantidade, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.ratearDescontoMovimentacaoProduto(this.movimentacaoControlador.movimentacao.descontoValor, false);
    this.utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.totalizar();
    this.movimentacaoControlador.calcularPesoBrutoLiquido();

    if (this.movimentacaoControlador.movimentacaoManual) {
      this.movimentacaoControlador.movimentacao.calcularBalanco();
    }
  }

  setMovimentacaoManual(movimentacaoProduto: MovimentacaoProduto, valor: number): void {
    movimentacaoProduto.estoqueFisico = valor;
    this.movimentacaoControlador.movimentacao.calcularBalanco();
  }

  setValorUnitario(movimentacaoProduto: MovimentacaoProduto, valorUnitario: number): void {
    movimentacaoProduto.setValorUnitario(valorUnitario, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
    if (this.movimentacaoControlador.usaDescontoUnitario && movimentacaoProduto.valorUnitarioTabela > 0) {
      const descontoValor: number = movimentacaoProduto.valorUnitarioTabela - movimentacaoProduto.getValorUnitario();
      if (descontoValor > 0) {
        this.setDescontoValorUnitario(movimentacaoProduto, descontoValor);
      }
    }
    this.movimentacaoControlador.ratearDescontoMovimentacaoProduto(this.movimentacaoControlador.movimentacao.descontoValor, false);
    this.utilMovimentacaoImpostos.atualizarBaseMovimentacaoProduto(movimentacaoProduto, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.saida);
    this.movimentacaoControlador.totalizar();
  }

  definirRegraBalanco(movimentacaoProduto: MovimentacaoProduto): void {
    const criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_LOJA', this.movimentacaoControlador.movimentacao.idLoja));
    this.listarEstoqueRefatorar(movimentacaoProduto, criterios).subscribe(() => {
      const quantidadeEstoqueFisico: number = this.getQuantidadeEstoqueFisico(movimentacaoProduto.estoques, this.movimentacaoControlador.movimentacao.idLoja);
      movimentacaoProduto.estoqueFisico = Math.sign((movimentacaoProduto.quantidadeBalanco ? movimentacaoProduto.quantidadeBalanco : 0) - quantidadeEstoqueFisico);
      const quantidade: number = Math.abs(quantidadeEstoqueFisico - movimentacaoProduto.quantidadeBalanco);
      movimentacaoProduto.setQuantidade(quantidade, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida);
      this.movimentacaoControlador.movimentacao.calcularBalanco();
    });
  }

  listarEstoqueRefatorar(movimentacaoProduto: MovimentacaoProduto, criterios: Criterio[] = []): Observable<any> {
    if (movimentacaoProduto.estoques == null) {
      criterios.push(new Criterio('IDS_PRODUTO', movimentacaoProduto.idProduto));
      const resposta = this.comunicacaoService.listar(new Transporte(criterios), this.movimentacaoControlador.bibServico.estoque);
      resposta.subscribe((res) => {
        movimentacaoProduto.estoques = this.plainToClass(Estoque, res) as any;
      });
      return resposta;
    }
    return of({});
  }

  getQuantidadeEstoqueFisico(estoques: Estoque[], idLoja: number): number {
    let estoque: Estoque;
    if (estoques != null) {
      estoque = estoques.find((estoque) => estoque.idLoja == idLoja);
    }
    if (estoque) {
      return estoque.quantidadeFisica;
    }
    return 0;
  }

  ehAbrirModalMovimentacao(modalVinculandoXml: boolean = false): void {
    if (this.movimentacaoControlador.ehValidoNomeConsumidorFinal()) {
      if (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) {
        if (this.movimentacaoControlador.movimentacao.idNaturezaOperacao == null) {
          alert(this.bibDialogo.selecioneNaturezaOperacao);
        } else {
          this.abrirModalMovimentacao(modalVinculandoXml);
        }
      } else {
        if (this.ehTransferenciaEntrada()) {
          this.abrirModalMovimentacao(modalVinculandoXml);
        }
      }
    }
  }

  ehTransferenciaEntrada(): boolean {
    if (this.movimentacaoControlador.transferenciaEntrada) {
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLoja);
      if (loja.dataInicioSistema == null) {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.lojaPossuiDataInicioSistema, [loja.nomeFantasia])));
        return false;
      }
    }
    return true;
  }

  abrirModalMovimentacao(modalVinculandoXml: boolean = false): void {
    if (this.movimentacaoControlador.usaNegociacao && (!this.movimentacaoControlador.movimentacao.negociacao || !this.movimentacaoControlador.movimentacao.parceiro)) {
      if (!this.movimentacaoControlador.movimentacao.parceiro) {
        alert(this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.selecioneParceiro, [this.movimentacaoControlador.tipoParceiro.toLowerCase()]));
      } else if (!this.movimentacaoControlador.movimentacao.negociacao) {
        alert(this.movimentacaoControlador.util.substituir(this.movimentacaoControlador.bibDialogo.selecioneNegociacao, [this.movimentacaoControlador.menuNegociacao.apelido.toLowerCase()]));
      }
    } else {
      this.ordenando = false;
      if ((this.movimentacaoControlador.movimentacao.nomeParceiro && this.movimentacaoControlador.movimentacao.nomeParceiro.length > 0) || this.movimentacaoControlador.transferenciaEntrada) {
        if (this.movimentacaoControlador.movimentacao.idLoja != this.movimentacaoControlador.movimentacao.idLojaDestino) {
          this.quantidadeProdutoInicial = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
          this.utilSessao.setIdentificacao(new Identificacao('compacto', this.movimentacaoControlador.compacto));
          this.utilSessao.setIdentificacao(new Identificacao('ehDevolucao', this.movimentacaoControlador.devolucao ? 'S' : 'N'));
          this.utilSessao.setIdentificacao(new Identificacao('ehTransferenciaEntrada', this.movimentacaoControlador.transferenciaEntrada ? 'S' : 'N'));
          this.utilSessao.setIdentificacao(new Identificacao('idLoja', this.movimentacaoControlador.nota ? null : this.movimentacaoControlador.movimentacao.idLoja));
          this.utilSessao.setIdentificacao(new Identificacao('idLojaDestino', this.movimentacaoControlador.movimentacao.idLojaDestino));
          this.utilSessao.setIdentificacao(new Identificacao('nota', this.movimentacaoControlador.nota ? 'S' : 'N'));
          this.utilSessao.setIdentificacao(new Identificacao('notaTerceiro', this.movimentacaoControlador.notaTerceiro ? 'S' : 'N'));
          this.utilSessao.setIdentificacao(new Identificacao('vinculo', this.movimentacaoControlador.vincularOperacao));
          this.utilSessao.setIdentificacao(new Identificacao('parceiro', this.movimentacaoControlador.movimentacao.classificacaoVinculaOutroParceiro ? null : this.movimentacaoControlador.movimentacao.idParceiro));
          this.utilSessao.setIdentificacao(new Identificacao('devolucao', this.movimentacaoControlador.devolucao));
          this.utilSessao.setIdentificacao(new Identificacao('modalVinculandoXml', modalVinculandoXml));
          this.utilSessao.setIdentificacao(new Identificacao('idLojaMovimentacao', this.movimentacaoControlador.movimentacao.idLoja));
          this.utilSessao.setIdentificacao(new Identificacao('saida', this.movimentacaoControlador.saida ? true : false));
          this.utilSessao.setIdentificacao(new Identificacao('pedido', this.movimentacaoControlador.pedido ? true : false));
          this.utilSessao.setIdentificacao(new Identificacao('consumidorFinal', this.movimentacaoControlador.movimentacao.consumidorFinal == 'S' ? true : false));
          this.utilSessao.setIdentificacao(new Identificacao('nomeParceiro', this.movimentacaoControlador.movimentacao.nomeParceiro));
          this.utilSessao.setIdentificacao(new Identificacao('naturezaOperacaoFinalidade', this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade));
          this.modalVinculandoXml = modalVinculandoXml;
          this.componente = PesquisaMovimentacaoComponent;
        } else {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecionarLojasDistintas));
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.preenchaNomeCliente));
      }
    }
  }

  consultarMovimentacaoOrigem(idMovimentacaoProOrigem: number): void {
    this.utilSessao.setIdentificacao(new Identificacao('idProdutoOrigem', idMovimentacaoProOrigem));
    if (idMovimentacaoProOrigem != null) {
      const criterios: Criterio[] = [new Criterio('ID_MOVIMENTACAO_ORIGEM', idMovimentacaoProOrigem), new Criterio('ID_LOJA', this.movimentacaoControlador.movimentacao.idLoja)];
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacao).subscribe((res) => {
        const movimentacao: Movimentacao = this.plainToClass(Movimentacao, res[0]);
        this.abrirModalMovimentacaoOrigem(movimentacao.id);
      });
    }
  }

  abrirModalMovimentacaoOrigem(idMovimentacaoOrigem: number): void {
    this.componente = this.util.abrirModal(MovimentacaoFrmComponent, [new Identificacao('objeto', idMovimentacaoOrigem)], null);
  }

  listarProdutoFormulado(movimentacaoProduto: MovimentacaoProduto): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', movimentacaoProduto.idProdutoFormulado)), this.bibServico.produtoFormulado).subscribe((res) => {
      movimentacaoProduto.classeToxicologica = this.plainToClass(ProdutoFormulado, res)[0].classeToxicologica;
      let grauToxicologica: string[] = movimentacaoProduto.classeToxicologica.split('-');
      movimentacaoProduto.grauToxicologica = grauToxicologica[0].trim();
    });
  }

  adicionarProduto(produto: Produto): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', produto.id)), this.bibServico.produtoUnidade);
  }

  retornarValorUnitarioProduto(produto: Produto): number {
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao != '2' && this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao != '3' && !this.movimentacaoControlador.entrada) {
      if (this.movimentacaoControlador.clienteEspecial && this.movimentacaoControlador.clienteEspecial.precificacao) {
        switch (this.movimentacaoControlador.clienteEspecial.precificacao) {
          case 1:
            return produto.custoReposicao;
          case 2:
            return produto.custoGerencial;
          case 3:
            return produto.custoNfe;
          case 4:
            return produto.precoVenda;
        }
      } else {
        return produto.precoVenda;
      }
    } else {
      if (this.movimentacaoControlador.pedido && this.movimentacaoControlador.entrada) {
        return produto.custoReposicao;
      } else {
        return this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao == '2' ? produto.custoReposicao : this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao == '3' ? produto.custoNfe : produto.precoVenda;;
      }
    }
  }

  vincularMovimentacaoProdutoXml(movimentacaoProdutos: MovimentacaoProduto[]): void {
    movimentacaoProdutos.forEach((movimentacaoProduto) => {
      let movimentacaoProdutoProdutoRepetidos: MovimentacaoProduto[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProdutoBusca) => movimentacaoProdutoBusca.idProduto == movimentacaoProduto.idProduto); if (movimentacaoProdutoProdutoRepetidos.length == 1) {
        let movimentacaoProdutoXml: MovimentacaoProduto = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.find((movimentacaoProdutoBusca) => movimentacaoProdutoBusca.idProduto == movimentacaoProduto.idProduto);
        if (movimentacaoProdutoXml) {
          movimentacaoProdutoXml.idMovimentacaoProOrigem = movimentacaoProduto.id;
          movimentacaoProdutoXml.financeiroGeradoVinculado = movimentacaoProduto.financeiroGerado;
          movimentacaoProdutoXml.setBloquearFinanceiroEstoque(this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro, this.movimentacaoControlador.financeiro, this.movimentacaoControlador.movimentacao.idOperacao);
          if (movimentacaoProduto.movimentacaoQuantidades.length == 0) {
            this.movimentacaoControlador.adicionarMovimentacaoQuantidade(movimentacaoProdutoXml, null);
            movimentacaoProdutoXml.definirRegraEstoque(this.movimentacaoControlador.estoqueComprado, this.movimentacaoControlador.estoqueFisico, this.movimentacaoControlador.estoqueReservado, this.movimentacaoControlador.estoqueTransferido, this.movimentacaoControlador.estoqueNFe, this.movimentacaoControlador.estoqueCarteira);
          }
        } else {
          this.movimentacaoControlador.movimentacao.movimentacaoProdutoFaltantes.push(movimentacaoProduto);
        }
      } else if (movimentacaoProdutoProdutoRepetidos.length > 1) {
        this.vincularMovimentacaoProdutoRepetidoXml(movimentacaoProdutoProdutoRepetidos, movimentacaoProduto);
      }
    });
    this.movimentacaoControlador.totalizar();
    // this.movimentacaoControlador.calcularDiferencaParcela(this.movimentacaoControlador.totalizarParcelas(), this.movimentacaoControlador.totalizarBloqueadoParcelas());
  }

  vincularMovimentacaoProdutoRepetidoXml(movimentacaoProdutoProdutoRepetidos: MovimentacaoProduto[], movimentacaoProduto: MovimentacaoProduto): void {
    let movimentacaoProdutoXmlRepetidos: MovimentacaoProduto[] = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.filter((movimentacaoProdutoBusca) => movimentacaoProdutoBusca.idProduto == movimentacaoProduto.idProduto);
    if (movimentacaoProdutoXmlRepetidos.length == movimentacaoProdutoProdutoRepetidos.length) {
      let movimentacaoProdutoXmlQuantidadeTotal: number = movimentacaoProdutoProdutoRepetidos.reduce((a, b) => a + b.getQuantidade(), 0);
      let movimentacaoProdutoQuantidadeTotal: number = movimentacaoProdutoProdutoRepetidos.reduce((a, b) => a + b.getQuantidade(), 0);
      if (movimentacaoProdutoXmlQuantidadeTotal == movimentacaoProdutoQuantidadeTotal) {
        movimentacaoProdutoXmlRepetidos.forEach((movimentacaoProdutoXml) => {
          movimentacaoProdutoXml.idMovimentacaoProOrigem = movimentacaoProduto.id;
          movimentacaoProdutoXml.financeiroGeradoVinculado = movimentacaoProduto.financeiroGerado;
          movimentacaoProdutoXml.setBloquearFinanceiroEstoque(this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro, this.movimentacaoControlador.financeiro, this.movimentacaoControlador.movimentacao.idOperacao);
          if (movimentacaoProduto.movimentacaoQuantidades.length == 0) {
            this.movimentacaoControlador.adicionarMovimentacaoQuantidade(movimentacaoProdutoXml, null);
            movimentacaoProdutoXml.definirRegraEstoque(this.movimentacaoControlador.estoqueComprado, this.movimentacaoControlador.estoqueFisico, this.movimentacaoControlador.estoqueReservado, this.movimentacaoControlador.estoqueTransferido, this.movimentacaoControlador.estoqueNFe, this.movimentacaoControlador.estoqueCarteira);
          }
        })
      }
    } else if (movimentacaoProdutoXmlRepetidos.length == 0) {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutoFaltantes.push(movimentacaoProduto);
    }
  }

  adicionarMovimentacaoProduto(movimentacaoProduto: MovimentacaoProduto, ordem: number, contagemFinal: number): void {
    let movimentacaoProdutoNovo: MovimentacaoProduto = new MovimentacaoProduto();
    let quantidade: number = 1;
    let casasDecimais: number = new UtilSessao().getEmpresa().regraCasasDecimais;
    const IDENTIFICACAO_PEDIDO: number = 2;
    if (this.movimentacaoControlador.devolucao) {
      quantidade = movimentacaoProduto.quantidadeEntregue - movimentacaoProduto.quantidadeDevolvida;
    } else if ((this.movimentacaoControlador.nota || movimentacaoProduto.identificacao == IDENTIFICACAO_PEDIDO) && !this.movimentacaoControlador.notaDevolucao) {
      quantidade = movimentacaoProduto.getQuantidade() - movimentacaoProduto.quantidadeFaturada + movimentacaoProduto.quantidadeCancelada - movimentacaoProduto.quantidadeDesfeita;
    } else if (this.movimentacaoControlador.transferenciaEntrada) {
      quantidade = movimentacaoProduto.quantidadePendente;
    } else {
      quantidade = movimentacaoProduto.getQuantidade();
    }
    movimentacaoProdutoNovo.icmsDesonerado = movimentacaoProduto.icmsDesonerado;
    let descontoTotalUnitario: number = 0;
    if (this.movimentacaoControlador.usaDescontoUnitario) {
      descontoTotalUnitario = movimentacaoProduto.descontoValorUnitario > 0 ? movimentacaoProduto.valorUnitarioTabela > movimentacaoProduto.getValorUnitario() ? movimentacaoProduto.valorUnitarioTabela - movimentacaoProduto.getValorUnitario() : movimentacaoProduto.descontoValorUnitario : (movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado) / movimentacaoProduto.getQuantidade();
    } else {
      descontoTotalUnitario = (movimentacaoProduto.descontoValor + movimentacaoProduto.descontoRateado) / movimentacaoProduto.getQuantidade();
    }
    let descontoTotal: number = this.movimentacaoControlador.util.arredondar(descontoTotalUnitario * quantidade, casasDecimais);
    let idMovimentacaoProOrigem: number = movimentacaoProduto.id;
    let identificacaoOrigem: number = movimentacaoProduto.identificacao;
    let valorUnitario: number = movimentacaoProduto.getValorUnitario();
    this.adicionarMovimentacaoQuantidade(movimentacaoProdutoNovo, movimentacaoProduto);
    const IDENTIFICACAO_ORCAMENTO: number = 1;
    if (movimentacaoProduto.identificacao == IDENTIFICACAO_ORCAMENTO) {
      idMovimentacaoProOrigem = null;
    }
    if (this.movimentacaoControlador.pedido && (idMovimentacaoProOrigem == null || identificacaoOrigem == IDENTIFICACAO_ORCAMENTO) && this.movimentacaoControlador.saida) {
      this.listarProduto(movimentacaoProduto.idProduto).subscribe((res) => {
        const produto: Produto = this.plainToClass(Produto, res[0]);
        valorUnitario = movimentacaoProduto.getValorUnitario();
        movimentacaoProduto.valorUnitarioTabela = produto.precoVenda;
        descontoTotal = 0;

        descontoTotal = this.movimentacaoControlador.util.arredondar(descontoTotalUnitario * quantidade, casasDecimais);
        if (produto.precoVenda > movimentacaoProduto.getValorUnitario()) {
          valorUnitario += produto.precoVenda - movimentacaoProduto.getValorUnitario();
          this.movimentacaoControlador.atualizouValor = true;
        }
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)), this.bibServico.produtoUnidade).subscribe((res) => {
          movimentacaoProduto.produtoUnidades = this.plainToClass(ProdutoUnidade, res) as any;
          movimentacaoProduto.produtoUnidades.push(this.movimentacaoControlador.adicionarUnidadeProduto(produto));
          movimentacaoProduto.produtoUnidades.forEach((unidade) => (unidade.nome = unidade.unidadeAbreviacao));

          this.listarTabelaPrecoProduto(produto.idProduto, produto.idGrupo).subscribe((res) => {
            const tabelaPrecos: TabelaPreco[] = this.plainToClass(TabelaPreco, res) as any;
            if (this.movimentacaoControlador.pedido && this.movimentacaoControlador.saida) {
              this.setMovimentacaoProduto(movimentacaoProdutoNovo, movimentacaoProduto.idProduto, movimentacaoProduto.produto, movimentacaoProduto.abreviacao, movimentacaoProduto.idEmpresa, movimentacaoProduto.custoGerencial, movimentacaoProduto.custoReposicao, movimentacaoProduto.foto, movimentacaoProduto.idUnidade, movimentacaoProduto.idUnidade, movimentacaoProduto.produtoNumero, movimentacaoProduto.valorUnitarioTabela, movimentacaoProduto.descricao, movimentacaoProduto.produtoMostrarDescricao, idMovimentacaoProOrigem, quantidade, movimentacaoProduto.financeiroGerado, valorUnitario, 0, descontoTotal, movimentacaoProduto.devolucao, null, null, null, movimentacaoProduto.produtoPesoBruto, movimentacaoProduto.produtoTipo, movimentacaoProduto.usaIcmsDesonerado, produto.custoNfe, tabelaPrecos.length > 0, movimentacaoProduto.margemLucro, movimentacaoProduto.produtoPesoLiquido, ordem, contagemFinal, identificacaoOrigem, movimentacaoProduto.getIcmsStValor(), movimentacaoProduto.icmsDesonerado, movimentacaoProduto.getIpiValor(), movimentacaoProduto.idProdutoFormulado, movimentacaoProduto.cultura, movimentacaoProduto.classeToxicologica, movimentacaoProduto.praga, movimentacaoProduto.numeroAplicacao, movimentacaoProduto.idAplicacao, movimentacaoProduto.aplicacao, movimentacaoProduto.idEquipamento, movimentacaoProduto.estagioCultura);
            } else {
              const valorUnitarioProduto: number = this.retornarValorUnitarioProduto(produto);
              this.setMovimentacaoProduto(movimentacaoProdutoNovo, movimentacaoProduto.idProduto, movimentacaoProduto.produto, movimentacaoProduto.abreviacao, movimentacaoProduto.idEmpresa, movimentacaoProduto.custoGerencial, movimentacaoProduto.custoReposicao, movimentacaoProduto.foto, movimentacaoProduto.idUnidade, movimentacaoProduto.idUnidade, movimentacaoProduto.produtoNumero, movimentacaoProduto.valorUnitarioTabela, movimentacaoProduto.descricao, movimentacaoProduto.produtoMostrarDescricao, idMovimentacaoProOrigem, quantidade, movimentacaoProduto.financeiroGerado, valorUnitarioProduto, 0, descontoTotal, movimentacaoProduto.devolucao, null, null, null, movimentacaoProduto.produtoPesoBruto, movimentacaoProduto.produtoTipo, movimentacaoProduto.usaIcmsDesonerado, produto.custoNfe, tabelaPrecos.length > 0, movimentacaoProduto.margemLucro, movimentacaoProduto.produtoPesoLiquido, ordem, contagemFinal, identificacaoOrigem, movimentacaoProduto.getIcmsStValor(), movimentacaoProduto.icmsDesonerado, movimentacaoProduto.getIpiValor(), movimentacaoProduto.idProdutoFormulado, movimentacaoProduto.cultura, movimentacaoProduto.classeToxicologica, movimentacaoProduto.praga, movimentacaoProduto.numeroAplicacao, movimentacaoProduto.idAplicacao, movimentacaoProduto.aplicacao, movimentacaoProduto.idEquipamento, movimentacaoProduto.estagioCultura);
            }
          });
        });
      });
    } else {
      this.listarProduto(movimentacaoProduto.idProduto).subscribe((res) => {
        const produto: Produto = this.plainToClass(Produto, res[0]);
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)), this.bibServico.produtoUnidade).subscribe((res) => {
          movimentacaoProdutoNovo.produtoUnidades = this.plainToClass(ProdutoUnidade, res) as any;
          movimentacaoProdutoNovo.produtoUnidades.push(this.movimentacaoControlador.adicionarUnidadeProduto(produto, produto.idUnidade == movimentacaoProduto.idUnidade ? 'S' : 'N'));
          movimentacaoProdutoNovo.produtoUnidades.forEach((unidade) => {
            unidade.nome = unidade.unidadeAbreviacao
          });
          this.setMovimentacaoProduto(movimentacaoProdutoNovo, movimentacaoProduto.idProduto, movimentacaoProduto.produto, movimentacaoProduto.abreviacao, movimentacaoProduto.idEmpresa, movimentacaoProduto.custoGerencial, movimentacaoProduto.custoReposicao, movimentacaoProduto.foto, movimentacaoProduto.idUnidade, produto.idUnidade, movimentacaoProduto.produtoNumero, movimentacaoProduto.valorUnitarioTabela, movimentacaoProduto.descricao, movimentacaoProduto.produtoMostrarDescricao, idMovimentacaoProOrigem, quantidade, movimentacaoProduto.financeiroGerado, movimentacaoProduto.getValorUnitario(), 0, descontoTotal, movimentacaoProduto.devolucao, null, null, null, movimentacaoProduto.produtoPesoBruto, movimentacaoProduto.produtoTipo, movimentacaoProduto.usaIcmsDesonerado, movimentacaoProduto.custoNfe, false, movimentacaoProduto.margemLucro, movimentacaoProduto.produtoPesoLiquido, ordem, contagemFinal, identificacaoOrigem, movimentacaoProduto.getIcmsStValor(), movimentacaoProduto.icmsDesonerado, movimentacaoProduto.getIpiValor(), movimentacaoProduto.idProdutoFormulado, movimentacaoProduto.cultura, movimentacaoProduto.classeToxicologica, movimentacaoProduto.praga, movimentacaoProduto.numeroAplicacao, movimentacaoProduto.idAplicacao, movimentacaoProduto.aplicacao, movimentacaoProduto.idEquipamento, movimentacaoProduto.estagioCultura);
        })
      });
    }


  }

  adicionarMovimentacaoQuantidade(movimentacaoProdutoNovo: MovimentacaoProduto, movimentacaoProduto: MovimentacaoProduto): void {
    movimentacaoProduto.movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
      let movimentacaoQuantidadeNova: MovimentacaoQuantidade = new MovimentacaoQuantidade();
      movimentacaoQuantidadeNova.idEmpresa = movimentacaoQuantidade.idEmpresa;
      movimentacaoQuantidadeNova.idMovimentacaoProduto = movimentacaoQuantidade.idMovimentacaoProduto;
      movimentacaoQuantidadeNova.status = 'N';
      if (this.movimentacaoControlador.transferencia) {
        movimentacaoQuantidadeNova.idLoja = this.movimentacaoControlador.movimentacao.idLoja;
        movimentacaoQuantidadeNova.idLocal = null;
      } else {
        movimentacaoQuantidadeNova.idLoja = movimentacaoQuantidade.idLoja;
        movimentacaoQuantidadeNova.idLocal = movimentacaoQuantidade.idLocal;
      }
      if (!this.movimentacaoControlador.pedido) {
        movimentacaoQuantidadeNova.idMovimentacaoQuaOrigem = movimentacaoQuantidade.id;
      }
      movimentacaoQuantidadeNova.idLote = movimentacaoQuantidade.idLote;
      movimentacaoQuantidadeNova.quantidade = movimentacaoQuantidade.quantidade;
      movimentacaoQuantidadeNova.referencia = movimentacaoQuantidade.referencia;
      movimentacaoProdutoNovo.movimentacaoQuantidades.push(movimentacaoQuantidadeNova);
    });
  }

  calcularDescontoPercentualFinal(descontoPercentual: number, movimentacaoProduto: MovimentacaoProduto, valorUnitario: number): number {
    const valorUnitarioVinculadoFinal: number = movimentacaoProduto.getValorUnitario() - movimentacaoProduto.getValorUnitario() * (descontoPercentual * 0.01);
    if (valorUnitario > movimentacaoProduto.getValorUnitario()) {
      descontoPercentual = this.util.arredondar((1 - valorUnitarioVinculadoFinal / valorUnitario) * 100);
    } else {
      if (valorUnitario < movimentacaoProduto.getValorUnitario()) {
        if (valorUnitario > valorUnitarioVinculadoFinal) {
          descontoPercentual = this.util.arredondar((1 - valorUnitarioVinculadoFinal / valorUnitario) * 100);
        } else {
          descontoPercentual = 0;
        }
      }
    }
    return descontoPercentual;
  }

  listarProduto(idProduto: number): Observable<any> {
    const criterios: Criterio[] = [new Criterio('ID', idProduto), new Criterio('ID_LOJA', this.movimentacaoControlador.movimentacao.idLoja)];
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produto);
  }

  listarCulturas(movimentacaoProduto: MovimentacaoProduto): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO_FORMULADO', movimentacaoProduto.idProdutoFormulado)), this.bibServico.produtoFormuladoDose).subscribe((res) => {
      movimentacaoProduto.produtoFormuladoDoses = this.plainToClass(ProdutoFormuladoDose, res) as any;
      let produtoFormuladoCulturas: ProdutoFormuladoDose[] = this.criarProdutoFormuladoCulturas(movimentacaoProduto);
      movimentacaoProduto.culturas = this.criarListaCultura(produtoFormuladoCulturas);
      if (movimentacaoProduto.cultura) {
        movimentacaoProduto.idCultura = movimentacaoProduto.culturas.find((cultura) => cultura.nome.toUpperCase() == movimentacaoProduto.cultura.toUpperCase()).id;
        let produtoFormuladoPragas: ProdutoFormuladoDose[] = this.criarProdutoFormuladoPragas(movimentacaoProduto, movimentacaoProduto.cultura);
        movimentacaoProduto.pragas = this.criarListaPraga(produtoFormuladoPragas);
        if (movimentacaoProduto.praga) {
          movimentacaoProduto.idPraga = movimentacaoProduto.pragas.find((praga) => praga.nome.toUpperCase() == movimentacaoProduto.praga.toUpperCase()).id;
        }
      }
    });
  }

  abrirInformacoesReceituario(movimentacaoProduto: MovimentacaoProduto) {
    if (movimentacaoProduto.receituario == null) {
      if (movimentacaoProduto.idProduto > 0) {
        movimentacaoProduto.expandidoCultura = !movimentacaoProduto.expandidoCultura;
      }
      if (movimentacaoProduto.expandidoCultura) {
        this.listarCulturas(movimentacaoProduto);
        if (this.equipamentos.length == 0) {
          this.listarEquipamentos();
        }
        if (movimentacaoProduto.aplicacao) {
          movimentacaoProduto.idAplicacao = movimentacaoProduto.aplicacao.toUpperCase() == this.bibDialogo.terrestre.toUpperCase() ? 1 : (movimentacaoProduto.aplicacao.toUpperCase() == this.bibDialogo.soloSulcoPlantio.toUpperCase() ? 11 : 2);
        }
      }
    }
  }

  setMovimentacaoProduto(movimentacaoProduto: MovimentacaoProduto, idProduto: number, produto: string, abreviacao: string, idEmpresa: number, custoGerencial: number, custoReposicao: number, fotoQuantidade: string, idUnidade: number, idUnidadePadrao: number, produtoNumero: number, valorUnitarioTabela: number, descricao: string, mostrarDescricao: string, idMovimentacaoProOrigem: number, quantidade: number, financeiroGerado: string, valorUnitario: number, descontoPercentual: number, descontoTotal: number, devolucao, idLocal: number = null, idTabelaPrecoConfiguracao: number = null, tabelaPrecoConfiguracao: TabelaPrecoConfiguracao = null, pesoBruto: number = 0, produtoOuServico: string = 'P', usaIcmsDesonerado: string = 'N', custoNfe: number, apresentaTabelaPreco: boolean = false, margemLucro: number, pesoLiquido: number = 0, ordem: number, contagemFinal: number, identificacaoOrigem: number, icmsStValor: number, icmsDesonerado: number, ipiValor: number, idProdutoFormulado: number, cultura: string, classeToxicologica: string, praga: string, numeroAplicacao: number, idAplicacao: number, aplicacao: string, idEquipamento: number, estagioCultura: string): void {
    if (ordem) {
      movimentacaoProduto.ordem = ordem;
    } else {
      movimentacaoProduto.ordem = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
    }
    movimentacaoProduto.devolucaoSaida = this.movimentacaoControlador.devolucao && this.movimentacaoControlador.saida;
    movimentacaoProduto.idProduto = idProduto;
    movimentacaoProduto.produto = produto;
    movimentacaoProduto.produtoNome = movimentacaoProduto.idProduto == this.idProdutoPersonalizado ? null : produto;
    movimentacaoProduto.abreviacao = abreviacao;
    movimentacaoProduto.idEmpresa = idEmpresa;
    movimentacaoProduto.custoNfe = custoNfe;
    movimentacaoProduto.margemLucro = margemLucro;
    movimentacaoProduto.custoGerencial = custoGerencial;
    movimentacaoProduto.custoReposicao = custoReposicao;
    movimentacaoProduto.foto = fotoQuantidade;
    movimentacaoProduto.idUnidade = idUnidade;
    movimentacaoProduto.idUnidadePadrao = idUnidadePadrao;
    movimentacaoProduto.produtoNumero = produtoNumero;
    movimentacaoProduto.valorUnitarioTabela = this.util.arredondar(valorUnitarioTabela, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao == '2' || this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao == '3') {
      movimentacaoProduto.valorUnitarioTabela = this.util.arredondar(valorUnitario, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
    }
    movimentacaoProduto.idTabelaPrecoConfiguracao = idTabelaPrecoConfiguracao;
    movimentacaoProduto.tabelaPrecoConfiguracao = tabelaPrecoConfiguracao;
    movimentacaoProduto.produtoPesoBruto = pesoBruto;
    movimentacaoProduto.produtoPesoLiquido = pesoLiquido;
    movimentacaoProduto.produtoTipo = produtoOuServico;
    movimentacaoProduto.apresentaTabelaPreco = apresentaTabelaPreco;
    movimentacaoProduto.setIcmsStValor(icmsStValor);
    movimentacaoProduto.icmsDesonerado = icmsDesonerado;
    movimentacaoProduto.cultura = cultura;
    movimentacaoProduto.praga = praga;
    movimentacaoProduto.numeroAplicacao = numeroAplicacao;

    movimentacaoProduto.idAplicacao = idAplicacao;
    movimentacaoProduto.aplicacao = aplicacao;
    movimentacaoProduto.idEquipamento = idEquipamento;
    movimentacaoProduto.estagioCultura = estagioCultura;

    if (cultura && !this.movimentacaoControlador.mostrarAgronomo) {
      this.movimentacaoControlador.mostrarAgronomo = true;
    }
    movimentacaoProduto.idProdutoFormulado = idProdutoFormulado;
    movimentacaoProduto.classeToxicologica = classeToxicologica;
    if (movimentacaoProduto.classeToxicologica) {
      let grauToxicologica: string[] = movimentacaoProduto.classeToxicologica.split('-');
      movimentacaoProduto.grauToxicologica = grauToxicologica[0].trim();
    }
    movimentacaoProduto.setIpiValor(ipiValor);
    this.alertarDescricao(descricao, mostrarDescricao, movimentacaoProduto.produto);
    movimentacaoProduto.idMovimentacaoProOrigem = idMovimentacaoProOrigem;
    if (movimentacaoProduto.idMovimentacaoProOrigem != null) {
      movimentacaoProduto.identificacaoOrigem = identificacaoOrigem;
    }
    movimentacaoProduto.setQuantidade(quantidade, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida);
    movimentacaoProduto.financeiroGeradoVinculado = financeiroGerado;
    movimentacaoProduto.devolucaoVinculada = devolucao;
    movimentacaoProduto.descontoPorValor = this.movimentacaoControlador.entrada ? true : false;
    if (this.movimentacaoControlador.clienteEspecial) {
      this.atualizarValorUnitarioVenda(movimentacaoProduto, valorUnitario, quantidade, descontoTotal);
    } else {
      if (this.movimentacaoControlador.interna && !this.movimentacaoControlador.movimentacao.idNaturezaOperacao) {
        movimentacaoProduto.setValorUnitario(custoGerencial, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
      } else {
        movimentacaoProduto.setValorUnitario(valorUnitario, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
        if (this.movimentacaoControlador.usaDescontoUnitario) {
          if (movimentacaoProduto.descontoPercentual > 0) {
            movimentacaoProduto.setDescontoPercentualUnitario(movimentacaoProduto.descontoPercentual > 0 ? movimentacaoProduto.descontoPercentual : movimentacaoProduto.descontoPercentualUnitario, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
          } else {
            movimentacaoProduto.setDescontoValorUnitario(descontoTotal / movimentacaoProduto.quantidade, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
          }
          movimentacaoProduto.descontoPercentual = 0;
          movimentacaoProduto.descontoValor = 0;
          movimentacaoProduto.calcularTotal(this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
        } else {
          movimentacaoProduto.setDescontoValor(descontoTotal, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
          movimentacaoProduto.descontoPercentualUnitario = 0;
          movimentacaoProduto.descontoValorUnitario = 0;
          movimentacaoProduto.calcularTotal(this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
        }
      }
    }

    if (movimentacaoProduto.movimentacaoQuantidades.length == 0) {
      this.movimentacaoControlador.adicionarMovimentacaoQuantidade(movimentacaoProduto, idLocal);
    }
    movimentacaoProduto.definirRegraEstoque(this.movimentacaoControlador.estoqueComprado, this.movimentacaoControlador.estoqueFisico, this.movimentacaoControlador.estoqueReservado, this.movimentacaoControlador.estoqueTransferido, this.movimentacaoControlador.estoqueNFe, this.movimentacaoControlador.estoqueCarteira);
    this.movimentacaoControlador.ratearDescontoMovimentacaoProduto(this.movimentacaoControlador.movimentacao.descontoValor, false);
    movimentacaoProduto.setBloquearFinanceiroEstoque(this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro, this.movimentacaoControlador.financeiro, this.movimentacaoControlador.movimentacao.idOperacao);
    if (this.movimentacaoControlador.balanco) {
      movimentacaoProduto.quantidadeBalanco = quantidade;
      this.definirRegraBalanco(movimentacaoProduto);
    }
    this.movimentacaoControlador.verificarMovimentacaoProdutoBloqueiaLoja();
    if ((this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro) && produtoOuServico == 'P') {
      this.movimentacaoControlador.ehListarParceiroDestino().subscribe((res) => {
        this.movimentacaoControlador.parceiroDestino = this.plainToClass(ParceiroDestino, res[0]);
        this.movimentacaoControlador.ehListarParceiroEndereco().subscribe((res) => {
          const parceiroEnderecoPrincipal: ParceiroEndereco = this.plainToClass(ParceiroEndereco, res[0]);
          this.movimentacaoControlador.ehPreencherParceiroEndereco(parceiroEnderecoPrincipal);
          this.movimentacaoControlador.movimentacao.movimentacaoProdutos.push(this.utilMovimentacaoImpostos.listarImpostos(movimentacaoProduto, this.movimentacaoControlador.saida, this.movimentacaoControlador.parceiroDestino, this.movimentacaoControlador.parceiroEndereco, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.devolucao || this.movimentacaoControlador.notaDevolucao, this.movimentacaoControlador.bloqueiaAlteracao, this.movimentacaoControlador.financeiro, this.movimentacaoControlador.movimentacao.idLoja, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro, this.movimentacaoControlador.movimentacao.idOperacao, this.movimentacaoControlador.movimentacao.idParceiro, this.movimentacaoControlador.movimentacao.idParceiroDestino, this.movimentacaoControlador.movimentacao.parceiroTipoPessoa, this.movimentacaoControlador.movimentacao.parceiroInscricao, false, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador, this.movimentacaoControlador.entradaServico, this.movimentacaoControlador.movimentacao));
          this.movimentacaoControlador.setMovimentacaoProdutoValorUnitatioFinal(movimentacaoProduto);
          this.movimentacaoControlador.calcularPesoBrutoLiquido();
          this.movimentacaoControlador.totalizar();
        });
      });
    } else {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.push(movimentacaoProduto);
    }
    if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length == contagemFinal) {
      this.ordenarProduto();
      this.mostrarMensagemAtualizarValor();
    }
    this.movimentacaoControlador.setMovimentacaoProdutoValorUnitatioFinal(movimentacaoProduto);
    this.movimentacaoControlador.calcularPesoBrutoLiquido();
    this.movimentacaoControlador.totalizar();
  }

  mostrarMensagemAtualizarValor(): void {
    if (this.movimentacaoControlador.atualizouValor) {
      this.movimentacaoControlador.atualizouValor = false;
      this.utilSessao.informacaoAlert = true;
      const resultado: Resultado = new Resultado(null, 'Os valores dos produtos foram atualizados');
      this.utilSessao.setResultado(resultado);
    }
  }

  atualizarValorUnitarioVenda(movimentacaoProduto: MovimentacaoProduto, valorUnitario: number, quantidade: number, descontoTotal: number): void {
    if (movimentacaoProduto.valorUnitarioTabela > valorUnitario) {
      movimentacaoProduto.setValorUnitario(movimentacaoProduto.valorUnitarioTabela, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
      descontoTotal = (movimentacaoProduto.valorUnitarioTabela - valorUnitario) * quantidade;
    } else {
      movimentacaoProduto.setValorUnitario(valorUnitario, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
    }
    if (this.movimentacaoControlador.usaDescontoUnitario == false) {
      movimentacaoProduto.setDescontoValor(descontoTotal, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
    }
  }

  alertarDescricao(descricao: string, mostrarDescricao: string, produto: string): void {
    if (this.movimentacaoControlador.saida) {
      if (descricao != null && mostrarDescricao == 'S') {
        this.utilSessao.informacaoAlert = true;
        const resultado: Resultado = new Resultado(null, produto + '\n' + descricao);
        this.utilSessao.setResultado(resultado);
      }
    }
  }

  excluirProduto(posicao: number, id: number): void {
    this.ordenando = false;
    super.ehExcluir(posicao, this.movimentacaoControlador.movimentacao.movimentacaoProdutos, this.movimentacaoControlador.excluirProdutos, id);
    this.movimentacaoControlador.totalizar();
    this.movimentacaoControlador.verificarMovimentacaoProdutoBloqueiaLoja();
    this.movimentacaoControlador.calcularPesoBrutoLiquido();
    if (this.movimentacaoControlador.balanco) {
      this.movimentacaoControlador.movimentacao.calcularBalanco();
    }
    if (this.movimentacaoControlador.movimentacao.movimentacaoParcelas.length > 0) {
      this.movimentacaoControlador.limparParcelas();
    }
    this.movimentacaoControlador.gerarReceituario();
  }

  expandir(movimentacaoProduto: MovimentacaoProduto, indice: number): void {
    if (movimentacaoProduto.idProduto > 0) {
      movimentacaoProduto.expandido = !movimentacaoProduto.expandido;
    }
    if (movimentacaoProduto.expandido) {
      this.ehListarLojaLimitacao();
      this.ehListarEstoque(movimentacaoProduto.idProduto, indice, movimentacaoProduto);
    }
  }

  ehListarLojaLimitacao(): void {
    if (this.lojaLimitacoes == null) {
      this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('LIMITAR_LOJA_ESTOQUE', 'S')]), this.bibServico.loja).subscribe((res) => {
        this.lojaLimitacoes = this.plainToClass(Loja, res) as any;
        const lojaMovimentacao: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLoja);
        const LIMITAR_ESTOQUE_POR_PRACA: number = 2;
        const LIMITAR_ESTOQUE_POR_LOJA: number = 3;
        if (this.movimentacaoControlador.empresa.limitarEstoque == LIMITAR_ESTOQUE_POR_LOJA) {
          this.lojaLimitacoes = this.lojaLimitacoes.filter((loja) => loja.id == this.movimentacaoControlador.movimentacao.idLoja);
        } else {
          if (this.movimentacaoControlador.empresa.limitarEstoque == LIMITAR_ESTOQUE_POR_PRACA) {
            this.lojaLimitacoes = this.lojaLimitacoes.filter((loja) => loja.idPraca == lojaMovimentacao.idPraca);
          }
        }
        this.abreviarOrdenarLojaLimitacao();
      });
    }
  }

  abreviarOrdenarLojaLimitacao(): void {
    this.lojaLimitacoes.forEach((loja) => (loja.nomeFantasia = loja.abreviacao));
    this.lojaLimitacoes.sort((a, b) => (a['abreviacao'] == b['abreviacao'] ? 0 : a['abreviacao'] > b['abreviacao'] ? 1 : -1));
  }

  ehListarEstoque(idProduto: number, indice: number, movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.estoques == null) {
      this.listarEstoque(idProduto, indice, movimentacaoProduto);
    }
  }

  listarEstoque(idProduto: number, indice: number, movimentacaoProduto: MovimentacaoProduto): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTO', idProduto)), this.bibServico.estoque).subscribe((res) => {
      const estoques: Estoque[] = this.plainToClass(Estoque, res) as any;
      movimentacaoProduto.estoques = estoques;
      this.movimentacaoControlador.ehListarLote(indice, movimentacaoProduto, idProduto);
      this.movimentacaoControlador.ehListarLocal(indice, movimentacaoProduto)
    });
  }

  abrirModalImposto(movimentacaoProduto: MovimentacaoProduto, indiceMovimentacaoProduto: number): void {
    if (!movimentacaoProduto.produto) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.preenchaProduto));
    }
    if (this.permiteAbrirModalImposto() && movimentacaoProduto.idProduto > 0) {
      this.movimentacaoControlador.ehListarParceiroDestino().subscribe((res) => {
        this.movimentacaoControlador.parceiroDestino = this.plainToClass(ParceiroDestino, res[0]);
        this.movimentacaoControlador.ehListarParceiroEndereco().subscribe((res) => {
          const parceiroEnderecoPrincipal: ParceiroEndereco = this.plainToClass(ParceiroEndereco, res[0]);
          this.movimentacaoControlador.ehPreencherParceiroEndereco(parceiroEnderecoPrincipal);
          const validaDocumentoFiscal: boolean = this.movimentacaoControlador.movimentacao.modelo != null || this.movimentacaoControlador.movimentacao.serie != null || this.movimentacaoControlador.movimentacao.chaveFiscal != null;
          const usaDocumentoFiscal: boolean = this.movimentacaoControlador.transferenciaEntrada || (this.movimentacaoControlador.entrada && (this.movimentacaoControlador.nota || this.movimentacaoControlador.notaTerceiro)) || (this.movimentacaoControlador.devolucao && this.movimentacaoControlador.saida);
          this.utilSessao.setIdentificacao(new Identificacao('bloqueado', this.movimentacaoControlador.bloqueado));
          this.utilSessao.setIdentificacao(new Identificacao('classificacaoBloqueadoEstoque', this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque));
          this.utilSessao.setIdentificacao(new Identificacao('classificacaoBloqueadoEstoqueNfe', this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe));
          this.utilSessao.setIdentificacao(new Identificacao('classificacaoBloqueadoFinanceiro', this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro));
          this.utilSessao.setIdentificacao(new Identificacao('compacto', this.movimentacaoControlador.compacto));
          this.utilSessao.setIdentificacao(new Identificacao('financeiro', this.movimentacaoControlador.financeiro));
          this.utilSessao.setIdentificacao(new Identificacao('idLoja', this.movimentacaoControlador.movimentacao.idLoja));
          this.utilSessao.setIdentificacao(new Identificacao('idParceiro', this.movimentacaoControlador.movimentacao.idParceiro));
          this.utilSessao.setIdentificacao(new Identificacao('idParceiroDestino', this.movimentacaoControlador.movimentacao.idParceiroDestino));
          this.utilSessao.setIdentificacao(new Identificacao('movimentacaoProduto', movimentacaoProduto, false));
          this.utilSessao.setIdentificacao(new Identificacao('movimentacaoProdutoIndice', indiceMovimentacaoProduto));
          this.utilSessao.setIdentificacao(new Identificacao('obrigatorio', validaDocumentoFiscal || !usaDocumentoFiscal));
          this.utilSessao.setIdentificacao(new Identificacao('parceiroDestino', this.movimentacaoControlador.parceiroDestino));
          this.utilSessao.setIdentificacao(new Identificacao('parceiroEndereco', this.movimentacaoControlador.parceiroEndereco));
          this.utilSessao.setIdentificacao(new Identificacao('saida', this.movimentacaoControlador.saida));
          this.utilSessao.setIdentificacao(new Identificacao('bloqueiaAlteracao', this.movimentacaoControlador.bloqueiaAlteracao));
          this.utilSessao.setIdentificacao(new Identificacao('devolucao', this.movimentacaoControlador.devolucao || this.movimentacaoControlador.notaDevolucao));
          this.utilSessao.setIdentificacao(new Identificacao('idOperacao', this.movimentacaoControlador.movimentacao.idOperacao));
          this.utilSessao.setIdentificacao(new Identificacao('naturezaOperacaoFinalidade', this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade));
          this.utilSessao.setIdentificacao(new Identificacao('parceiroTipoPessoa', this.movimentacaoControlador.movimentacao.parceiroTipoPessoa));
          this.utilSessao.setIdentificacao(new Identificacao('parceiroInscricao', this.movimentacaoControlador.movimentacao.parceiroInscricao));
          this.utilSessao.setIdentificacao(new Identificacao('faturamento', this.movimentacaoControlador.nota));
          this.utilSessao.setIdentificacao(new Identificacao('negociacao', this.movimentacaoControlador.movimentacao.negociacao));
          this.utilSessao.setIdentificacao(new Identificacao('mediaDiaPrazo', this.movimentacaoControlador.movimentacao.mediaDiaPrazo));
          this.utilSessao.setIdentificacao(new Identificacao('movimentacaoParcelas', this.movimentacaoControlador.movimentacao.movimentacaoParcelas));
          this.utilSessao.setIdentificacao(new Identificacao('valorTotal', this.movimentacaoControlador.movimentacao.valorTotal));
          this.utilSessao.setIdentificacao(new Identificacao('nomeParceiro', this.movimentacaoControlador.tipoParceiro));
          this.utilSessao.setIdentificacao(new Identificacao('identificacao', this.movimentacaoControlador.identificacao));
          this.utilSessao.setIdentificacao(new Identificacao('entradaServico', this.movimentacaoControlador.entradaServico));
          this.utilSessao.setIdentificacao(new Identificacao('alimentaCustoReposicao', this.movimentacaoControlador.alimentaCustoReposicao));
          this.utilSessao.setIdentificacao(new Identificacao('casasDecimais', this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais));
          this.utilSessao.setIdentificacao(new Identificacao('movimentacao', this.movimentacaoControlador.movimentacao));
          this.componente = MovimentacaoProdutoImpostoFrmComponent;
        });
      });
    }
  }

  permiteAbrirModalImposto(): boolean {
    if ((this.movimentacaoControlador.movimentacao.idLoja && this.movimentacaoControlador.movimentacao.idParceiro) || this.movimentacaoControlador.transferencia) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.preenchaLojaEParceiroParaAbrirModalImposto, [this.movimentacaoControlador.tipoParceiro.toLowerCase()])));
    return false;
  }

  atualizarLinhaColuna(linha: number, coluna: number): void {
    this.focadoCampoMovimentacaoProduto = true;
    if (linha >= 0 && linha < this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length) {
      this.linha = linha;
      this.coluna = coluna;
    }
  }

  ehFecharModal(): void {
    if (this.modalPesquisaProdutoAberta) {
      this.utilNotificacao.adicionaProdutos.emit(true);
      this.modalPesquisaProdutoAberta = false;
      this.componente = null;
    } else {
      this.componente = null;
    }
  }

  validarProdutos(produto: Produto, movimentacaoProduto: MovimentacaoProduto): void {
    if (this.movimentacaoControlador.movimentacao.idParceiro) {
      if (this.movimentacaoControlador.movimentacao.idNaturezaOperacao) {
        const historicoIdProdutoParceiro: number = movimentacaoProduto.idProdutoParceiro;
        movimentacaoProduto.idProduto = produto ? produto.id : null;
        movimentacaoProduto.produto = produto ? produto.nome : null;
        movimentacaoProduto.produtoNumero = produto ? produto.numero : null;
        movimentacaoProduto.abreviacao = produto ? produto.abreviacao : null;
        movimentacaoProduto.idUnidade = produto ? produto.idUnidade : null;
        movimentacaoProduto.idUnidadePadrao = produto ? produto.idUnidade : null;
        movimentacaoProduto.custoNfe = produto ? produto.custoNfe : null;
        movimentacaoProduto.custoGerencial = produto ? produto.custoGerencial : null;
        movimentacaoProduto.custoReposicao = produto ? produto.custoReposicao : null;
        movimentacaoProduto.valorUnitarioTabela = produto ? produto.precoVenda : null;
        movimentacaoProduto.margemLucro = produto ? this.util.coalesce(produto.margemLucro, this.util.coalesce(produto.grupoMargemLucro, 0), true) : null;
        movimentacaoProduto.descontoPorValor = this.movimentacaoControlador.entrada ? true : false;
        if (movimentacaoProduto.importacaoXml == 'S') {
          movimentacaoProduto.produtoNome = produto ? produto.nome : null;
        }
        this.persistirProdutoParceiro(movimentacaoProduto);
        this.popularUnidadeParceiro(movimentacaoProduto, historicoIdProdutoParceiro);
        if (movimentacaoProduto.idProduto) {
          this.utilMovimentacaoImpostos.listarImpostos(movimentacaoProduto, this.movimentacaoControlador.saida, this.movimentacaoControlador.parceiroDestino, this.movimentacaoControlador.parceiroEndereco, this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade, this.movimentacaoControlador.devolucao || this.movimentacaoControlador.notaDevolucao, this.movimentacaoControlador.bloqueiaAlteracao, this.movimentacaoControlador.financeiro, this.movimentacaoControlador.movimentacao.idLoja, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoque, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoEstoqueNfe, this.movimentacaoControlador.movimentacao.classificacaoBloqueadoFinanceiro, this.movimentacaoControlador.movimentacao.idOperacao, this.movimentacaoControlador.movimentacao.idParceiro, this.movimentacaoControlador.movimentacao.idParceiroDestino, this.movimentacaoControlador.movimentacao.parceiroTipoPessoa, this.movimentacaoControlador.movimentacao.parceiroInscricao, false, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador, this.movimentacaoControlador.entradaServico, this.movimentacaoControlador.movimentacao);
          this.movimentacaoControlador.setMovimentacaoProdutoValorUnitatioFinal(movimentacaoProduto);
          this.movimentacaoControlador.calcularPesoBrutoLiquido();
          this.movimentacaoControlador.totalizar();
          this.movimentacaoControlador.listarUnidadeProduto(movimentacaoProduto, produto);
        }
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.selecioneNaturezaOperacao));
      }
    } else {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.preenchaOCampoFornecedor));
    }
  }

  persistirProdutoParceiro(movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.idProduto) {
      let produtoParceiro: ProdutoParceiro = new ProdutoParceiro();
      produtoParceiro.idProduto = movimentacaoProduto.idProduto;
      produtoParceiro.numero = movimentacaoProduto.numeroProdutoNfe;
      produtoParceiro.idParceiro = this.movimentacaoControlador.movimentacao.idParceiro;
      super.persistir(new Transporte(produtoParceiro), this.bibServico.produtoParceiro, null);
      this.movimentacaoControlador.listarPersistirLoteXml(movimentacaoProduto);
    }
  }
  popularUnidadeParceiro(movimentacaoProduto: MovimentacaoProduto, idUnidadeParceiro: number): void {
    if (movimentacaoProduto.idProduto) {
      let unidadeParceiro: UnidadeParceiro = new UnidadeParceiro();
      unidadeParceiro.idProduto = movimentacaoProduto.idProduto;
      unidadeParceiro.abreviacao = movimentacaoProduto.unidadeXml;
      unidadeParceiro.idParceiro = this.movimentacaoControlador.movimentacao.idParceiro;
      unidadeParceiro.idUnidade = movimentacaoProduto.idUnidade;
      this.movimentacaoControlador.unidadesParceiros.push(unidadeParceiro);
    } else {
      this.movimentacaoControlador.idUnidadesParceiros.push(idUnidadeParceiro);
    }
  }

  mudarTipoDesconto(movimentacaoProduto: MovimentacaoProduto): void {
    movimentacaoProduto.descontoPorValor = !movimentacaoProduto.descontoPorValor;
  }

  abrirModalTabelaPreco(movimentacaoProduto: MovimentacaoProduto, indice: number): void {
    movimentacaoProduto.indice = indice;
    this.movimentacaoProdutoIndice = indice;
    this.utilSessao.setIdentificacao(new Identificacao('data', this.movimentacaoControlador.movimentacao.data));
    this.utilSessao.setIdentificacao(new Identificacao('valor', movimentacaoProduto.valorUnitarioTabela));
    this.utilSessao.setIdentificacao(new Identificacao('idCliente', this.movimentacaoControlador.movimentacao.idParceiro));
    this.utilSessao.setIdentificacao(new Identificacao('idColaborador', this.movimentacaoControlador.movimentacao.idColaborador));
    this.utilSessao.setIdentificacao(new Identificacao('idProduto', movimentacaoProduto.idProduto));
    this.componente = TabelaPrecoModalComponent;
  }

  permitirOrdenar(): void {
    this.ordenando = this.ordenando ? false : true;
    if (this.ordenando) {
      const quantidadeProduto: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
      if (quantidadeProduto > 0) {
        this.ordens = [];
        for (let i = 1; i <= quantidadeProduto; i++) {
          this.ordens.push({ id: i, nome: i });
        }
      } else {
        this.ordenando = false;
      }
    }
  }

  ordenar(indice: number, ordem: any): void {
    if (ordem.id) {
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos[indice].ordem = 10000;
      this.ordenarProduto();
      this.movimentacaoControlador.gerarOrdenacaoProduto();

      for (let i = ordem.id - 1; i < this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length; i++) {
        this.movimentacaoControlador.movimentacao.movimentacaoProdutos[i].ordem++;
      }

      const ultimaPosicao: number = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length - 1;
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos[ultimaPosicao].ordem = ordem.id;
      this.ordenarProduto();
    }
  }

  ordenarProduto(): void {
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.sort((a, b) => {
      return a['ordem'] === b['ordem'] ? (a['id'] > b['id'] ? 1 : -1) : a['ordem'] > b['ordem'] ? 1 : -1;
    });
  }

  setDescontoPercentualAcumulaDesconto(descontoPercentualAcumulaDesconto: number): void {
    this.descontoPercentualAcumulaDesconto = null;
    setTimeout((_) => (this.descontoPercentualAcumulaDesconto = 0));
    this.movimentacaoControlador.setDescontoPercentualCabecalho(descontoPercentualAcumulaDesconto, true);
  }

  setDescontoValorAcumulaDesconto(descontoValorAcumulaDesconto: number): void {
    this.descontoValorAcumulaDesconto = null;
    setTimeout((_) => (this.descontoValorAcumulaDesconto = 0));
    this.movimentacaoControlador.setDescontoValorCabecalho(descontoValorAcumulaDesconto, true);
  }

  alterarTotal(valor: number, acumulaDesconto: boolean = false): void {
    const usaDescontoUnitario = this.utilSessao.sistema == 'Sigescom-w';
    if (usaDescontoUnitario == false) {
      if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0) {
        const valorTotal: number = valor - this.movimentacaoControlador.movimentacao.valorTotal > 0 ? valor - this.movimentacaoControlador.movimentacao.valorTotal : (valor - this.movimentacaoControlador.movimentacao.valorTotal) * -1;
        const percentualValorTotal: number = (valorTotal * 100) / this.movimentacaoControlador.movimentacao.valorTotal;
        if (this.movimentacaoControlador.utilSessao.getEmpresa().regraVariacaoValorTotal >= percentualValorTotal) {
          const desconto: number = this.movimentacaoControlador.movimentacao.valorTotal + this.movimentacaoControlador.movimentacao.descontoValor - valor;
          const novoDescontoTotal = desconto >= 0 ? desconto : 0;
          if (novoDescontoTotal > 0) {
            this.movimentacaoControlador.setDescontoValorCabecalho(novoDescontoTotal, acumulaDesconto);
          } else {
            this.subirValorUnitario(desconto * -1);
          }
        } else {
          const valorTotal: number = this.movimentacaoControlador.movimentacao.valorTotal;
          this.movimentacaoControlador.movimentacao.valorTotal = null;
          setTimeout((_) => (this.movimentacaoControlador.movimentacao.valorTotal = valorTotal));
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.variacaoDescontoAcrescimoValor));
        }
      }
    } else {
      this.alterarTotalAtualizada(valor);
    }
  }

  alterarTotalAtualizada(valorTotalDesejado: number): void {
    if (this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length > 0) {
      if (this.validarRegraVariacaoTotal(valorTotalDesejado)) {
        const desconto = this.movimentacaoControlador.movimentacao.valorTotal - valorTotalDesejado;
        if (desconto != 0) {
          this.recalcularDescontoUnitario(valorTotalDesejado, desconto);
        }
      }
    }
  }

  validarRegraVariacaoTotal(valorTotalDesejado: number): boolean {
    let percentualAlteracaoValorTotal: number = 100 - (valorTotalDesejado * 100) / this.movimentacaoControlador.movimentacao.valorTotal;
    percentualAlteracaoValorTotal = percentualAlteracaoValorTotal * (percentualAlteracaoValorTotal > 0 ? 1 : -1);
    if (this.movimentacaoControlador.utilSessao.getEmpresa().regraVariacaoValorTotal < percentualAlteracaoValorTotal) {
      this.atualizarTotal();
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.variacaoDescontoAcrescimoValor));
      return false;
    }
    return true;
  }

  recalcularDescontoUnitario(valorTotalDesejado: number, desconto: number): void {
    let totalDescontoConcedido: number = 0;
    const valorTotal: number = this.movimentacaoControlador.movimentacao.valorTotal;
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      const descontoUnitarioProduto: number = this.util.arredondar(((movimentacaoProduto.valorTotal * desconto) / valorTotal) / movimentacaoProduto.getQuantidade());
      totalDescontoConcedido += (descontoUnitarioProduto * movimentacaoProduto.getQuantidade());
      this.setDescontoValorUnitario(movimentacaoProduto, movimentacaoProduto.descontoValorUnitario + descontoUnitarioProduto);
    });
    this.lancarDescontoUnitarioFaltante(valorTotalDesejado, (desconto - totalDescontoConcedido));
  }

  lancarDescontoUnitarioFaltante(valorTotalDesejado: number, descontoValorUnitarioFaltante: number) {
    if (descontoValorUnitarioFaltante != 0) {
      const movimentacaoProduto: MovimentacaoProduto = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.find((movimentacaoProduto) => movimentacaoProduto.getQuantidade() == 1);
      if (movimentacaoProduto) {
        this.setDescontoValorUnitario(movimentacaoProduto, movimentacaoProduto.descontoValorUnitario + descontoValorUnitarioFaltante);
      }
    }
    if (valorTotalDesejado != this.movimentacaoControlador.movimentacao.valorTotal) {
      this.atualizarTotal();
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.impossivelChegarValorDesejaAutomaticamente, [this.util.moeda(valorTotalDesejado)])));
    }
  }

  atualizarTotal() {
    const valorTotal: number = this.movimentacaoControlador.movimentacao.valorTotal;
    this.movimentacaoControlador.movimentacao.valorTotal = null;
    setTimeout((_) => this.movimentacaoControlador.movimentacao.valorTotal = valorTotal);
  }

  subirValorUnitario(valorAcrescimo: number) {
    let totalAcrescimoConcedido: number = 0;
    let valorAcrescimoItem: number = 0;
    this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      valorAcrescimoItem = (movimentacaoProduto.valorTotal * valorAcrescimo) / this.movimentacaoControlador.movimentacao.valorTotal;
      valorAcrescimoItem = this.util.arredondar(valorAcrescimoItem / movimentacaoProduto.getQuantidade());
      const valorUnitarioComAcrescimo = movimentacaoProduto.getValorUnitario() + valorAcrescimoItem;
      totalAcrescimoConcedido += valorAcrescimoItem * movimentacaoProduto.getQuantidade();
      movimentacaoProduto.setValorUnitarioSemAlteraDescontoValor(valorUnitarioComAcrescimo, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
    });
    let diferencaAcrescimo: number = valorAcrescimo - totalAcrescimoConcedido;
    this.ajustarDiferencaAcrescimo(valorAcrescimo, diferencaAcrescimo);
    this.movimentacaoControlador.totalizar();
  }

  ajustarDiferencaAcrescimo(valorAcrescimo: number, diferencaAcrescimo: number) {
    if (diferencaAcrescimo != 0) {
      let valorAcrescimoItem: number = 0;
      let valorUnitarioComAcrescimo: number = 0;
      let totalAcrescimoConcedido: number = 0;
      this.movimentacaoControlador.movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
        if (diferencaAcrescimo != 0 && movimentacaoProduto.getQuantidade() == 1) {
          valorAcrescimoItem = this.util.arredondar(diferencaAcrescimo);
          valorUnitarioComAcrescimo = movimentacaoProduto.getValorUnitario() + valorAcrescimoItem;
          totalAcrescimoConcedido += valorAcrescimoItem * movimentacaoProduto.getQuantidade();
          movimentacaoProduto.setValorUnitarioSemAlteraDescontoValor(valorUnitarioComAcrescimo, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
          diferencaAcrescimo = valorAcrescimo - totalAcrescimoConcedido;
        }
        if (diferencaAcrescimo > 0 && this.movimentacaoControlador.movimentacao.movimentacaoProdutos[i + 1] == null) {
          valorAcrescimoItem = this.util.arredondar(diferencaAcrescimo);
          totalAcrescimoConcedido += valorAcrescimoItem * movimentacaoProduto.getQuantidade();
          valorUnitarioComAcrescimo = movimentacaoProduto.getValorUnitario() + valorAcrescimoItem;
          movimentacaoProduto.setValorUnitarioSemAlteraDescontoValor(valorUnitarioComAcrescimo, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
          diferencaAcrescimo = valorAcrescimo - totalAcrescimoConcedido;

          if (diferencaAcrescimo > 0) {
            valorAcrescimoItem = this.util.arredondar(diferencaAcrescimo);
            totalAcrescimoConcedido += diferencaAcrescimo * movimentacaoProduto.getQuantidade();
            valorUnitarioComAcrescimo = movimentacaoProduto.getValorUnitario() + valorAcrescimoItem;
            movimentacaoProduto.setValorUnitarioSemAlteraDescontoValor(valorUnitarioComAcrescimo, this.movimentacaoControlador.movimentacao.negociacao, this.movimentacaoControlador.movimentacao.mediaDiaPrazo, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal);
            diferencaAcrescimo = valorAcrescimo - totalAcrescimoConcedido;
          }
        }
      });
      if (diferencaAcrescimo < 0) {
        this.setDescontoValorAcumulaDesconto(diferencaAcrescimo * -1);
      }
    }
  }

  listarMovimentacaoChaveFiscal(): void {
    if (this.movimentacaoControlador.movimentacao.naturezaOperacaoFinalidade == 3) {
      if (this.movimentacaoControlador.nota && this.movimentacaoControlador.vinculoIdMovimentacaoProOrigens.length > 0) {
        this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO_PRO', this.movimentacaoControlador.vinculoIdMovimentacaoProOrigens.toString())), this.bibServico.chaveMovimentacao).subscribe((res) => {
          this.adicionarMovimentacaoChave(this.plainToClass(MovimentacaoChave, res));
        });
      }
    }
  }

  adicionarMovimentacaoChave(chaveMovimentacoes: any[]): void {
    chaveMovimentacoes.forEach((chaveMovimentacao) => {
      const movimentacaoChave: MovimentacaoChave = new MovimentacaoChave();
      movimentacaoChave.chave = chaveMovimentacao.chaveFiscal;
      movimentacaoChave.serie = chaveMovimentacao.serie;
      movimentacaoChave.modelo = chaveMovimentacao.modelo;
      movimentacaoChave.numeroFiscal = chaveMovimentacao.numeroFiscal;
      this.modalNotificacao.modalEmt.emit(new Identificacao(this.bibClasse.movimentacaoChave, movimentacaoChave));
    });
  }

  validarPrecoMenor(movimentacaoProduto: MovimentacaoProduto, foco: boolean): void {
    if (this.movimentacaoControlador.nota == false && !foco && movimentacaoProduto.valorUnitarioTabela > 0 && !this.movimentacaoControlador.permiteValorMenor && this.movimentacaoControlador.movimentacao.naturezaOperacaoUsaCustoReposicao == '1') {
      if (this.util.arredondar(movimentacaoProduto.getValorUnitario()) < this.util.arredondar(movimentacaoProduto.valorUnitarioTabela, this.movimentacaoControlador.empresaRegraCasasDecimais)) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.precoMenor));
      }
    }
  }

  mostrarTodosProdutos(): void {
    this.paginacaoProduto = this.movimentacaoControlador.movimentacao.movimentacaoProdutos.length;
    this.posicao = 0;
  }

  setPosicaoPagina(numeroPagina: number): void {
    this.posicao = numeroPagina;
  }

  listarTabelaPrecoProduto(idProduto: number, idGrupo: number): Observable<any> {
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    criterios.push(new Criterio('LIMITAR_ID_PRODUTO', idProduto));
    criterios.push(new Criterio('LIMITAR_ID_CLIENTE', this.movimentacaoControlador.movimentacao.idParceiro));
    criterios.push(new Criterio('LIMITAR_ID_COLABORADOR', this.movimentacaoControlador.movimentacao.idColaborador));
    criterios.push(new Criterio('LIMITAR_ID_GRUPO', idGrupo));
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.tabelaPreco);
  }

  setUnidade(movimentacaoProduto: MovimentacaoProduto, produtoUnidade: ProdutoUnidade) {
    movimentacaoProduto.produtoUnidades.forEach((produtoUnidade) => (produtoUnidade.sugerirUnidade = 'N'));
    produtoUnidade.sugerirUnidade = 'S';
    let alterouUnidade: boolean = false;
    if (movimentacaoProduto.idUnidade != produtoUnidade.idUnidade) {
      alterouUnidade = true;
    }
    movimentacaoProduto.idUnidade = produtoUnidade.idUnidade;
    if (alterouUnidade) {
      this.converterValorUnidadeAlternativa(movimentacaoProduto, produtoUnidade);
    }
    if (alterouUnidade) {
      movimentacaoProduto.converterUnidadeAlternativa();
    }
  }

  converterValorUnidadeAlternativa(movimentacaoProduto: MovimentacaoProduto, produtoUnidade: ProdutoUnidade): void {
    if (movimentacaoProduto.importacaoXml != 'S' && this.movimentacaoControlador.movimentacao.status != 'S') {
      const criterios: Criterio[] = [new Criterio('ID', movimentacaoProduto.idProduto), new Criterio('ID_LOJA', this.movimentacaoControlador.movimentacao.idLoja)];
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produto).subscribe((res) => {
        let produto: Produto = this.plainToClass(Produto, res[0]);
        if (movimentacaoProduto.idUnidade == produto.idUnidade) {
          movimentacaoProduto.custoReposicao = produto.custoReposicao;
          movimentacaoProduto.custoGerencial = produto.custoGerencial;
          this.setValorUnitario(movimentacaoProduto, produto.precoVenda);
          movimentacaoProduto.valorUnitarioTabela = produto.precoVenda;
        } else {
          let valorUnitarioConvertido: number = 0;
          let valorTabelaConvertido: number = 0;
          let custoReposicaoConvertido: number = 0;
          let custoGerencialConvertido: number = 0;
          if (movimentacaoProduto.valorUnitarioTabela > 0) {
            valorUnitarioConvertido = this.calcularValorConvertido(produtoUnidade.operacao, movimentacaoProduto.getValorUnitario(), produtoUnidade.fator);
            valorTabelaConvertido = this.calcularValorConvertido(produtoUnidade.operacao, movimentacaoProduto.valorUnitarioTabela, produtoUnidade.fator);
          } else {
            valorUnitarioConvertido = movimentacaoProduto.getValorUnitario();
            valorTabelaConvertido = movimentacaoProduto.valorUnitarioTabela;
          }
          if (movimentacaoProduto.custoReposicao > 0) {
            custoReposicaoConvertido = this.calcularValorConvertido(produtoUnidade.operacao, movimentacaoProduto.custoReposicao, produtoUnidade.fator);
          }
          if (movimentacaoProduto.custoGerencial > 0) {
            custoGerencialConvertido = this.calcularValorConvertido(produtoUnidade.operacao, movimentacaoProduto.custoGerencial, produtoUnidade.fator);
          }
          if (this.movimentacaoControlador.saida == false) {
            movimentacaoProduto.valorUnitarioTabela = this.calcularValorConvertido(produtoUnidade.operacao == 'D' ? 'M' : 'D', valorUnitarioConvertido, produtoUnidade.fator);
          } else {
            movimentacaoProduto.valorUnitarioTabela = valorTabelaConvertido;
          }
          movimentacaoProduto.setValorUnitario(valorUnitarioConvertido, this.movimentacaoControlador.movimentacao.negociacao, 0, this.movimentacaoControlador.movimentacao.movimentacaoParcelas, this.movimentacaoControlador.movimentacao.valorTotal, this.movimentacaoControlador.saida, 0, this.movimentacaoControlador.entrada ? this.movimentacaoControlador.empresaRegraCasasDecimaisEntrada : this.movimentacaoControlador.empresaRegraCasasDecimais);
          movimentacaoProduto.custoReposicao = custoReposicaoConvertido;
          movimentacaoProduto.custoGerencial = custoGerencialConvertido;
          this.movimentacaoControlador.totalizar();
        }
      });
    }
  }

  calcularValorConvertido(operacao: string, valor: number, fator: number): number {

    if (operacao == 'M') {
      return valor * fator;
    }
    return valor / fator;
  }

  criarProdutoFormuladoCulturas(movimentacaoProduto: MovimentacaoProduto): ProdutoFormuladoDose[] {
    let produtoFormuladoCulturas: ProdutoFormuladoDose[] = [];
    movimentacaoProduto.produtoFormuladoDoses.forEach((produtoFormulado, i) => {
      if (i == movimentacaoProduto.produtoFormuladoDoses.length - 1 || produtoFormulado.cultura != movimentacaoProduto.produtoFormuladoDoses[i == movimentacaoProduto.produtoFormuladoDoses.length - 1 ? i : i + 1].cultura) {
        produtoFormuladoCulturas.push(produtoFormulado);
      }
    });
    return produtoFormuladoCulturas;
  }

  criarProdutoFormuladoPragas(movimentacaoProduto: MovimentacaoProduto, cultura: string): ProdutoFormuladoDose[] {
    let produtoFormuladoPragas: ProdutoFormuladoDose[] = [];
    let pragaAtual: String = '';
    movimentacaoProduto.produtoFormuladoDoses.filter((produtoFormulado) => produtoFormulado.cultura == cultura)
      .sort((a, b) => (a['praga'] === b['praga'] ? 0 : a['praga'] > b['praga'] ? 1 : -1))
      .forEach((produtoFormulado, i) => {
        if (pragaAtual.localeCompare(produtoFormulado.praga)) {
          produtoFormuladoPragas.push(produtoFormulado);
          pragaAtual = produtoFormulado.praga;
        }
      });
    return produtoFormuladoPragas;
  }

  criarListaPraga(lista: any[]): any[] {
    let listaPragas: any[] = [];
    for (const objeto of lista) {
      listaPragas.push({ id: objeto.id, nome: objeto.praga });
    }
    return listaPragas;
  }

  criarListaCultura(lista: any[]): any[] {
    let listaCulturas: any[] = [];
    for (const objeto of lista) {
      listaCulturas.push({ id: objeto.id, nome: objeto.cultura });
    }
    return listaCulturas;
  }

  setCultura(movimentacaoProduto: MovimentacaoProduto, cultura: any): void {
    movimentacaoProduto.idCultura = cultura.id;
    movimentacaoProduto.cultura = cultura.nome;
    movimentacaoProduto.idPraga = null;
    movimentacaoProduto.praga = null;
    this.movimentacaoControlador.gerarReceituario();
    this.produtoFormuladoPragaCulturas = this.criarProdutoFormuladoPragas(movimentacaoProduto, cultura.nome);
    if (this.produtoFormuladoPragaCulturas.length == 1) {
      this.setPraga(movimentacaoProduto, { id: this.produtoFormuladoPragaCulturas[0].id, nome: this.produtoFormuladoPragaCulturas[0].praga });
    }
    movimentacaoProduto.pragas = this.criarListaPraga(this.produtoFormuladoPragaCulturas);
  }

  setPraga(movimentacaoProduto: MovimentacaoProduto, praga: any): void {
    movimentacaoProduto.idPraga = praga.id;
    movimentacaoProduto.praga = praga.nome;
    this.movimentacaoControlador.gerarReceituario();
  }

  setAplicacao(movimentacaoProduto: MovimentacaoProduto, aplicacao: any): void {
    movimentacaoProduto.idAplicacao = aplicacao.id;
    movimentacaoProduto.aplicacao = aplicacao.nome;
  }

  listarEquipamentos(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.equipamento).subscribe((res) => {
      this.equipamentos = this.plainToClass(Equipamento, res) as any;
    });
  }
}
