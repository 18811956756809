import bibDialogo from 'src/app/biblioteca/bibDialogo';
import { Grupo } from 'src/app/interface/grupo';
import { UtilFiltro } from 'src/app/utilitario/utilFiltro';

const utilFiltro: UtilFiltro = new UtilFiltro();
const grupos: Grupo[] = [
  { rotulo: bibDialogo.cliente, campo: 'cliente' },
  { rotulo: bibDialogo.produto, campo: 'produto' },
  { rotulo: bibDialogo.vendedor, campo: 'vendedor' },
  { rotulo: bibDialogo.loja, campo: 'loja' },
  { rotulo: bibDialogo.grupo, campo: 'grupo' },
];

export default grupos;
