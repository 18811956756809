<layout [titulo]="parceiro.razaoSocial" [id]="parceiro && parceiro.id" (voltarAvancarEmt)="ehAlteracao()">
  <div *ngIf="utilizaPesquisaCliente" [ngClass]="ehModoClaro ? 'bg-cinza-fraco' : 'badge-light'" class="tab-pane fade active show">
    <div class="row m0 form-group" [ngClass]="compacto ? 'tamanho-minimo-compacto' : 'tamanho-minimo'">
      <busca class="col-sm" [rotulo]="bibDialogo.cliente" (alteracao)="listarParceiroCompleto($event.id)" [minimoCaracterBusca]="5" [servico]="bibServico.cliente" [buscarPorNumero]="true" [foco]="utilizaPesquisaCliente" [minimoCaracterBusca]="3"></busca>
    </div>
  </div>
  <ng-container *ngIf="(parceiro.id && utilizaPesquisaCliente) || utilizaPesquisaCliente == false">
    <div *ngIf="parceiro.id" class="row">
      <check *ngIf="permissaoCliente" class="col-sm" [rotulo]="menuCliente.apelido" [campo]="parceiro.tipoCliente" (alteracao)="setParceiro(parceiro, 'cliente', $event)" [compacto]="true"></check>
      <check *ngIf="permissaoFornecedor" class="col-sm" [rotulo]="menuFornecedor.apelido" [campo]="parceiro.tipoFornecedor" (alteracao)="setParceiro(parceiro, 'fornecedor', $event)" [compacto]="true"></check>
      <check *ngIf="permissaoTransportadora" class="col-sm" [rotulo]="menuTransportadora.apelido" [campo]="parceiro.tipoTransportadora" (alteracao)="setParceiro(parceiro, 'transportadora', $event)" [compacto]="true"></check>
      <check *ngIf="permissaoColaborador" class="col-sm" [rotulo]="menuColaborador.apelido" [campo]="parceiro.tipoColaborador" (alteracao)="setParceiro(parceiro, 'colaborador', $event)" [compacto]="true"></check>
    </div>
    <div class="row">
      <apresentacao *ngIf="!informarNumero" class="col-sm-3" [rotulo]="bibDialogo.numero" [campo]="parceiro.numero" [icone]="bibIcone.editar" (btnAdicional)="editarCampo()"></apresentacao>
      <texto *ngIf="informarNumero" class="col-sm-3" [id]="'numeroParceiro'" [rotulo]="bibDialogo.numero" [tipo]="bibPropriedade.texto.inteiro" [campo]="parceiro.numero" (alteracao)="parceiro.numero = $event" [focoId]="focoNumero"></texto>        
      <lista class="col-sm-3" [id]="'tipoPessoa'" [rotulo]="bibDialogo.tipo" [campo]="parceiro.tipoPessoa" [lista]="listaPessoas" (alteracao)="setTipoPessoa(parceiro, $event.id)" [obrigatorio]="true" [foco]="true && !utilizaPesquisaCliente"></lista>
      <div class="col-sm"></div>
      <interruptor [rotulo]="bibDialogo.ativo" [campo]="parceiro.ativo" (alteracao)="parceiro.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
    </div>
    <div class="row">
      <texto [ngClass]="parceiro.tipoPessoa !== 'F' ? 'col-sm-12' : 'col-sm-8'" [id]="'nome'" [rotulo]="parceiro.tipoPessoa === 'F' ? bibDialogo.nomeCompleto : bibDialogo.razaoSocial" [campo]="parceiro.razaoSocial" (alteracao)="setRazaoSocial($event)" [obrigatorio]="true" [maximoCaracteres]="100" [foco]="focoNome"></texto>
      <data *ngIf="parceiro.tipoPessoa === 'F'" class="col-sm-4" [id]="'dataNascimento'" [rotulo]="bibDialogo.dataNascimento" [campo]="parceiro.dataNascimento" (alteracao)="parceiro.dataNascimento = $event"></data>
    </div>
    <div class="row">
      <texto [ngClass]="parceiro.tipoPessoa !== 'F' ? 'col-sm-12' : 'col-sm-8'" [id]="'apelido'" [rotulo]="parceiro.tipoColaborador == 'S' ? bibDialogo.abreviacao : parceiro.tipoPessoa === 'F' ? bibDialogo.apelido : bibDialogo.nomeFantasia" [campo]="parceiro.nomeFantasia" (alteracao)="parceiro.nomeFantasia = $event" [maximoCaracteres]="parceiro.tipoColaborador == 'S' ? 3 : 100" [obrigatorio]="parceiro.tipoColaborador == 'S'" [ajuda]="parceiro.tipoColaborador == 'S' ? bibDialogo.max3caracteres : ''"></texto>
      <lista *ngIf="parceiro.tipoPessoa === 'F'" class="col-sm-4" [id]="'sexo'" [rotulo]="bibDialogo.sexo" [campo]="parceiro.sexo" [lista]="listaSexos" (alteracao)="parceiro.sexo = $event.id"></lista>
    </div>
    <div *ngIf="parceiro.tipoCliente === 'S'" class="row align-items-center">
      <lista class="col-sm-3" [id]="'permitirFaturamentoMatriz'" [rotulo]="bibDialogo.permitirFaturamentoCompartilhado" [campo]="parceiro.permiteFaturamentoCompartilhado" [lista]="listaSimNao" (alteracao)="parceiro.permiteFaturamentoCompartilhado = $event.id" [obrigatorio]="true"></lista>      
    </div>
    <titulo *ngIf="!compacto && parceiro.tipoColaborador === 'S'" [titulo]="bibDialogo.tipo" [imagem]="bibImagem.atualizacaocadastro"></titulo>
    <div *ngIf="parceiro.tipoColaborador === 'S'" class="row">
      <check class="col-sm" [rotulo]="bibDialogo.comprador" [campo]="parceiro.comprador" (alteracao)="parceiro.comprador = $event" [compacto]="true"></check>
      <check class="col-sm" [rotulo]="bibDialogo.interno" [campo]="parceiro.interno" (alteracao)="parceiro.interno = $event" [compacto]="true"></check>        
      <check class="col-sm" [rotulo]="bibDialogo.vendedor" [campo]="parceiro.vendedor" (alteracao)="parceiro.vendedor = $event" [compacto]="true"></check>
    </div> 
    <titulo *ngIf="!compacto" [titulo]="bibDialogo.documentos" [imagem]="bibImagem.documentos"></titulo>
    <div class="row">
      <mascara class="col-sm-4" [id]="'cpfCnpj'" [rotulo]="parceiro.tipoPessoa === 'F' ? bibDialogo.cpf : bibDialogo.cnpj" [campo]="parceiro.cnpjCpf" (alteracao)="setCnpjCpf(parceiro, $event)" [obrigatorio]="obrigaCpfCnpj == 1 ? true : false" [tipo]="parceiro.tipoPessoa === 'F' ? bibPropriedade.mascara.cpf : bibPropriedade.mascara.cnpj" [atencao]="!parceiro.ehValidoCnpjCpf && parceiro.cnpjCpf ? bibDialogo.incorreto : null" [ajuda]="atencaoConsumidorFinal ? bibDialogo.consumidorFinalCpfPadrao : null" [ajudaDireita]="true"></mascara>
      <lista *ngIf="parceiro.tipoCliente === 'S' || parceiro.tipoFornecedor === 'S'" class="col-sm-4" [id]="'contribuinte'" [rotulo]="bibDialogo.contribuinte" [campo]="parceiro.contribuinte" [lista]="listaContribuinte" (alteracao)="parceiro.contribuinte = $event.id" [obrigatorio]="true"></lista>
      <texto *ngIf="parceiro.tipoCliente === 'S' || (parceiro.tipoFornecedor === 'S' && parceiro.tipoPessoa !== 'F') || (parceiro.tipoColaborador !== 'S' && parceiro.tipoFornecedor !== 'S')" class="col-sm-4" [id]="'inscricaoProdutor'" [rotulo]="parceiro.tipoPessoa == 'J' ? bibDialogo.inscricaoEstadual : bibDialogo.inscricaoProdutor" [campo]="parceiro.inscricaoEstadual" (alteracao)="parceiro.inscricaoEstadual = $event" [maximoCaracteres]="20" [obrigatorio]="parceiro.tipoPessoa == 'J' || parceiro.contribuinte == 1"></texto>
      <texto *ngIf="parceiro.tipoPessoa === 'F'" class="col-sm-4" [id]="'identidade'" [rotulo]="bibDialogo.identidade" [campo]="parceiro.identidade" (alteracao)="parceiro.identidade = $event" [maximoCaracteres]="20"></texto>
      <lista *ngIf="parceiro.tipoCliente === 'S' && parceiro.tipoPessoa === 'F'" class="col-sm-4" [id]="'estadoCivil'" [rotulo]="bibDialogo.estadoCivil" [campo]="parceiro.estadoCivil" [lista]="estadosCivil" (alteracao)="parceiro.estadoCivil = $event.id"></lista>
      <mascara *ngIf="parceiro.tipoFornecedor === 'S' && integracaoNeogrid" class="col-sm-4" [id]="'cnpjIntegracao'" [rotulo]="bibDialogo.cnpjNeogrid" [campo]="parceiro.cnpjIntegracao" (alteracao)="parceiro.cnpjIntegracao = $event" [tipo]="bibPropriedade.mascara.cnpj" [ajudaDireita]="true" [ajuda]="bibDialogo.cnpjNeogridFornecedor"></mascara>
    </div>
    <div *ngIf="parceiro.tipoColaborador === 'S' && utilSessao.sistema == 'aGrow-w'" class="row">
      <lista class="col-sm-4" [id]="'agronomoTecnico'" [rotulo]="bibDialogo.formacao + ' ' + bibDialogo.agricola.toLowerCase()" [campo]="parceiro.agronomoTecnico" [lista]="listaFormacao" (alteracao)="parceiro.agronomoTecnico = $event.id" [obrigatorio]="true"></lista>
      <texto *ngIf="parceiro.agronomoTecnico != 'N'" class="col-sm-4" [id]="'crea'" [rotulo]="parceiro.agronomoTecnico == 'T' ? bibDialogo.cfta : bibDialogo.crea" [campo]="parceiro.crea" (alteracao)="setCrea($event)" [tipo]="bibPropriedade.texto" [maximoCaracteres]="20"></texto>
      <atencao *ngIf="atencaoCrea" [atencao]="bibDialogo.informeAbaixoDados + ' ' + (parceiro.agronomoTecnico == 'T' ? bibDialogo.trt : bibDialogo.art)"></atencao>
    </div>
    <titulo *ngIf="!compacto" [titulo]="bibDialogo.dadosComplementares" [imagem]="bibImagem.dadoscomplementares"></titulo>
    <div class="row">
      <texto class="col-sm-6" [id]="'email'" [rotulo]="bibDialogo.email" [campo]="parceiro.email" (alteracao)="parceiro.email = $event" [tipo]="bibPropriedade.texto.email" [normal]="true" [ajuda]="bibDialogo.variosEmails" [ajudaDireita]="true" [maximoCaracteres]="100"> </texto>
      <busca [id]="'ramoAtividade'" *ngIf="parceiro.tipoCliente === 'S'" class="col-sm-6" [idMenu]="permissaoRamoAtividade && permissaoRamoAtividade.inserir == 'S' ? menuRamoAtividade.idMenu : null" [id]="'ramoAtividade'" [rotulo]="menuRamoAtividade.apelido" [campo]="parceiro.ramoAtividade" [servico]="bibServico.ramoAtividade" (alteracao)="parceiro.idRamoAtividade = $event.id"></busca>
      <lista class="col-sm-6" *ngIf="(parceiro.tipoCliente === 'S' || parceiro.tipoFornecedor === 'S') && familias.length > 0" [idMenu]="permissaoFamilia && permissaoFamilia.inserir == 'S' ? menuFamilia.idMenu : null" [id]="'familia'" [rotulo]="menuFamilia.apelido" [campo]="parceiro.idFamilia" (alteracao)="parceiro.idFamilia = $event.id" [lista]="familias" [icone]="bibIcone.atualizar" (btnAdicional)="listarFamilia()"></lista>
      <lista class="col-sm-6" *ngIf="parceiro.tipoCliente === 'S' && relacionamentos.length > 0" [idMenu]="permissaoRelacionamento && permissaoRelacionamento.inserir == 'S' ? menuRelacionamento.idMenu : null" [id]="'relacionamento'" [rotulo]="menuRelacionamento.apelido" [campo]="parceiro.idRelacionamento" (alteracao)="parceiro.idRelacionamento = $event.id" [lista]="relacionamentos" [icone]="bibIcone.atualizar" (btnAdicional)="listarRelacionamento()"></lista>
    </div>
    <div *ngIf="parceiro.tipoColaborador === 'S'">
      <titulo *ngIf="!compacto" [titulo]="bibDialogo.comissao" [imagem]="bibImagem.comissao"></titulo>
      <div class="row align-items-center">
        <lista class="col-sm-3" [id]="'comissionado'" [rotulo]="bibDialogo.comissionado" [campo]="parceiro.comissionado" [lista]="listaSimNao" (alteracao)="parceiro.comissionado = $event.id" [obrigatorio]="true"></lista>
        <decimal class="col-sm-3" [id]="'percentualComissao'" [rotulo]="bibDialogo.percentualComissao" [campo]="parceiro.percentualComissao" (alteracao)="parceiro.percentualComissao = $event" [sufixo]="'%'" [valorMaximo]="100" [desabilitado]="parceiro.comissionado == 'N'" [obrigatorio]="parceiro.comissionado == 'S'"> </decimal>
        <decimal *ngIf="utilSessao.sistema == 'aGrow-w'" class="col-sm-3" [id]="'percentualComissaoVd'" [rotulo]="bibDialogo.percentualComissao + ' ' + bibDialogo.vendaDireta.toLowerCase()" [campo]="parceiro.percentualComissaoVd" (alteracao)="parceiro.percentualComissaoVd = $event" [sufixo]="'%'" [valorMaximo]="100" [desabilitado]="parceiro.comissionado == 'N'" [ajuda]="bibDialogo.ajudaVendaDireta"> </decimal>
        <decimal class="col-sm-3" [id]="'descontoMaximo'" [rotulo]="bibDialogo.descontoMaximo" [campo]="parceiro.descontoMaximo" (alteracao)="parceiro.descontoMaximo = $event" [sufixo]="'%'" [valorMaximo]="100"> </decimal>
      </div>
    </div>
    <div *ngIf="parceiro.tipoCliente === 'S'">
      <titulo *ngIf="!compacto" [titulo]="bibDialogo.vendedor" [imagem]="bibImagem.vendedor"></titulo>
      <div class="row">
        <busca class="col-sm" [idMenu]="permissaoColaborador && permissaoColaborador.inserir == 'S' ? menuColaborador.idMenu : null" [id]="'vendedor'" [rotulo]="bibDialogo.vendedor" [campo]="parceiro.parceiroVendedor" (alteracao)="parceiro.idParceiroVendedor = $event.id" [servico]="bibServico.colaborador" [criterios]="parceiroCriterios" [ajudaDireita]="true"></busca>
      </div>
    </div>
    <titulo *ngIf="!compacto" [titulo]="bibDialogo.observacao" [imagem]="bibImagem.aspas"></titulo>
    <div class="row">
      <descricao *ngIf="parceiro.tipoCliente === 'S'" class="col-sm-12" [id]="'observacaoVenda'" [rotulo]="bibDialogo.observacaoVenda" [ajuda]="bibDialogo.observacaoApresentaVenda"  [ajudaDireita]="true" [campo]="parceiro.observacao" (alteracao)="setParceiroObservacao($event)" [maximoCaracteres]="1000"></descricao>
      <descricao *ngIf="!utilizaPesquisaCliente" class="col-sm-12" [id]="'observacaoInterna'" [rotulo]="bibDialogo.observacaoInterna" [ajuda]="bibDialogo.observacaoApresentaInterna" [ajudaDireita]="true" [campo]="parceiro.observacaoInterna" (alteracao)="parceiro.observacaoInterna = $event" [maximoCaracteres]="1000"></descricao>
    </div>
    <titulo *ngIf="!compacto && parceiro.tipoCliente === 'S'" [titulo]="bibDialogo.limiteCredito" [imagem]="bibImagem.dinheiro"> </titulo>
    <div class="row" *ngIf="parceiro.tipoCliente === 'S'">
      <div class="col-sm-10">
        <div class="row align-items-center">
          <decimal class="col-sm-4" *ngIf="permissaoLimiteCredito && permissaoLimiteCredito.editar == 'S'" [campo]="parceiro.receitaMensal" [id]="'rendaMensal'" [rotulo]="bibDialogo.rendaMensal" (alteracao)="utilLimiteCredito.setReceitaMensal(parceiro, $event)"> </decimal>
          <lista class="col-sm-4" [id]="'comissionado'" [rotulo]="bibDialogo.controla + ' ' + bibDialogo.limite.toLowerCase()" [campo]="parceiro.usaLimiteCredito" [lista]="listaSimNao" (alteracao)="usarLimite($event.id)" [obrigatorio]="true"></lista>
          <a href="#" *ngIf="parceiro.id && parceiro.usaLimiteCredito == 'S' && permissaoLimiteCredito && permissaoLimiteCredito.editar == 'S'" class="mb-0 col-sm-4 d-flex align-items-center clique" (click)="abrirModalHistorico(parceiro)" style="font-size: 13px;">
            {{ bibDialogo.visualizarHistoricoLimiteCredito }}
          </a>
        </div>
        <div *ngIf="parceiro.usaLimiteCredito == 'S'" class="row">
          <decimal class="col-sm-4" [campo]="parceiro.limiteMaximo" [id]="'limiteMaximo'" [rotulo]="bibDialogo.limiteMaximo" (alteracao)="utilLimiteCredito.setLimiteMaximo(parceiro, $event)" [desabilitado]="permissaoLimiteCredito && permissaoLimiteCredito.editar != 'S'"></decimal>
          <decimal *ngIf="empresaLimiteCreditoAutomatico" class="col-sm-4" [campo]="parceiro.limiteCredito" [id]="'limiteCredito'" [rotulo]="bibDialogo.limiteCredito" (alteracao)="utilLimiteCredito.setLimiteCredito(parceiro, $event)" [desabilitado]="empresa.limiteCredito == 2 || (permissaoLimiteCredito && permissaoLimiteCredito.editar != 'S')" [atencao]="empresaLimiteCreditoAutomatico && parceiro.limiteMaximo < parceiro.limiteCredito ? bibDialogo.limiteCredito + ' ' + (parceiro.limiteCredito - parceiro.limiteMaximo | monetario) + ' ' + bibDialogo.acimaLimiteMaximo.toLowerCase() : null"></decimal>
          <decimal class="alinhamento-limite-credito" class="col-sm-4" [campo]="parceiro.limiteDisponivel < 0 ? 0 : parceiro.limiteDisponivel" [id]="'limiteDisponivel'" [rotulo]="bibDialogo.limiteDisponivel" (alteracao)="utilLimiteCredito.setLimiteCreditoDisponivel(parceiro, $event)" [desabilitado]="empresa.limiteCredito == 1 || (permissaoLimiteCredito && permissaoLimiteCredito.editar != 'S')" [atencao]="!empresaLimiteCreditoAutomatico && parceiro.limiteMaximo < parceiro.limiteDisponivel ? bibDialogo.limiteDisponivel + ' ' + (parceiro.limiteDisponivel - parceiro.limiteMaximo | monetario) + ' ' + bibDialogo.acimaLimiteMaximo.toLowerCase() : null"></decimal>          
        </div>
      </div>
      <texto class="col-sm-12" [id]="'avalista'" [rotulo]="bibDialogo.avalista" [campo]="parceiro.avalista" (alteracao)="parceiro.avalista = $event" [maximoCaracteres]="200"></texto>
    </div>
    <div *ngIf="parceiro.tipoCliente === 'S'">
      <titulo *ngIf="!compacto" [titulo]="bibDialogo.pagamento" [imagem]="bibImagem.pagamento"></titulo>
      <div class="row">
        <apresentacao class="col-sm-4" [id]="'perda'" [rotulo]="bibDialogo.perda" [campo]="parceiro.valorPerda | monetario" [ajuda]="bibDialogo.ajudaPerda" [ajudaDireita]="true"></apresentacao>
        <lista class="col-sm" [id]="'formaDeCobranca'" [rotulo]="bibDialogo.formaDeCobranca" [campo]="parceiro.dinamicaPagamento" [lista]="listaDinamicaPagamento" (alteracao)="parceiro.dinamicaPagamento = $event.id"></lista>
      </div>
    </div>
    <div *ngIf="parceiro.tipoCliente === 'S'">
      <titulo *ngIf="!compacto" [titulo]="bibDialogo.parcelamento" [imagem]="bibImagem.acoes"></titulo>
      <div class="row">
        <div class="col-sm-4">
          <lista [id]="'negociacao'" [rotulo]="bibDialogo.forma + ' ' + bibDialogo.pagamento.toLowerCase()" [campo]="parceiro.tipoNegociacao" [ajuda]="bibDialogo.ajudaBloquearNegociacao" [ajudaDireita]="true" [lista]="tipoNegociacoes" [obrigatorio]="true" (alteracao)="setTipoNegociacao($event.id)"></lista>
        </div>
        <div class="col-sm-4">
          <div *ngIf="parceiro.tipoNegociacao == 'D'" class="col-sm">
            <texto [id]="'diaFixo'" [rotulo]="bibDialogo.diaFixo" [campo]="parceiro.diaFechamento" (alteracao)="parceiro.diaFechamento = $event" [tipo]="bibPropriedade.texto.inteiro" [obrigatorio]="true" [valorMaximo]="31"></texto>
          </div>
          <div *ngIf="parceiro.tipoNegociacao != 'L' && parceiro.tipoNegociacao != 'D' && parceiro.tipoNegociacao != 'A' && parceiro.tipoNegociacao != 'P'" class="col-sm">
            <texto [id]="'maisIncremento'" [rotulo]="bibDialogo.maisIncremento" [campo]="parceiro.prazoFixo" (alteracao)="parceiro.prazoFixo = $event" [tipo]="bibPropriedade.texto.inteiro" [prefixo]="'+'" [obrigatorio]="parceiro.tipoNegociacao == 'P'" [sufixo]="bibDialogo.dias"></texto>
          </div>
          <div *ngIf="parceiro.tipoNegociacao == 'P'" class="col-sm">
            <texto [id]="'prazoFixo'" [rotulo]="bibDialogo.prazo" [campo]="parceiro.prazoFixo" (alteracao)="parceiro.prazoFixo = $event" [tipo]="bibPropriedade.texto.inteiro" [obrigatorio]="parceiro.tipoNegociacao == 'P'" [sufixo]="bibDialogo.dias"></texto>
          </div>
        </div>
        <div class="col-sm-4">
          <lista [id]="'tipoTitulo'" [rotulo]="bibDialogo.tipoTitulo" [campo]="parceiro.idTipoTitulo" [lista]="tipoTitulos" (alteracao)="parceiro.idTipoTitulo = $event.id" [icone]="bibIcone.atualizar" (btnAdicional)="listarTipoTitulo()"></lista>
        </div>
      </div>
      <div class="row">
        <atencao class="col" [atencao]="parceiro.tipoNegociacao == 'L' ? bibDialogo.dicaParcelamemtoLivre : parceiro.tipoNegociacao == 'S' ? bibDialogo.dicaParcelamemtoSemanal : parceiro.tipoNegociacao == 'Q' ? bibDialogo.dicaParcelamemtoQuinzenal : parceiro.tipoNegociacao == 'M' ? bibDialogo.dicaParcelamemtoMensal : parceiro.tipoNegociacao == 'D' ? bibDialogo.dicaParcelamemtoDiaFixo : parceiro.tipoNegociacao == 'P' ? bibDialogo.dicaParcelamemtoPrazoFixo : parceiro.tipoNegociacao == 'A' ? bibDialogo.dicaParcelamemtoSomenteAVista : ''"></atencao>
      </div>
    </div>
    <div *ngIf="(parceiro.tipoFornecedor === 'S' || parceiro.tipoCliente === 'S') && empresaUsaContabilidade && !empresaUsaCorrentista" class="row">    
      <titulo *ngIf="!compacto" class="col-sm-12" [titulo]="bibDialogo.contaContabil" [imagem]="bibImagem.contabilidade"></titulo>
      <busca *ngIf="parceiro.tipoCliente === 'S'" class="col-sm-12" [rotulo]="bibDialogo.contaContabil + ' ' + bibDialogo.cliente" [campo]="parceiro.contaContabil" (alteracao)="parceiro.idContaContabil = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioClienteContaContabil" [buscarPorNumero]="true"></busca>
      <busca *ngIf="parceiro.tipoFornecedor === 'S'" class="col-sm-12" [rotulo]="bibDialogo.contaContabil + ' ' + bibDialogo.fornecedor" [campo]="parceiro.contaContabilFornecedor" (alteracao)="parceiro.idContaContabilFornecedor = $event.id" [servico]="bibServico.contaContabil" [criterios]="criterioFornecedorContaContabil" [buscarPorNumero]="true"></busca>
      <parceiroContaContabilEspecifica *ngIf="parceiro.tipoFornecedor === 'S'" [parceiroContasContabilEspecifica]="parceiro.parceiroContaContabilEspecificas" [excluirParceiroContasContabilEspecifica]="excluirParceiroContasContabilEspecifica"></parceiroContaContabilEspecifica>    
    </div>    
    <titulo [titulo]="menuNegociacao.apelido" [imagem]="bibImagem.credito" [desabilitado]="bloquearPermissao(parceiro.id)"> </titulo>
    <div class="row">
      <div class="col-sm-6">
        <lista [id]="'negociacao'" [rotulo]="bibDialogo.negociacao + ' ' + bibDialogo.padrao.toLowerCase()" [campo]="parceiro.idNegociacao" [ajuda]="bibDialogo.ajudaNegociacaoPadrao" [ajudaDireita]="true" [lista]="negociacoes" (alteracao)="setNegociacao($event.id)" [icone]="bibIcone.atualizar" (btnAdicional)="listarNegociacao()"></lista>
      </div>
    </div>
    <parceiroNegociacao *ngIf="parceiro.tipoCliente === 'S' && apresentaNegociacaoRestrita" [parceiro]="parceiro" [negociacoes]="negociacoes" [excluirNegociacoes]="excluirNegociacoes"> </parceiroNegociacao>
    <parceiroEndereco [idParceiro]="parceiro.id" [parceiroEnderecos]="parceiro.parceiroEnderecos" [excluirEnderecos]="excluirEnderecos" [tipoVinculo]="tipoVinculo"></parceiroEndereco>
    <parceiroTelefone [idParceiro]="parceiro.id" [parceiroTelefones]="parceiro.parceiroTelefones" [excluirTelefones]="excluirTelefones" [tipoVinculo]="tipoVinculo"></parceiroTelefone>
    <parceiroContato *ngIf="parceiro.tipoCliente === 'S' || parceiro.tipoTransportadora === 'S' || (parceiro.tipoFornecedor === 'S' && parceiro.tipoPessoa !== 'F')" [idParceiro]="parceiro.id" [parceiroContatos]="parceiro.parceiroContatos" [excluirContatos]="excluirContatos" [tipoVinculo]="tipoVinculo"></parceiroContato>
    <parceiroVeiculo *ngIf="parceiro.tipoTransportadora === 'S'" [idParceiro]="parceiro.id" [parceiroVeiculos]="parceiro.parceiroVeiculos" [excluirVeiculos]="excluirVeiculos"></parceiroVeiculo>
    <parceiroArt *ngIf="parceiro.agronomoTecnico != 'N' && parceiro.crea && parceiro.tipoColaborador === 'S' && utilSessao.sistema == 'aGrow-w'" [parceiro]="parceiro" [parceiroArts]="parceiro.parceiroArts" [excluirArts]="excluirArts"></parceiroArt>
    <parceiroDestino *ngIf="parceiro.tipoCliente === 'S'"  [idParceiro]="parceiro.id" [parceiroDestinos]="parceiro.parceiroDestinos" [parceiroNome]="parceiro.razaoSocial" [parceiroCnpjCpf]="parceiro.cnpjCpf" [tipoPessoa]="parceiro.tipoPessoa" [excluirDestinos]="excluirDestinos" [tipoVinculo]="tipoVinculo" [negociacoes]="negociacoes"></parceiroDestino>
    <parceiroOcorrencia *ngIf="motivoOcorrencias.length > 0 && parceiro.tipoCliente === 'S'" [parceiro]="parceiro" [edicaoParceiroOcorrencias]="edicaoParceiroOcorrencias" [excluirOcorrencias]="excluirOcorrencias"></parceiroOcorrencia>
    <parceiroAtualizacao *ngIf="parceiro.id > 0 && parceiro.tipoCliente === 'S'" [parceiro]="parceiro" [excluirParceiroAtualizacoes]="excluirParceiroAtualizacoes"></parceiroAtualizacao>
    <div *ngIf="!utilizaPesquisaCliente && parceiro.id && parceiro.tipoCliente === 'S'" class="row pl-sm-2 align-items-center justify-content-between">
      <titulo [titulo]="bibDialogo.historico" [imagem]="bibImagem.historico"></titulo>
      <botao class="float-right" [compacto]="compacto" [legenda]="!visualizaHistorico ? bibDialogo.visualizarHistorico : bibDialogo.ocultarHistorico" (botaoEmt)="visualizarHistorico(parceiro.id)"></botao>
      <p *ngIf="!visualizaHistorico" class="col-sm-12 pl-3">
        {{ bibDialogo.paraVisualizarHistorico + ', ' }} <strong class="clique" (click)="visualizarHistorico(parceiro.id)">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong>
      </p>
    </div>
    <ng-container *ngIf="visualizaHistorico">
      <table class="table table-responsive-sm tabela-formulario">
        <thead>
          <tr>
            <th class="l-15">{{ bibDialogo.debitosAtrasado }}</th>
            <th class="l-15">{{ bibDialogo.debitosVencer }}</th>
            <th class="l-10">{{ bibDialogo.totalAberto }}</th>
            <th class="l-10">{{ bibDialogo.mediaAtraso }}</th>
            <th class="l-15">{{ bibDialogo.atrasoMaximo }}</th>
            <th class="l-10">{{ bibDialogo.receitas + ' ' + ano }}</th>
            <th class="l-10">{{ bibDialogo.lucro + ' ' + ano }}</th>
            <th class="l-15">{{ bibDialogo.lucroMedio }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let parceiroHistorico of parceiroHistoricos">
            <tr>
              <td><apresentacao [campo]="parceiroHistorico.receitaAtrasada | monetario"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.receitaVencer | monetario"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.despesasAbertas | monetario"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.mediaAtraso + ' ' + bibDialogo.dias"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.atrasoMaximo + ' ' + bibDialogo.dias"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.receitasAno | monetario"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.lucroAno | monetario"></apresentacao></td>
              <td><apresentacao [campo]="parceiroHistorico.lucroAno > 0 ? ((parceiroHistorico.receitasAno * 100) / parceiroHistorico.lucroAno | monetario) + ' %' : 0 + ' %'"></apresentacao></td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>
    <div *ngIf="parceiro.id && parceiro.tipoCliente === 'S' && visualizaMovimentacaoOutroVendedor" class="row pl-sm-2 align-items-center justify-content-between">
      <titulo [titulo]="bibDialogo.resumoMovimentacoes" [imagem]="bibImagem.resumo"></titulo>
      <botao class="float-right" [compacto]="compacto" [legenda]="!resumo ? bibDialogo.visualizarResumo : bibDialogo.ocultarResumo" (botaoEmt)="visualizarResumoMovimentacao(parceiro.id)"></botao>
      <p *ngIf="!resumo" class="col-sm-12 pl-3">
        {{ bibDialogo.paraVisualizarResumo + ', ' }} <strong class="clique" (click)="visualizarResumoMovimentacao(parceiro.id)">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong>
      </p>
    </div>
    <div *ngIf="resumo && operacoes.length == 0" class="card-body">
      <header class="card-header row justify-content-between align-items-center">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab">{{ bibDialogo.parceiroNaoPossuiMovimentacao.toUpperCase() }}</a>
          </li>
        </ul>
      </header>
    </div>
    <div *ngIf="resumo && operacoes.length > 0" class="card-body">
      <div id="myTabContent" class="tab-content">
        <header class="card-header row justify-content-between align-items-center">
          <ul class="nav nav-tabs card-header-tabs">
            <ng-container *ngFor="let operacao of operacoes; let i = index">
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" [ngClass]="{ 'active show': i == 0 }" [href]="'#' + i">{{ operacao.nome }}</a>
              </li>
            </ng-container>
          </ul>
        </header>
        <div class="card-body">
          <div id="myTabContent" class="tab-content">
            <ng-container *ngFor="let operacao of operacoes; let i = index">
              <div class="tab-pane fade" [ngClass]="{ 'active show': i == 0 }" [id]="i">
                <pesquisaClienteMovimentacaoProduto [movimentacoes]="movimentacaoPorOperacoes[operacao.nome]" [apresentaTodos]="operacao.nome == 'TODOS'"></pesquisaClienteMovimentacaoProduto>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <parceiroCobranca *ngIf="utilSessao.sistema == 'aGrow-w' && parceiro.id > 0 && parceiro.tipoCliente === 'S'" [parceiro]="parceiro" [excluirParceiroCobrancas]="excluirParceiroCobrancas"></parceiroCobranca>
    <titulo [titulo]="bibDialogo.lojaOrigem" [imagem]="bibImagem.loja"></titulo>
    <div class="row">
      <lista class="col-sm-12" [id]="'loja'" [rotulo]="bibDialogo.lojaOrigem" [tipo]="bibPropriedade.lista.lojaUsuario" [campo]="parceiro.idLoja" (alteracao)="parceiro.idLoja = $event.id ? $event.id : $event" [obrigatorioLoja]="true" [icone]="bibIcone.atualizar" (btnAdicional)="utilBusca.listarLojas()"></lista>
    </div>
  </ng-container>
</layout>
<div *ngIf="(parceiro.id && utilizaPesquisaCliente) || utilizaPesquisaCliente == false">
  <botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="ehAtualizarParceiro()" [desabilitado]="bloquearPermissao(parceiro.id)"></botao>
  <botao [compacto]="compacto" [legenda]="bibDialogo.salvarAdicionarNovo" (botaoEmt)="ehAtualizarParceiro(true)" [desabilitado]="bloquearPermissao(parceiro.id)"></botao>
</div>
<ng-container *ngIf="(parceiro.id && utilizaPesquisaCliente) || utilizaPesquisaCliente == false">
  <auditoria *ngIf="parceiro.id" [idUsuarioInclusao]="parceiro.idUsuarioInclusao" [dataHoraInclusao]="parceiro.dataHoraInclusao" [idUsuarioAlteracao]="parceiro.idUsuarioAlteracao" [dataHoraAlteracao]="parceiro.dataHoraAlteracao"></auditoria>
</ng-container>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
