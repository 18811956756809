<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [grupos]="grupos" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)" (campoGrupoEmt)="agruparRelatorio(financeiros, $event)" (agrupaTotalizadorEmt)="agruparTotalizador()">
  <table>
    <thead>
        <th *ngIf="agrupaLoja == false" class="r-0-5 clique" (click)="ordenar(financeiros, 'abreviacao')">{{ bibDialogo.loja }}</th>
        <th *ngIf="agrupaCliente == false" class="r clique" (click)="ordenar(financeiros, 'cliente')">{{ bibDialogo.cliente }}</th>        
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'dataNegociacao')">{{ bibDialogo.data + ' ' + bibDialogo.negociacao }}</th>
        <th *ngIf="agrupaDataVencimento == false" class="r-1 text-right clique" (click)="ordenar(financeiros, 'dataVencimento')">{{ bibDialogo.data + ' ' + bibDialogo.vencimento }}</th>
        <th *ngIf="agrupaDataVencimento == false" class="r-0-5"></th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'numero')">{{ bibDialogo.numero }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'numeroFiscal')">{{ bibDialogo.numeroFiscal }}</th>
        <th class="r-1 text-right clique" (click)="ordenar(financeiros, 'valorRestante')">{{ bibDialogo.valorRestante }}</th>  
        <th *ngIf="usaAtencipacao" class="r-1 text-right clique" (click)="ordenar(financeiros, 'descontoAntecipacao')">{{ bibDialogo.descontoAntecipacao }}</th> 
        <th *ngIf="usaAtencipacao"class="r-1 text-right">{{ bibDialogo.valorResTotal }} <ajuda [ajuda]="bibDialogo.ajudaValorRestanteTotal"></ajuda></th>    
        <th *ngIf="agrupaCliente" class="r"></th>        
      </thead>
      <tbody>
        <ng-container *ngFor="let financeiro of financeiros; let i = index">
          <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
          <tr class="conteudo">
            <td *ngIf="agrupaLoja == false">{{ financeiro.loja }}</td>
            <td *ngIf="agrupaCliente == false"><a href="#" (click)="abrirModalCliente(financeiro.idCliente)">{{ financeiro.cliente }}</a></td>
            <td class="text-right">{{ financeiro.dataNegociacao | data }}</td>
            <td class="text-right" *ngIf="agrupaDataVencimento == false">{{ financeiro.dataVencimento | data }}</td>
            <td class="text-right" *ngIf="agrupaDataVencimento == false"><atencao *ngIf="financeiro.diaAtraso > 0" [atencao]="financeiro.diaAtraso" [ajuda]="bibDialogo.diasAtraso" [tipo]="bibPropriedade.atencao.alerta"></atencao></td>
            <td *ngIf="financeiro.idMovimentacao == null" class="text-right">{{ financeiro.numero > 0 ? financeiro.numero : '' }}</td>
            <td *ngIf="financeiro.idMovimentacao" class="text-right"><a href="#" (click)="abrirModalMovimentacao(financeiro.idMovimentacao)">{{ financeiro.numero }}</a></td>
            <td *ngIf="financeiro.idMovimentacaoFiscal == null" class="text-right">{{ financeiro.numeroFiscal }}</td>
            <td *ngIf="financeiro.idMovimentacaoFiscal" class="text-right"><a href="#" (click)="abrirModalMovimentacaoFiscal(financeiro.idMovimentacaoFiscal)">{{ financeiro.numeroFiscal }}</a></td>
            <td class="text-right"><a href="#" (click)="abrirModalFinanceiro(financeiro.id)">{{ financeiro.valorRestante | monetario }}</a></td>
            <td *ngIf="usaAtencipacao" class="text-right">{{ financeiro.descontoAntecipacao > 0 ?  (financeiro.descontoAntecipacao | monetario) : '' }}</td>
            <td *ngIf="usaAtencipacao" class="text-right">{{ (financeiro.valorRestante - financeiro.descontoAntecipacao) > 0 ? ((financeiro.valorRestante - financeiro.descontoAntecipacao) | monetario) : '' }}</td>
            <td *ngIf="agrupaCliente"></td>        
          </tr>
          <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" [ehAgrupadoOcultado]="ehAgrupadoOcultado"></tr>
        </ng-container>
      </tbody>
    <tfoot>
        <tr *ngIf="financeiros.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo" [rodapePagina]="true"></tr>
    </tfoot> 
</table>
</relatorioLayout>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>