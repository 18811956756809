import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutenticacaoGuard } from './guardas/autenticacao.guard';
import { AutenticacaoRelatorioGuard } from './guardas/autenticacaoRelatorio.guard';
import { AlterarMovimentacaoComponent } from './pagina/alterarMovimentacao/alterarMovimentacao.component';
import { AprovacaoComponent } from './pagina/aprovacao/aprovacao.component';
import { BaixaComponent } from './pagina/baixa/baixa.component';
import { BloqueiaEstoqueComponent } from './pagina/bloquearEstoque/bloqueiaEstoque.component';
import { BloqueioPeriodoComponent } from './pagina/bloqueioPeriodo/bloqueioPeriodo.component';
import { BloqueioPeriodoFrmComponent } from './pagina/bloqueioPeriodo/bloqueioPeriodoFrm.component';
import { CaixaComponent } from './pagina/caixa/caixa.component';
import { CaixaFrmComponent } from './pagina/caixa/caixaFrm.component';
import { CancelamentoComponent } from './pagina/cancelamento/cancelamento.component';
import { CancelamentoEstornoComponent } from './pagina/cancelamentoEstorno/cancelamentoEstorno.component';
import { CestComponent } from './pagina/cest/cest.component';
import { CestFrmComponent } from './pagina/cest/cestFrm.component';
import { CfopEmpresaComponent } from './pagina/cfopEmpresa/cfopEmpresa.component';
import { CfopEmpresaFrmComponent } from './pagina/cfopEmpresa/cfopEmpresaFrm.component';
import { ChequeComponent } from './pagina/cheque/cheque.component';
import { ChequeFrmComponent } from './pagina/cheque/chequeFrm.component';
import { ClassificacaoComponent } from './pagina/classificacao/classificacao.component';
import { ClassificacaoFrmComponent } from './pagina/classificacao/classificacaoFrm.component';
import { ClassificacaoFiscalComponent } from './pagina/classificacaoFiscal/classificacaoFiscal.component';
import { ClassificacaoFiscalFrmComponent } from './pagina/classificacaoFiscal/classificacaoFiscalFrm.component';
import { ClienteEspecialComponent } from './pagina/clienteEspecial/clienteEspecial.component';
import { ComissaoFaturamentoComponent } from './pagina/comissaoFaturamento/comissaoFaturamento.component';
import { ComissaoFaturamentoEstornoComponent } from './pagina/comissaoFaturamentoEstorno/comissaoFaturamentoEstorno.component';
import { ConciliacaoComponent } from './pagina/conciliacao/conciliacao.component';
import { ConfiguracaoContabilComponent } from './pagina/configuracaoContabil/configuracaoContabil.component';
import { ConfiguracaoContabilFrmComponent } from './pagina/configuracaoContabil/configuracaoContabilFrm/configuracaoContabilFrm.component';
import { ConfiguracaoRelatorioContabilComponent } from './pagina/configuracaoRelatorioContabil/configuracaoRelatorioContabil.component';
import { ConfiguracaoRelatorioContabilFrmComponent } from './pagina/configuracaoRelatorioContabil/configuracaoRelatorioContabilFrm.component';
import { ContaComponent } from './pagina/conta/conta.component';
import { ContaFrmComponent } from './pagina/conta/contaFrm.component';
import { ContaContabilComponent } from './pagina/contaContabil/contaContabil.component';
import { ContaContabilFrmComponent } from './pagina/contaContabil/contaContabilFrm.component';
import { ContaCorrenteComponent } from './pagina/contaCorrente/contaCorrente.component';
import { CreditoComponent } from './pagina/credito/credito.component';
import { DesfazimentoComponent } from './pagina/desfazimento/desfazimento.component';
import { DesfazimentoFrmComponent } from './pagina/desfazimento/desfazimentoFrm/desfazimentoFrm.component';
import { DesfazimentoEstornoComponent } from './pagina/desfazimentoEstorno/desfazimentoEstorno.component';
import { DocumentoEletronicoComponent } from './pagina/documentoEletronico/documentoEletronico.component';
import { DocumentoEletronicoMDFeComponent } from './pagina/documentoEletronicoMDFe/documentoEletronicoMDFe.component';
import { DocumentoEletronicoCTeComponent } from './pagina/documentoEletronicoCTe/documentoEletronicoCTe.component';
import { DocumentoEletronicoReceituarioComponent } from './pagina/documentoEletronicoReceituario/documentoEletronicoReceituario.component';
import { EmpresaComponent } from './pagina/empresa/empresa.component';
import { EncerramentoContabilComponent } from './pagina/encerramentoContabil/encerramentoContabil.component';
import { EncerramentoContabilFrmComponent } from './pagina/encerramentoContabil/encerramentoContabilFrm.component';
import { EntregasComponent } from './pagina/entregas/entregas.component';
import { EquipeComponent } from './pagina/equipe/equipe.component';
import { EquipeFrmComponent } from './pagina/equipe/frm/equipeFrm.component';
import { EquipeParceiroComponent } from './pagina/equipe/frm/equipeParceiro/equipeParceiro.component';
import { EspecialComponent } from './pagina/especial/especial.component';
import { EspecialFrmComponent } from './pagina/especial/especialFrm.component';
import { EstoqueComponent } from './pagina/estoque/estoque.component';
import { FabricanteComponent } from './pagina/fabricante/fabricante.component';
import { FabricanteFrmComponent } from './pagina/fabricante/fabricanteFrm.component';
import { FinanceiroComponent } from './pagina/financeiro/financeiro.component';
import { FinanceiroFrmComponent } from './pagina/financeiro/frm/financeiroFrm.component';
import { FinanceiroEstornoComponent } from './pagina/financeiroEstorno/financeiroEstorno.component';
import { GenericoComponent } from './pagina/generico/generico.component';
import { GenericoFrmComponent } from './pagina/generico/genericoFrm.component';
import { GenericoHierarquiaComponent } from './pagina/genericoHierarquia/genericoHierarquia.component';
import { GenericoHierarquiaFrmComponent } from './pagina/genericoHierarquia/genericoHierarquiaFrm.component';
import { GrupoComponent } from './pagina/grupo/grupo.component';
import { GrupoFrmComponent } from './pagina/grupo/grupoFrm.component';
import { GrupoAcessoComponent } from './pagina/grupoAcesso/grupoAcesso.component';
import { GrupoAcessoFrmComponent } from './pagina/grupoAcesso/grupoAcessoFrm.component';
import { GrupoContabilComponent } from './pagina/grupoContabil/grupoContabil.component';
import { GrupoContabilFrmComponent } from './pagina/grupoContabil/grupoContabilFrm/grupoContabilFrm.component';
import { GrupoPermissaoFrmComponent } from './pagina/grupoPermissao/frm/grupoPermissaoFrm.component';
import { GrupoPermissaoComponent } from './pagina/grupoPermissao/grupoPermissao.component';
import { GrupoValorComponent } from './pagina/grupoValor/grupoValor.component';
import { HistoricoComponent } from './pagina/historico/historico.component';
import { HistoricoFrmComponent } from './pagina/historico/historicoFrm.component';
import { GrupoImpostoComponent } from './pagina/imposto/grupoImposto/grupoImposto.component';
import { GrupoImpostoFrmComponent } from './pagina/imposto/grupoImposto/grupoImpostoFrm.component';
import { ProdutoImpostoComponent } from './pagina/imposto/produtoImposto/produtoImposto.component';
import { ProdutoImpostoFrmComponent } from './pagina/imposto/produtoImposto/produtoImpostoFrm.component';
import { IntegracaoListaComponent } from './pagina/integracaoLista/integracaoLista.component';
import { LancamentoContabilComponent } from './pagina/lancamentoContabil/lancamentoContabil.component';
import { LancamentoContabilFrmComponent } from './pagina/lancamentoContabil/lancamentoContabilFrm.component';
import { LimiteCreditoComponent } from './pagina/limiteCredito/limiteCredito.component';
import { LocalComponent } from './pagina/local/local.component';
import { LocalFrmComponent } from './pagina/local/localFrm.component';
import { LoginComponent } from './pagina/login/login.component';
import { LojaFrmComponent } from './pagina/loja/frm/lojaFrm.component';
import { LojaComponent } from './pagina/loja/loja.component';
import { MdfeFrmComponent } from './pagina/mdfe/frm/mdfeFrm.component';
import { MdfeComponent } from './pagina/mdfe/mdfe.component';
import { MenuEmpresaComponent } from './pagina/menuEmpresa/menuEmpresa.component';
import { MenuPersonalizadoComponent } from './pagina/menuPersonalizado/menuPersonalizado.component';
import { ModuloEmpresaFrmComponent } from './pagina/moduloEmpresa/menuModuloFrm/moduloEmpresaFrm.component';
import { ModuloEmpresaComponent } from './pagina/moduloEmpresa/moduloEmpresa.component';
import { MonitoramentoComponent } from './pagina/monitoramento/monitoramento.component';
import { MovimentacaoFrmComponent } from './pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { MovimentacaoComponent } from './pagina/movimentacao/movimentacao.component';
import { MovimentacaoAbrirComponent } from './pagina/movimentacaoAbrir/movimentacaoAbrir.component';
import { NaturezaOperacaoComponent } from './pagina/naturezaOperacao/naturezaOperacao.component';
import { NaturezaOperacaoFrmComponent } from './pagina/naturezaOperacao/naturezaOperacaoFrm.component';
import { NegociacaoComponent } from './pagina/negociacao/negociacao.component';
import { NegociacaoFrmComponent } from './pagina/negociacao/negociacaoFrm.component';
import { NfeXmlPdfComponent } from './pagina/nfeXmlPdf/nfeXmlPdf.component';
import { NfeXmlPdfFrmComponent } from './pagina/nfeXmlPdf/nfeXmlPdfFrm.component';
import { NomenclaturaEmpresaComponent } from './pagina/nomenclaturaEmpresa/nomenclaturaEmpresa.component';
import { ParceiroFrmComponent } from './pagina/parceiro/frm/parceiroFrm.component';
import { ParceiroComponent } from './pagina/parceiro/parceiro.component';
import { PatrimonialFrmComponent } from './pagina/patrimonial/frm/patrimonialFrm.component';
import { PatrimonialComponent } from './pagina/patrimonial/patrimonial.component';
import { PolegadaComponent } from './pagina/polegada/polegada.component';
import { PolegadaFrmComponent } from './pagina/polegada/polegadaFrm.component';
import { PrincipalComponent } from './pagina/principal/principal.component';
import { ProdutoFrmComponent } from './pagina/produto/frm/produtoFrm.component';
import { ProdutoComponent } from './pagina/produto/produto.component';
import { ProdutoComissaoComponent } from './pagina/produtoComissao/produtoComissao.component';
import { ProdutoValorComponent } from './pagina/produtoValor/produtoValor.component';
import { ProdutoValorHistoricoComponent } from './pagina/produtoValorHistorico/produtoValorHistorico.component';
import { RelacionamentoComponent } from './pagina/relacionamento/relacionamento.component';
import { RelacionamentoFrmComponent } from './pagina/relacionamento/relacionamentoFrm.component';
import { RelatorioListaComponent } from './pagina/relatorioLista/relatorioLista.component';
import { RelatorioPersonalizadoComponent } from './pagina/relatorioPersonalizado/relatorioPersonalizado.component';
import { RomaneioEntregaFrmComponent } from './pagina/romaneioEntrega/frm/romaneioEntregaFrm.component';
import { RomaneioEntregaComponent } from './pagina/romaneioEntrega/romaneioEntrega.component';
import { RoteiroContabilComponent } from './pagina/roteiroContabil/roteiroContabil.component';
import { RoteiroContabilFrmComponent } from './pagina/roteiroContabil/roteiroContabilFrm.component';
import { SaldoBancarioComponent } from './pagina/saldoBancario/saldoBancario.component';
import { SepararEntregaComponent } from './pagina/separarEntrega/separarEntrega.component';
import { SepararEntregaFrmComponent } from './pagina/separarEntrega/separarEntregaFrm.component';
import { TipoTituloComponent } from './pagina/tipoTitulo/tipoTitulo.component';
import { TipoTituloFrmComponent } from './pagina/tipoTitulo/tipoTituloFrm.component';
import { TransferenciaBancariaComponent } from './pagina/transferenciaBancaria/transferenciaBancaria.component';
import { TransferenciaBancariaFrmComponent } from './pagina/transferenciaBancaria/transferenciaBancariaFrm.component';
import { UnidadeComponent } from './pagina/unidade/unidade.component';
import { UnidadeFrmComponent } from './pagina/unidade/unidadeFrm.component';
import { UsuarioFrmComponent } from './pagina/usuario/frm/usuarioFrm.component';
import { UsuarioComponent } from './pagina/usuario/usuario.component';
import { VinculoComponent } from './pagina/vinculo/vinculo.component';
import { VinculoFrmComponent } from './pagina/vinculo/vinculoFrm.component';
import { R1Component } from './relatorio/r1/r1.component';
import { R1001Component } from './relatorio/r1001/r1001.component';
import { R1002Component } from './relatorio/r1002/r1002.component';
import { R1003Component } from './relatorio/r1003/r1003.component';
import { R1004Component } from './relatorio/r1004/r1004.component';
import { R1009Component } from './relatorio/r1009/r1009.component';
import { R1010Component } from './relatorio/r1010/r1010.component';
import { R1012Component } from './relatorio/r1012/r1012.component';
import { R1014Component } from './relatorio/r1014/r1014.component';
import { R1043Component } from './relatorio/r1043/r1043.component';
import { R1053Component } from './relatorio/r1053/r1053.component';
import { R1085Component } from './relatorio/r1085/r1085.component';
import { R1089Component } from './relatorio/r1089/r1089.component';
import { R1105Component } from './relatorio/r1105/r1105.component';
import { R1109Component } from './relatorio/r1109/r1109.component';
import { R1110Component } from './relatorio/r1110/r1110.component';
import { R1111Component } from './relatorio/r1111/r1111.component';
import { R1112Component } from './relatorio/r1112/r1112.component';
import { R1113Component } from './relatorio/r1113/r1113.component';
import { R1114Component } from './relatorio/r1114/r1114.component';
import { R1115Component } from './relatorio/r1115/r1115.component';
import { R1116Component } from './relatorio/r1116/r1116.component';
import { R1117Component } from './relatorio/r1117/r1117.component';
import { R1118Component } from './relatorio/r1118/r1118.component';
import { R1125Component } from './relatorio/r1125/r1125.component';
import { R1126Component } from './relatorio/r1126/r1126.component';
import { R1127Component } from './relatorio/r1127/r1127.component';
import { R1128Component } from './relatorio/r1128/r1128.component';
import { R1131Component } from './relatorio/r1131/r1131.component';
import { R1132Component } from './relatorio/r1132/r1132.component';
import { R1134Component } from './relatorio/r1134/r1134.component';
import { R1138Component } from './relatorio/r1138/r1138.component';
import { R1144Component } from './relatorio/r1144/r1144.component';
import { R1145Component } from './relatorio/r1145/r1145.component';
import { R1151Component } from './relatorio/r1151/r1151.component';
import { R1152Component } from './relatorio/r1152/r1152.component';
import { R1159Component } from './relatorio/r1159/r1159.component';
import { R1167Component } from './relatorio/r1167/r1167.component';
import { R1168Component } from './relatorio/r1168/r1168.component';
import { R1198Component } from './relatorio/r1198/r1198.component';
import { R1206Component } from './relatorio/r1206/r1206.component';
import { R1233Component } from './relatorio/r1233/r1233.component';
import { R1248Component } from './relatorio/r1248/r1248.component';
import { R1268Component } from './relatorio/r1268/r1268.component';
import { R2Component } from './relatorio/r2/r2.component';
import { R2000Component } from './relatorio/r2000/r2000.component';
import { R2001Component } from './relatorio/r2001/r2001.component';
import { R2002Component } from './relatorio/r2002/r2002.component';
import { R2003Component } from './relatorio/r2003/r2003.component';
import { R2004Component } from './relatorio/r2004/r2004.component';
import { R2005Component } from './relatorio/r2005/r2005.component';
import { R2006Component } from './relatorio/r2006/r2006.component';
import { R2007Component } from './relatorio/r2007/r2007.component';
import { R2008Component } from './relatorio/r2008/r2008.component';
import { R2009Component } from './relatorio/r2009/r2009.component';
import { R2010Component } from './relatorio/r2010/r2010.component';
import { R2011Component } from './relatorio/r2011/r2011.component';
import { R2012Component } from './relatorio/r2012/r2012.component';
import { R2013Component } from './relatorio/r2013/r2013.component';
import { R2014Component } from './relatorio/r2014/r2014.component';
import { R2015Component } from './relatorio/r2015/r2015.component';
import { R2016Component } from './relatorio/r2016/r2016.component';
import { R2017Component } from './relatorio/r2017/r2017.component';
import { R2018Component } from './relatorio/r2018/r2018.component';
import { R2019Component } from './relatorio/r2019/r2019.component';
import { R2020Component } from './relatorio/r2020/r2020.component';
import { R2021Component } from './relatorio/r2021/r2021.component';
import { R2022Component } from './relatorio/r2022/r2022.component';
import { R2023Component } from './relatorio/r2023/r2023.component';
import { R2024Component } from './relatorio/r2024/r2024.component';
import { R2025Component } from './relatorio/r2025/r2025.component';
import { R2026Component } from './relatorio/r2026/r2026.component';
import { R2027Component } from './relatorio/r2027/r2027.component';
import { R2028Component } from './relatorio/r2028/r2028.component';
import { R2029Component } from './relatorio/r2029/r2029.component';
import { R2030Component } from './relatorio/r2030/r2030.component';
import { R2031Component } from './relatorio/r2031/r2031.component';
import { R2032Component } from './relatorio/r2032/r2032.component';
import { R2033Component } from './relatorio/r2033/r2033.component';
import { R3Component } from './relatorio/r3/r3.component';
import { R4Component } from './relatorio/r4/r4.component';
import { RegiaoComponent } from './pagina/regiao/regiao.component';
import { RegiaoFrmComponent } from './pagina/regiao/regiaoFrm.component';
import { EquipamentoComponent } from './pagina/equipamento/equipamento.component';
import { EquipamentoFrmComponent } from './pagina/equipamento/equipamentoFrm.component';
import { PragaFrmComponent } from './pagina/praga/pragaFrm.component';
import { PragaComponent } from './pagina/praga/praga.component';
import { CulturaComponent } from './pagina/cultura/cultura.component';
import { CulturaFrmComponent } from './pagina/cultura/culturaFrm.component';
import { CteComponent } from './pagina/cte/cte.component';
import { CteFrmComponent } from './pagina/cte/cteFrm.component';

const routes: Routes = [
  {
    path: 'aprovacao/:parametro',
    component: AprovacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'bairro',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'bairroFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'bairroFrm/:id',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'baixa/:parametro',
    component: BaixaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'bloqueioPeriodo/:parametro',
    component: BloqueioPeriodoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'bloqueioPeriodoFrm',
    component: BloqueioPeriodoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'caixa',
    component: CaixaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'caixaFrm',
    component: CaixaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'configuracao/:parametro',
    component: ConfiguracaoRelatorioContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoFrm',
    component: ConfiguracaoRelatorioContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cancelamento',
    component: CancelamentoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'cest',
    component: CestComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'cestFrm',
    component: CestFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'cheque',
    component: ChequeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'chequeFrm',
    component: ChequeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'chequeFrm/:id',
    component: ChequeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'cidade',
    loadChildren: () => import('./pagina/cidade/cidade.module').then((cidade) => cidade.CidadeModule),
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'classificacaoFiscal',
    component: ClassificacaoFiscalComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'classificacaoFiscalFrm',
    component: ClassificacaoFiscalFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'classificacao',
    component: ClassificacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'classificacaoFrm',
    component: ClassificacaoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'classificacaoFiscalFrm/:id',
    component: ClassificacaoFiscalFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'categoriaRoteiroContabil',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'categoriaRoteiroContabilFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'conciliacao/:parametro',
    component: ConciliacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoFinanceiroContabil',
    component: ConfiguracaoContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoFinanceiroContabilFrm',
    component: ConfiguracaoContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoMovimentacaoContabil',
    component: ConfiguracaoContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoMovimentacaoContabilFrm',
    component: ConfiguracaoContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoTransferenciaContabil',
    component: ConfiguracaoContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'configuracaoTransferenciaContabilFrm',
    component: ConfiguracaoContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'desfazimento/:parametro',
    component: DesfazimentoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'desfazimentoFrm',
    component: DesfazimentoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'desfazimentoEstorno',
    component: DesfazimentoEstornoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'financeiroEstorno/:parametro',
    component: FinanceiroEstornoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'financeiroEstorno/:parametro',
    component: FinanceiroEstornoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'comissaoFaturamento',
    component: ComissaoFaturamentoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'comissaoEstorno',
    component: ComissaoFaturamentoEstornoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'cancelamentoEstorno',
    component: CancelamentoEstornoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'conta',
    component: ContaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'contaFrm',
    component: ContaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'cfopEmpresa',
    component: CfopEmpresaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cfopEmpresaFrm',
    component: CfopEmpresaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cfopEmpresaFrm/:id',
    component: CfopEmpresaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'credito/:parametro',
    component: CreditoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'empresa',
    component: EmpresaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'encerramentoContabil',
    component: EncerramentoContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'encerramentoContabilFrm',
    component: EncerramentoContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'equipe',
    component: EquipeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'equipeFrm',
    component: EquipeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'equipeParceiro',
    component: EquipeParceiroComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'entrega',
    component: SepararEntregaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'entregaFrm',
    component: SepararEntregaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'entregas',
    component: EntregasComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'estoque',
    component: EstoqueComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'fabricante',
    component: FabricanteComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'fabricanteFrm',
    component: FabricanteFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'regiao',
    component: RegiaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'regiaoFrm',
    component: RegiaoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'polegada',
    component: PolegadaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'polegadaFrm',
    component: PolegadaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'financeiro/:tipo',
    component: FinanceiroComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'financeiroFrm',
    component: FinanceiroFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'renegociacao/:tipo',
    component: FinanceiroComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'renegociacaoFrm',
    component: FinanceiroFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'contaCorrente',
    component: ContaCorrenteComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'genericoHierarquia/:parametro',
    component: GenericoHierarquiaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'genericoHierarquiaFrm',
    component: GenericoHierarquiaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'genericoHierarquiaFrm/:id',
    component: GenericoHierarquiaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'grupo/:parametro',
    component: GrupoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoFrm',
    component: GrupoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoFrm/:id',
    component: GrupoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'grupoAcesso',
    component: GrupoAcessoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoAcessoFrm',
    component: GrupoAcessoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoImposto',
    component: GrupoImpostoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoImpostoFrm',
    component: GrupoImpostoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'grupoValor',
    component: GrupoValorComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoPermissao',
    component: GrupoPermissaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'grupoPermissaoFrm',
    component: GrupoPermissaoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'local',
    component: LocalComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'localFrm',
    component: LocalFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  { path: 'login', component: LoginComponent, runGuardsAndResolvers: 'always' },
  { path: 'login/:dominio', component: LoginComponent, runGuardsAndResolvers: 'always' },

  {
    path: 'loja',
    component: LojaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lojaFrm',
    component: LojaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lojaFrm/:id',
    component: LojaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'mdfe',
    component: MdfeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'mdfeFrm',
    component: MdfeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'mdfeFrm/:id',
    component: MdfeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'menuEmpresa',
    component: MenuEmpresaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'moduloEmpresa',
    component: ModuloEmpresaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'moduloEmpresaFrm',
    component: ModuloEmpresaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'menuPersonalizado',
    component: MenuPersonalizadoComponent,
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'movimentacao/:idOperacao',
    component: MovimentacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'movimentacaoFrm',
    component: MovimentacaoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'movimentacaoAbrir',
    component: MovimentacaoAbrirComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'alterarMovimentacao',
    component: AlterarMovimentacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'motivoOcorrencia',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'motivoOcorrenciaFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'motivoOcorrenciaFrm/:id',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'documentoEletronico/:id',
    component: DocumentoEletronicoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'documentoEletronicoMDFe/:id',
    component: DocumentoEletronicoMDFeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'documentoEletronicoCTe/:id',
    component: DocumentoEletronicoCTeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'documentoEletronicoReceituario/:id',
    component: DocumentoEletronicoReceituarioComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'naturezaOperacao',
    component: NaturezaOperacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'naturezaOperacaoFrm',
    component: NaturezaOperacaoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'naturezaOperacaoFrm/:id',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'negociacao',
    component: NegociacaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'negociacaoFrm',
    component: NegociacaoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'nomenclaturaEmpresa',
    component: NomenclaturaEmpresaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'parceiro/:parametro',
    component: ParceiroComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'parceiroFrm',
    component: ParceiroFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'patrimonial',
    component: PatrimonialComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'patrimonialFrm',
    component: PatrimonialFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'clienteEspecial',
    component: ClienteEspecialComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'bloqueiaEstoque',
    component: BloqueiaEstoqueComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'praca',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pracaFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'familia/:parametro',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'familiaFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lancamentoContabil/:parametro',
    component: LancamentoContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'lancamentoContabilFrm',
    component: LancamentoContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'limiteCredito',
    component: LimiteCreditoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'contaContabil',
    component: ContaContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'contaContabilFrm',
    component: ContaContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'historico',
    component: HistoricoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'historicoFrm',
    component: HistoricoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'produto',
    component: ProdutoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'produtoFrm',
    component: ProdutoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'produtoFrm/:id',
    component: ProdutoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'produtoImposto',
    component: ProdutoImpostoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'produtoImpostoFrm',
    component: ProdutoImpostoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'produtoValorHistorico/:parametro',
    component: ProdutoValorHistoricoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'parceiroComissao/:parametro',
    component: ProdutoComissaoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'produtoValor',
    component: ProdutoValorComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'ramoAtividade',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'ramoAtividadeFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'ramoAtividadeFrm/:id',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'relacionamento',
    component: RelacionamentoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'relacionamentoFrm',
    component: RelacionamentoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'relatorioLista/:operacao',
    component: RelatorioListaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'relatorioPersonalizado',
    component: RelatorioPersonalizadoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'roteiroContabilFrm',
    component: RoteiroContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'roteiroContabil',
    component: RoteiroContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'saldoBancario',
    component: SaldoBancarioComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tipoLancamentoContabil',
    component: GenericoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tipoLancamentoContabilFrm',
    component: GenericoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tipoTitulo/:parametro',
    component: TipoTituloComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tipoTituloFrm',
    component: TipoTituloFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'especial',
    component: EspecialComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'especialFrm',
    component: EspecialFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'transferenciaBancaria',
    component: TransferenciaBancariaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'transferenciaBancariaFrm',
    component: TransferenciaBancariaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'vinculo',
    component: VinculoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'vinculoFrm',
    component: VinculoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'vinculoFrm/:id',
    component: VinculoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'romaneioEntrega',
    component: RomaneioEntregaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'romaneioEntregaFrm',
    component: RomaneioEntregaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'romaneioEntregaFrm/:id',
    component: RomaneioEntregaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'nfeXmlPdf',
    component: NfeXmlPdfComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'nfeXmlPdfFrm',
    component: NfeXmlPdfFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'nfeXmlPdfFrm/:id',
    component: NfeXmlPdfFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'monitoramento',
    component: MonitoramentoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'unidade',
    component: UnidadeComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'unidadeFrm',
    component: UnidadeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'unidadeFrm/:id',
    component: UnidadeFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'praga',
    component: PragaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pragaFrm',
    component: PragaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'pragaFrm/:id',
    component: PragaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cultura',
    component: CulturaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'culturaFrm',
    component: CulturaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'culturaFrm/:id',
    component: CulturaFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cte',
    component: CteComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cteFrm',
    component: CteFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'cteFrm/:id',
    component: CteFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'equipamento',
    component: EquipamentoComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'equipamentoFrm',
    component: EquipamentoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'equipamentoFrm/:id',
    component: EquipamentoFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'usuario',
    component: UsuarioComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'usuarioFrm',
    component: UsuarioFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'usuarioFrm/:parametro',
    component: UsuarioFrmComponent,
  },
  {
    path: 'integracaoLista',
    component: IntegracaoListaComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoContabil',
    component: GrupoContabilComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'grupoContabilFrm',
    component: GrupoContabilFrmComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'relatorio/r1/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1Component,
  },
  {
    path: 'relatorio/r2/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2Component,
  },
  {
    path: 'relatorio/r3/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R3Component,
  },
  {
    path: 'relatorio/r4/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R4Component,
  },
  {
    path: 'relatorio/r1001/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1001Component,
  },
  {
    path: 'relatorio/r1002/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1002Component,
  },
  {
    path: 'relatorio/r1003/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1003Component,
  },
  {
    path: 'relatorio/r1004/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1004Component,
  },
  {
    path: 'relatorio/r1009/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1009Component,
  },
  {
    path: 'relatorio/r1010/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1010Component,
  },
  {
    path: 'relatorio/r1012/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1012Component,
  },
  {
    path: 'relatorio/r1014/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1014Component,
  },
  {
    path: 'relatorio/r1043/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1043Component,
  },
  {
    path: 'relatorio/r1053/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1053Component,
  },
  {
    path: 'relatorio/r1085/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1085Component,
  },
  {
    path: 'relatorio/r1089/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1089Component,
  },
  {
    path: 'relatorio/r1105/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1105Component,
  },
  {
    path: 'relatorio/r1109/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1109Component,
  },
  {
    path: 'relatorio/r1110/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1110Component,
  },
  {
    path: 'relatorio/r1111/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1111Component,
  },
  {
    path: 'relatorio/r1112/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1112Component,
  },
  {
    path: 'relatorio/r1113/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1113Component,
  },
  {
    path: 'relatorio/r1114/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1114Component,
  },
  {
    path: 'relatorio/r1115/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1115Component,
  },
  {
    path: 'relatorio/r1116/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1116Component,
  },
  {
    path: 'relatorio/r1117/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1117Component,
  },
  {
    path: 'relatorio/r1118/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1118Component,
  },
  {
    path: 'relatorio/r1125/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1125Component,
  },
  {
    path: 'relatorio/r1126/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1126Component,
  },
  {
    path: 'relatorio/r1127/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1127Component,
  },
  {
    path: 'relatorio/r1128/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1128Component,
  },
  {
    path: 'relatorio/r1131/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1131Component,
  },
  {
    path: 'relatorio/r1132/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1132Component,
  },
  {
    path: 'relatorio/r1134/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1134Component,
  },
  {
    path: 'relatorio/r1138/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1138Component,
  },
  {
    path: 'relatorio/r1144/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1144Component,
  },
  {
    path: 'relatorio/r1145/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1145Component,
  },
  {
    path: 'relatorio/r1151/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1151Component,
  },
  {
    path: 'relatorio/r1152/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1152Component,
  },
  {
    path: 'relatorio/r1159/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1159Component,
  },
  {
    path: 'relatorio/r1167/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1167Component,
  },
  {
    path: 'relatorio/r1168/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1168Component,
  },
  {
    path: 'relatorio/r1198/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1198Component,
  },
  {
    path: 'relatorio/r1206/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1206Component,
  },
  {
    path: 'relatorio/r1233/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1233Component,
  },
  {
    path: 'relatorio/r1248/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1248Component,
  },
  {
    path: 'relatorio/r1268/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R1268Component,
  },
  {
    path: 'relatorio/r2000/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2000Component,
  },
  {
    path: 'relatorio/r2001/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2001Component,
  },
  {
    path: 'relatorio/r2002/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2002Component,
  },
  {
    path: 'relatorio/r2003/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2003Component,
  },
  {
    path: 'relatorio/r2004/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2004Component,
  },
  {
    path: 'relatorio/r2005/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2005Component,
  },
  {
    path: 'relatorio/r2006/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2006Component,
  },
  {
    path: 'relatorio/r2007/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2007Component,
  },
  {
    path: 'relatorio/r2008/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2008Component,
  },
  {
    path: 'relatorio/r2009/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2009Component,
  },
  {
    path: 'relatorio/r2010/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2010Component,
  },
  {
    path: 'relatorio/r2011/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2011Component,
  },
  {
    path: 'relatorio/r2014/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2014Component,
  },
  {
    path: 'relatorio/r2012/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2012Component,
  },
  {
    path: 'relatorio/r2013/:idRelatorio/:impressaoDireta',
    component: R2013Component,
  },
  {
    path: 'relatorio/r2015/:idRelatorio/:impressaoDireta',
    component: R2015Component,
  },
  {
    path: 'relatorio/r2016/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2016Component,
  },
  {
    path: 'relatorio/r2017/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2017Component,
  },
  {
    path: 'relatorio/r2018/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2018Component,
  },

  {
    path: 'relatorio/r2019/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2019Component,
  },

  {
    path: 'relatorio/r2020/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2020Component,
  },

  {
    path: 'relatorio/r2021/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2021Component,
  },
  {
    path: 'relatorio/r2022/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2022Component,
  },
  {
    path: 'relatorio/r2023/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2023Component,
  },
  {
    path: 'relatorio/r2024/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2024Component,
  },
  {
    path: 'relatorio/r2025/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2025Component,
  },
  {
    path: 'relatorio/r2026/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2026Component,
  },
  {
    path: 'relatorio/r2027/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2027Component,
  },
  {
    path: 'relatorio/r2028/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2028Component,
  },
  {
    path: 'relatorio/r2029/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2029Component,
  },
  {
    path: 'relatorio/r2030/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2030Component,
  },
  {
    path: 'relatorio/r2031/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2031Component,
  },
  {
    path: 'relatorio/r2032/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2032Component,
  },
  {
    path: 'relatorio/r2033/:idRelatorio/:impressaoDireta',
    canActivate: [AutenticacaoRelatorioGuard],
    component: R2033Component,
  },
  {
    path: '',
    component: PrincipalComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: '**',
    component: PrincipalComponent,
    canLoad: [AutenticacaoGuard],
    canActivate: [AutenticacaoGuard],
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
