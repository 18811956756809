import { Component } from '@angular/core';
import { SaldoBancario } from 'src/app/modelo/saldoBancario';
import { Criterio } from 'src/app/modelo/criterio';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { Transporte } from 'src/app/modelo/transporte';

import { Usuario } from 'src/app/modelo/usuario';
import { Filtro } from 'src/app/modelo/filtro';
import filtros from './filtro';

@Component({
  templateUrl: './saldoBancario.component.html',
  styleUrls: ['./saldoBancario.component.css'],
})
export class SaldoBancarioComponent extends PaginaComponent {
  public dataSaldo: Date;
  public filtros: Filtro[] = filtros;
  public saldoBancarios: SaldoBancario[] = [];
  public totalDisponivel: number;

  listar(criterios: Criterio[], posicao: number = 0, quantidade: number = 0): void {
    this.dataSaldo = this.utilSessao.getCriterio().find((criterio) => criterio.nome == 'DATA_SALDO').valor;
    const usuario: Usuario = this.utilSessao.getUsuario();
    criterios.push(new Criterio('ID_USUARIO_CONTA', usuario.id));
    criterios.push(new Criterio('ID_USUARIO_LOJA', usuario.id));
    criterios.push(new Criterio('ATIVO', 'S'));
    this.comunicacaoService.listar(new Transporte(criterios, posicao, quantidade, true, this.utilSessao.numeroRegistro), this.bibServico.saldoBancario).subscribe((res) => {
      this.saldoBancarios = this.plainToClass(SaldoBancario, res) as any;
      this.converter(this.saldoBancarios);
    });
  }

  converter(saldoBancarios: SaldoBancario[]): void {
    this.totalDisponivel = 0;
    saldoBancarios.forEach((saldoBancario) => {
      this.totalDisponivel += saldoBancario.valorConciliadas;
      if (saldoBancario.numeroBanco) {
        saldoBancario.numeroBanco = saldoBancario.numeroBanco.padStart(3, '0');
      }
    });
  }
}
