<titulo [titulo]="bibDialogo.contaEspecifica" [imagem]="bibImagem.contabilidade" [icone]="bibIcone.mais" (iconeEmt)="adicionar()" [ajuda]="bibDialogo.adicionar + ' ' + bibDialogo.contaEspecifica.toLocaleLowerCase()" [desabilitado]="!temPermissaoEdicao"></titulo>
<div *ngIf="parceiroContasContabilEspecifica.length == 0" class="row">
  <p class="col-sm-12 pl-3">
    {{ bibDialogo.nenhumaAbr + ' ' + bibDialogo.contaEspecifica.toLowerCase() + ', ' }} <strong class="clique" (click)="adicionar()">{{ bibDialogo.cliqueAqui.toLowerCase() }}</strong> {{ bibDialogo.paraAdicionar.toLowerCase() }}
  </p>
</div>
<table *ngIf="parceiroContasContabilEspecifica.length > 0" class="table table-responsive-sm" >
  <thead>
    <tr>
      <th class="l-40 clique" (click)="util.ordenarLista(this.parceiroContasContabilEspecifica, 'loja')">{{ bibDialogo.loja }}</th>
      <th class="l-50 clique" (click)="util.ordenarLista(this.parceiroContasContabilEspecifica, 'contaContabil')">{{ bibDialogo.contaContabil }}</th>
      <th class="l-5"></th>
      <th class="l-5"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let parceiroContaContabilEspecifica of parceiroContasContabilEspecifica; let i = index">
      <tr>
        <td>
          <lista *ngIf="!parceiroContaContabilEspecifica.id || edicaoParceiroContasContabilEspecifica.includes(parceiroContaContabilEspecifica.id)" [campo]="parceiroContaContabilEspecifica.idLoja" (alteracao)="setLoja(parceiroContaContabilEspecifica, $event)" [lista]="listaLojas" [obrigatorio]="true" [focoId]="focoLoja"></lista>
          {{ parceiroContaContabilEspecifica.id && edicaoParceiroContasContabilEspecifica.includes(parceiroContaContabilEspecifica.id) == false ? parceiroContaContabilEspecifica.loja : '' }}
        </td>
        <td>
          <busca *ngIf="!parceiroContaContabilEspecifica.id || edicaoParceiroContasContabilEspecifica.includes(parceiroContaContabilEspecifica.id)" [campo]="parceiroContaContabilEspecifica.contaContabil" (alteracao)="parceiroContaContabilEspecifica.idContaContabil = $event.id" [servico]="bibServico.contaContabil" [buscarPorNumero]="true" [obrigatorio]="true" [criterios]="criteriosEspecifica"></busca>
          {{ parceiroContaContabilEspecifica.id && edicaoParceiroContasContabilEspecifica.includes(parceiroContaContabilEspecifica.id) == false ? parceiroContaContabilEspecifica.contaContabil : '' }}
        </td> 
        <td>
          <btnAdicional [icone]="bibIcone.editar" (btnAdicional)="editar(parceiroContaContabilEspecifica)"></btnAdicional>
        </td>
        <td>
          <btnAdicional [id]="'lixo' + i" [icone]="bibIcone.lixo" (btnAdicional)="excluirParceiroContaContabilEspecifica(i, parceiroContaContabilEspecifica.id)"></btnAdicional>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
