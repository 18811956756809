import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Criterio } from 'src/app/modelo/criterio';
import { Empresa } from 'src/app/modelo/empresa';
import { Generico } from 'src/app/modelo/generico';
import { Identificacao } from 'src/app/modelo/identificacao';
import { IntegracaoUsuario } from 'src/app/modelo/integracaoUsuario';
import { MotivoOcorrencia } from 'src/app/modelo/motivoOcorrencia';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Operacao } from 'src/app/modelo/operacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroArt } from 'src/app/modelo/parceiroArt';
import { ParceiroAtualizacao } from 'src/app/modelo/parceiroAtualizacao';
import { ParceiroCobranca } from 'src/app/modelo/parceiroCobranca';
import { ParceiroContaContabilEspecifica } from 'src/app/modelo/parceiroContaContabilEspecifica';
import { ParceiroContato } from 'src/app/modelo/parceiroContato';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { ParceiroHistorico } from 'src/app/modelo/parceiroHistorico';
import { ParceiroHistoricoLimite } from 'src/app/modelo/parceiroHistoricoLimite';
import { ParceiroNegociacao } from 'src/app/modelo/parceiroNegociacao';
import { ParceiroOcorrencia } from 'src/app/modelo/parceiroOcorrencia';
import { ParceiroTelefone } from 'src/app/modelo/parceiroTelefone';
import { ParceiroVeiculo } from 'src/app/modelo/parceiroVeiculo';
import { Permissao } from 'src/app/modelo/permissao';
import { Relacionamento } from 'src/app/modelo/relacionamento';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { UtilLimiteCredito } from 'src/app/utilitario/utilLimiteCredito';
import { LimiteCreditoHistoricoComponent } from '../../limiteCredito/limiteCreditoHistorico/limiteCreditoHistorico.component';

@Component({
  templateUrl: './parceiroFrm.component.html',
})
export class ParceiroFrmComponent extends PaginaComponent {
  public ano: number = new Date().getFullYear();
  public data: Date = new Date();
  public apresentaNegociacaoRestrita: boolean = false;
  public componente: any = null;
  public cpfParceiroMovimentacao: string;
  public criterioClienteContaContabil: Criterio[] = [new Criterio('EMPRESA_CONTA_CONTABIL_CLIENTE', 'S')];
  public criterioFornecedorContaContabil: Criterio[] = [new Criterio('EMPRESA_CONTA_CONTABIL_FORNECEDOR', 'S')];
  public criterioEspecificaContaContabil: Criterio[] = [new Criterio('EMPRESA_CONTA_CONTABIL_ESPECIFICA', 'S')];
  public edicaoParceiroOcorrencias: number[] = [];
  public ehModalCadastroCliente: boolean = false;
  public empresa: Empresa = this.utilSessao.getEmpresa();
  public empresaUsaContabilidade: boolean;
  public empresaUsaCorrentista: boolean;
  public empresaLimiteCreditoAutomatico: boolean;
  public excluirArts: number[] = [];
  public excluirContatos: number[] = [];
  public excluirDestinos: number[] = [];
  public excluirEnderecos: number[] = [];
  public excluirNegociacoes: number[] = [];
  public excluirOcorrencias: number[] = [];
  public excluirParceiroAtualizacoes: number[] = [];
  public excluirParceiroCobrancas: number[] = [];
  public excluirTelefones: number[] = [];
  public excluirVeiculos: number[] = [];
  public excluirParceiroContasContabilEspecifica: number[] = [];
  public edicaoParceiroContasContabilEspecifica: number[] = [];
  public familias: Generico[] = [];
  public focoNome: boolean = false;
  public idParceiroModal: number;
  public limiteCredito: number = 0;
  public limiteDisponivel: number = 0;
  public limiteMaximo: number = 0;
  public motivoOcorrencias: MotivoOcorrencia[] = [];
  public movimentacaoPorOperacoes: Object = {};
  public movimentacaoProdutos: MovimentacaoProduto[] = [];
  public movimentacoes: Movimentacao[];
  public negociacoes: Negociacao[] = [];
  public negociacoesRestrita: Negociacao[] = [];
  public obrigaCpfCnpj: number = this.utilSessao.getEmpresa().regraCnpjCpf;
  public operacoes: Operacao[] = [];
  public operacoes2: Operacao[] = [];
  public parceiro: Parceiro = new Parceiro();
  public parceiroCriterios: Criterio[] = [new Criterio('ID_MENU', 17)];
  public parceiroHistoricos: ParceiroHistorico[] = [];
  public permissaoLimiteCredito: Permissao = this.utilSessao.getPermissoes().find((permissao) => permissao.idMenu == 123);
  public relacionamentos: Relacionamento[] = [];
  public resumo: boolean = false;
  public tipoParceiro: string;
  public tipoTitulos: TipoTitulo[] = [];
  public tipoVinculo: string;
  public utilLimiteCredito: UtilLimiteCredito = new UtilLimiteCredito();
  public utilizaPesquisaCliente: boolean = false;
  public visualizaHistorico: boolean = false;
  public visualizaMovimentacaoOutroVendedor: boolean = this.utilSessao.getUsuario().permitir == 'S';
  public atencaoCrea: boolean = false;
  public atencaoConsumidorFinal: boolean = false;
  private cobranca: boolean = false;
  public informarNumero: boolean = false;
  public focoNumero: number;
  public parceiroEnderecoPrincipal: ParceiroEndereco = new ParceiroEndereco();
  public integracaoNeogrid: boolean = false;

  public listaPessoas: any[] = [
    { id: 'F', nome: this.bibDialogo.fisica.toUpperCase() },
    { id: 'J', nome: this.bibDialogo.juridica.toUpperCase() },
  ];

  public listaSexos: any[] = [
    { id: 'M', nome: this.bibDialogo.masculino.toUpperCase() },
    { id: 'F', nome: this.bibDialogo.feminimo.toUpperCase() },
  ];

  public listaSimNao: any[] = [
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
  ];

  public listaFormacao: any[] = [
    { id: 'N', nome: this.bibDialogo.nenhuma.toUpperCase() },
    { id: 'A', nome: this.bibDialogo.agronomo.toUpperCase() },
    { id: 'T', nome: this.bibDialogo.tecnicoAgricola.toUpperCase() },
  ];

  public tipoNegociacoes: any[] = [
    { id: 'L', nome: this.bibDialogo.livre.toUpperCase() },
    { id: 'S', nome: this.bibDialogo.semanal.toUpperCase() },
    { id: 'Q', nome: this.bibDialogo.quinzenal.toUpperCase() },
    { id: 'M', nome: this.bibDialogo.mensal.toUpperCase() },
    { id: 'D', nome: this.bibDialogo.diaFixo.toUpperCase() },
    { id: 'P', nome: this.bibDialogo.prazoFixo.toUpperCase() },
    { id: 'A', nome: this.bibDialogo.somenteVendaAVista.toUpperCase() },
  ];

  public listaContribuinte: any[] = [
    { id: '2', nome: this.bibDialogo.isento.toUpperCase() },
    { id: '9', nome: this.bibDialogo.naoContribuinte.toUpperCase() },
    { id: '1', nome: this.bibDialogo.contribuinte.toUpperCase() },
  ];

  public listaDinamicaPagamento: any[] = [
    { id: '1', nome: this.bibDialogo.pagamentoNaLoja.toUpperCase() },
    { id: '2', nome: this.bibDialogo.envio.toUpperCase() },
    { id: '3', nome: this.bibDialogo.contatarCliente.toUpperCase() },
    { id: '4', nome: this.bibDialogo.naoCobrar.toUpperCase() },
  ];

  public estadosCivil: any[] = [
    { id: 1, nome: this.bibDialogo.solteiro.toUpperCase() },
    { id: 2, nome: this.bibDialogo.casado.toUpperCase() },
    { id: 3, nome: this.bibDialogo.separado.toUpperCase() },
    { id: 4, nome: this.bibDialogo.divorciado.toUpperCase() },
    { id: 5, nome: this.bibDialogo.viuvo.toUpperCase() },
  ];

  ngOnInit(): void {
    this.cobranca = this.utilSessao.getIdentificacao('cobranca')?.conteudo;
    this.tipoParceiro = this.utilSessao.getIdentificacao('parametro')?.conteudo;
    this.utilizaPesquisaCliente = this.util.coalesce(this.utilSessao.getIdentificacao('pesquisaCliente')?.conteudo, false);
    this.empresaLimiteCreditoAutomatico = this.utilSessao.getEmpresa().limiteCredito == 1 ? true : false;
    this.empresaUsaContabilidade = this.utilSessao.getEmpresa().contabilidade == 'S' ? true : false;
    this.empresaUsaCorrentista = this.utilSessao.getEmpresa().correntista == 'S' ? true : false;
    this.idParceiroModal = this.utilSessao.getIdentificacao('idParceiro')?.conteudo;
    this.ehModalCadastroCliente = this.utilSessao.getIdentificacao('ehModalCadastroCliente')?.conteudo;
    if (this.idParceiroModal) {
      this.listarParceiroCompleto(this.idParceiroModal);
    } else {
      if (this.ehModalCadastroCliente) {
        this.preencherComDadosDaMovimentacao();
      } else {
        this.setTipoJuridico();
        this.ehSetFocoSexo();
        this.ehAlteracao();
      }
    }
    this.listarFamilia();
    this.listarNegociacao();
    this.listarRelacionamento();
    this.listarOcorrencias();
    this.listarTipoTitulo();
    this.ehConsumidorFinal();
    this.listarPermissoesNeogrid();
  }

  ehConsumidorFinal(): void {
    this.atencaoConsumidorFinal = false;
    if (this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 152).ativo == 'S') {
      if (this.parceiro.tipoCliente == 'S' && this.parceiro.tipoPessoa == 'F') {
        this.atencaoConsumidorFinal = true;
      }
    }
  }

  setTipoJuridico(): void {
    if (this.tipoParceiro == 'FORNECEDOR' || this.tipoParceiro == 'TRANSPORTADORA') {
      this.parceiro.tipoPessoa = 'J';
    }
  }

  ehSetFocoSexo(): void {
    if (this.tipoParceiro == 'COLABORADOR') {
      this.focoNome = true;
    }
  }

  ehAlteracao(): void {
    this.parceiro = this.plainToClass(Parceiro, this.ehAtualizacao(this.parceiro));
    if (this.parceiro.id) {
      this.listarParceiroCompleto(this.parceiro.id);
    } else {
      this.setTipo();
      this.listarNegociacaoRestricao();
    }
  }

  listarParceiroCompleto(id: number = null): void {
    this.listarParceiro(id).subscribe((res) => {
      this.parceiro = this.plainToClass(Parceiro, res[0]) as any;
      this.limiteCredito = this.parceiro.limiteCredito;
      this.limiteDisponivel = this.parceiro.limiteDisponivel;
      this.limiteMaximo = this.parceiro.limiteMaximo;
      this.parceiro.mostrarCampoFaturarMatriz = this.parceiro.idParceiroFaturamento ? 'S' : 'N';
      this.setVinculo();
      this.listarParceiroEndereco();
      this.listarParceiroContato();
      this.listarParceiroTelefone();
      this.listarParceiroVeiculo();
      this.listarParceiroOcorrencia();
      this.listarParceiroDestino();
      this.listarParceiroAtualizacao();
      this.listarParceiroCobranca();
      this.listarNegociacaoRestricao(this.parceiro.id);
      this.listarParceiroNegociacao();
      this.listarParceiroArt();
      this.listarParceiroContaContabilEspecifica();
    });
  }

  listarParceiro(id: number = null): Observable<any> {
    let criterios: Criterio[] = [];
    if (id != null) {
      criterios.push(new Criterio('ID', id));
    } else {
      criterios.push(new Criterio('ID', this.parceiro.id));
    }
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiro);
  }

  listarParceiroEndereco(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroEndereco).subscribe((res) => {
      this.parceiro.parceiroEnderecos = this.plainToClass(ParceiroEndereco, res) as any;
      this.definirEnderecoPrincipal(this.parceiro.parceiroEnderecos);
    });
  }

  definirEnderecoPrincipal(parceiroEnderecos: ParceiroEndereco[]): void {
    const parceiroEnderecoPrincipal: ParceiroEndereco = parceiroEnderecos.find((parceiroEndereco) => parceiroEndereco.idVinculo == 3);
    this.utilSessao.setIdentificacao(new Identificacao('parceiroEnderecoPrincipal', parceiroEnderecoPrincipal));

  }

  listarParceiroContato(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroContato).subscribe((res) => {
      this.parceiro.parceiroContatos = this.plainToClass(ParceiroContato, res) as any;
    });
  }

  listarParceiroTelefone(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroTelefone).subscribe((res) => {
      this.parceiro.parceiroTelefones = this.plainToClass(ParceiroTelefone, res) as any;
    });
  }

  listarParceiroVeiculo(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroVeiculo).subscribe((res) => {
      this.parceiro.parceiroVeiculos = this.plainToClass(ParceiroVeiculo, res) as any;
    });
  }

  listarParceiroContaContabilEspecifica(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroContaContabilEspecifica).subscribe((res) => {
      this.parceiro.parceiroContaContabilEspecificas = this.plainToClass(ParceiroContaContabilEspecifica, res) as any;
    });
  }

  listarParceiroAtualizacao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroAtualizacao).subscribe((res) => {
      this.parceiro.parceiroAtualizacoes = this.plainToClass(ParceiroAtualizacao, res) as any;
    });
  }

  listarParceiroCobranca(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroCobranca).subscribe((res) => {
      this.parceiro.parceiroCobrancas = this.plainToClass(ParceiroCobranca, res) as any;
      this.adicionarCobranca();
    });
  }

  adicionarCobranca(): void {
    if (this.cobranca) {
      const parceiroCobranca: ParceiroCobranca = new ParceiroCobranca();
      parceiroCobranca.idParceiro = this.parceiro.id;
      parceiroCobranca.nomeUsuario = this.utilSessao.getUsuario().nome;
      this.parceiro.parceiroCobrancas.push(parceiroCobranca);
    }
  }

  listarParceiroOcorrencia(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroOcorrencia).subscribe((res) => {
      this.parceiro.parceiroOcorrencias = this.plainToClass(ParceiroOcorrencia, res) as any;
    });
  }

  listarOcorrencias(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.motivoOcorrencia).subscribe((res) => {
      this.motivoOcorrencias = this.plainToClass(MotivoOcorrencia, res) as any;
    });
  }

  listarParceiroDestino(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroDestino).subscribe((res) => {
      this.parceiro.parceiroDestinos = this.plainToClass(ParceiroDestino, res) as any;
    });
  }

  listarNegociacao(): void {
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.negociacao).subscribe((res) => {
      this.negociacoes = this.plainToClass(Negociacao, res) as any;
      this.apresentaNegociacaoRestrita = this.negociacoes.filter((negociacao) => negociacao.restricao == 1).length > 0;
    });
  }

  listarRelacionamento(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.relacionamento).subscribe((res) => {
      this.relacionamentos = this.plainToClass(Relacionamento, res) as any;
    });
  }

  listarNegociacaoRestricao(idParceiro: number = 0): void {
    let criterios: Criterio[] = [new Criterio('RESTRICAO', 1), new Criterio('ATIVO', 'S')];
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.negociacao).subscribe((res) => {
      this.negociacoesRestrita = this.plainToClass(Negociacao, res) as any;
    });
  }

  listarParceiroNegociacao(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroNegociacao).subscribe((res) => {
      this.parceiro.parceiroNegociacoes = this.plainToClass(ParceiroNegociacao, res) as any;
      this.vincularNegociacao();
    });
  }

  vincularNegociacao(): void {
    let negociacoes: Negociacao[] = [];
    this.negociacoes.forEach((negociacao) => {
      let parceiroNegociacao: ParceiroNegociacao = this.parceiro.parceiroNegociacoes.find((parceiroNegociacao) => parceiroNegociacao.idNegociacao == negociacao.id);
      if (negociacao.restricao == 1 && parceiroNegociacao != undefined) {
        negociacoes.push(negociacao);
      }
      if (negociacao.restricao != 1) {
        negociacoes.push(negociacao);
      }
    });
    this.negociacoes = negociacoes;
  }

  listarParceiroArt(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', this.parceiro.id)), this.bibServico.parceiroArt).subscribe((res) => {
      this.parceiro.parceiroArts = this.plainToClass(ParceiroArt, res) as any;
    });
  }

  listarTipoTitulo(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.tipoTitulo).subscribe((res) => {
      this.tipoTitulos = this.plainToClass(TipoTitulo, res) as any;
    });
  }

  listarPermissoesNeogrid(): void {
    const idIntegracaoNeogrid: number = 6;
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_INTEGRACAO', idIntegracaoNeogrid)), this.bibServico.integracaoUsuario).subscribe((res) => {
      this.integracaoNeogrid = this.plainToClass(IntegracaoUsuario, res).length > 0 ? true : false;
    });
  }

  preencherComDadosDaMovimentacao(): void {
    this.parceiro.cnpjCpf = this.utilSessao.getIdentificacao('parceiroCpf')?.conteudo;
    this.parceiro.razaoSocial = this.utilSessao.getIdentificacao('nomeParceiro')?.conteudo;
    this.parceiro.tipoCliente = 'S';
  }

  setParceiroObservacao(observacao: string): void {
    this.parceiro.observacao = observacao;
    if (this.parceiro.observacao && this.parceiro.observacao.length > 0) {
      this.parceiro.mostrarAlerta = 'S';
    } else {
      this.parceiro.mostrarAlerta = 'N';
    }
  }

  persistirParceiro(novo: boolean = false): void {
    if (this.ehValido()) {
      this.parceiro.receitaMensal = this.parceiro.receitaMensal ? this.parceiro.receitaMensal : 0;
      const transporte: Transporte = new Transporte(this.parceiro);
      transporte.adicionar(this.excluirEnderecos);
      transporte.adicionar(this.excluirContatos);
      transporte.adicionar(this.excluirTelefones);
      transporte.adicionar(this.excluirOcorrencias);
      transporte.adicionar(this.excluirDestinos);
      transporte.adicionar(this.excluirNegociacoes);
      transporte.adicionar(this.excluirParceiroAtualizacoes);
      transporte.adicionar(this.excluirArts);
      transporte.adicionar(this.excluirVeiculos);
      transporte.adicionar(this.excluirParceiroCobrancas);
      transporte.adicionar(this.utilSessao.idsPropriedadesExcluir);
      transporte.adicionar(this.excluirParceiroContasContabilEspecifica);
      const ehInsercao: boolean = this.parceiro.id > 0 ? false : true;
      super.persistir(transporte, this.bibServico.parceiro, novo ? new Parceiro() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.limiteCredito = this.parceiro.limiteCredito;
          this.limiteDisponivel = this.parceiro.limiteDisponivel;
          this.limiteMaximo = this.parceiro.limiteMaximo;
          this.definirIds();
          this.setTipo();
          this.limparListas();
          if (ehInsercao && !novo) {
            this.atualizarParceiro(this.parceiro.id);
          }
          if (novo) {
            this.parceiro.id = null;
            this.informarNumero = false;
          }
        }
      });
    }
  }

  ehAdicionarParceiroHistoricoLimite(): void {
    this.parceiro.parceiroHistoricoLimites = [];
    if (this.parceiro.id && (this.limiteCredito != this.parceiro.limiteCredito || this.limiteDisponivel != this.parceiro.limiteDisponivel || this.limiteMaximo != this.parceiro.limiteMaximo)) {
      this.parceiro.parceiroHistoricoLimites.push(this.criarHistoricoLimite());
    }
  }

  criarHistoricoLimite(): ParceiroHistoricoLimite {
    let parceiroHistoricoLimite: ParceiroHistoricoLimite = new ParceiroHistoricoLimite();
    parceiroHistoricoLimite.limiteCredito = this.parceiro.limiteCredito ? this.parceiro.limiteCredito : 0;
    parceiroHistoricoLimite.variacaoLimiteCredito = (this.parceiro.limiteCredito ? this.parceiro.limiteCredito : 0) - (this.limiteCredito ? this.limiteCredito : 0);
    parceiroHistoricoLimite.limiteDisponivel = this.parceiro.limiteDisponivel ? this.parceiro.limiteDisponivel : 0;
    parceiroHistoricoLimite.variacaoLimiteDisponivel = (this.parceiro.limiteDisponivel ? this.parceiro.limiteDisponivel : 0) - (this.limiteDisponivel ? this.limiteDisponivel : 0);
    parceiroHistoricoLimite.limiteMaximo = this.parceiro.limiteMaximo ? this.parceiro.limiteMaximo : 0;
    parceiroHistoricoLimite.variacaoLimiteMaximo = (this.parceiro.limiteMaximo ? this.parceiro.limiteMaximo : 0) - (this.limiteMaximo ? this.limiteMaximo : 0);
    return parceiroHistoricoLimite;
  }

  ehAtualizarParceiro(novo: boolean = false): void {
    if (this.parceiro.id && this.parceiro.tipoCliente == 'S') {
      this.listarParceiro().subscribe((res) => {
        let parceiro: Parceiro = this.plainToClass(Parceiro, res[0]);
        this.parceiro.valorPerda = parceiro.valorPerda;
        this.parceiro.idClienteEspecial = parceiro.idClienteEspecial;
        this.ehValidoParceiroLimite(parceiro);
        this.persistirParceiro(novo);
      });
    } else {
      this.persistirParceiro(novo);
    }
  }

  ehValidoParceiroLimite(parceiro: Parceiro): void {
    if (this.limiteCredito != parceiro.limiteCredito || this.limiteDisponivel != parceiro.limiteDisponivel) {
      if (this.utilSessao.getEmpresa().limiteCredito == 2 && this.limiteDisponivel != parceiro.limiteDisponivel) {
        if (confirm(this.util.substituir(this.bibDialogo.edicaoParceiroLimites, [this.bibDialogo.limiteDisponivel, this.util.moeda(this.limiteDisponivel), this.util.moeda(parceiro.limiteDisponivel), this.bibDialogo.limiteDisponivel, this.util.moeda(this.parceiro.limiteDisponivel)]))) {
          this.atualizarlimitesAdicionarParceiroHistorico(parceiro);
        }
      } else {
        if (confirm(this.util.substituir(this.bibDialogo.edicaoParceiroLimites, [this.bibDialogo.limiteCredito, this.util.moeda(this.limiteCredito), this.util.moeda(parceiro.limiteCredito), this.bibDialogo.limiteCredito, this.util.moeda(this.parceiro.limiteCredito)]))) {
          this.atualizarlimitesAdicionarParceiroHistorico(parceiro);
        }
      }
    } else {
      this.ehAdicionarParceiroHistoricoLimite();
    }
  }

  atualizarlimitesAdicionarParceiroHistorico(parceiro: Parceiro): void {
    this.limiteCredito = parceiro.limiteCredito;
    this.limiteDisponivel = parceiro.limiteDisponivel;
    this.ehAdicionarParceiroHistoricoLimite();
  }

  setTipoNegociacao(tipoNegociacao: string): void {
    this.parceiro.tipoNegociacao = tipoNegociacao;
    this.parceiro.prazoFixo = null;
    this.parceiro.diaFechamento = null;
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoDataNascimento: boolean = this.setEhValidoDataNascimento();
    const ehValidoParcelamento: boolean = this.setEhValidoParcelamento();
    const ehValidoCnpjCpf: boolean = this.setEhValidoCnpjCpf();
    const ehValidoAbreviacaoObrigatorio: boolean = this.setEhValidoAbreviacaoObrigatorio();
    const ehValidoParceiroContato: boolean = this.setEhValidoParceiroContato();
    const ehValidoParceiroTelefone: boolean = this.setEhValidoParceiroTelefone();
    const ehValidoParceiroVeiculo: boolean = this.setEhValidoParceiroVeiculo();
    const ehValidoInscricaoEstadual: boolean = this.setEhValidoInscricaoEstadual();
    const ehValidoParceiroCobranca: boolean = this.setEhValidoParceiroCobranca();
    const ehValidoEmailTamanho: boolean = this.setEhValidoEmailTamanho();
    const ehValidoParceiroContaContabilEspecifica: boolean = this.setEhValidoParceiroContaContabilEspecifica();
    const ehValidoLimiteCredito: boolean = this.utilLimiteCredito.sehEhValidoLimiteCredito([this.parceiro], this.empresaLimiteCreditoAutomatico);
    return ehValidoObrigatorio && ehValidoDataNascimento && ehValidoCnpjCpf && ehValidoAbreviacaoObrigatorio && ehValidoEmailTamanho && ehValidoParceiroContato && ehValidoParcelamento && ehValidoParceiroTelefone && ehValidoParceiroCobranca && ehValidoLimiteCredito && ehValidoInscricaoEstadual && ehValidoParceiroVeiculo && ehValidoParceiroContaContabilEspecifica;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.parceiro.razaoSocial && this.parceiro.tipoPessoa && this.parceiro.idLoja && (this.parceiro.comissionado == 'S' ? this.parceiro.percentualComissao || this.parceiro.percentualComissao == 0 : true) && (this.parceiro.tipoNegociacao == 'P' && this.parceiro.prazoFixo == null ? false : true) && (this.parceiro.cnpjCpf || this.obrigaCpfCnpj != 1)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoDataNascimento(): boolean {
    if (this.parceiro.dataNascimento) {
      if (this.parceiro.dataNascimento > this.data) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.dataNascimentoIncorreta));
        return false;
      }
    }
    return true;
  }

  setEhValidoParcelamento(): boolean {
    if ((this.parceiro.tipoNegociacao == 'D' && !this.parceiro.diaFechamento) || (this.parceiro.tipoNegociacao == 'P' && this.parceiro.prazoFixo == 0)) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  setEhValidoCnpjCpf(): boolean {
    if (this.util.validarCnpjCpf(this.parceiro.cnpjCpf)) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.parceiro.tipoPessoa === 'F' ? this.bibDialogo.cpfIncorreto : this.bibDialogo.cnpjIncorreto));
    return false;
  }

  setEhValidoAbreviacaoObrigatorio(): boolean {
    if (this.parceiro.tipoColaborador == 'S') {
      if (this.parceiro.nomeFantasia) {
        if (this.parceiro.nomeFantasia.length > 3) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.campoAbreviacaoTresCaracteres));
          return false;
        }
        return true;
      }
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
      return false;
    }
    return true;
  }

  setEhValidoParceiroContato(): boolean {
    let contagemParceiroContato: number = 0;
    if (this.parceiro.parceiroContatos.length > 0) {
      this.parceiro.parceiroContatos.forEach((parceiroContato) => {
        if (parceiroContato.nome == null) {
          this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.informeNomeDoContatoParaVinculo, [parceiroContato.vinculo])));
          contagemParceiroContato++;
        }
      });
    }
    if (contagemParceiroContato > 0) {
      return false;
    }
    return true;
  }

  setEhValidoParceiroTelefone(): boolean {
    let contagemParceiroTelefone: number = 0;
    if (this.parceiro.parceiroTelefones.length > 0) {
      this.parceiro.parceiroTelefones.forEach((parceiroTelefone) => {
        if (parceiroTelefone.telefone == null) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeNumeroDoTelefone));
          contagemParceiroTelefone++;
        }
      });
    }
    if (contagemParceiroTelefone > 0) {
      return false;
    }
    return true;
  }

  setEhValidoParceiroCobranca(): boolean {
    let contagemParceiroCobranca: number = 0;
    if (this.parceiro.parceiroCobrancas.length > 0) {
      this.parceiro.parceiroCobrancas.forEach((parceiroCobranca) => {
        if (parceiroCobranca.formaCobranca == null || parceiroCobranca.formaCobranca == '' || parceiroCobranca.observacao == null || parceiroCobranca.observacao == '') {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
          contagemParceiroCobranca++;
        }
      });
    }
    if (contagemParceiroCobranca > 0) {
      return false;
    }
    return true;
  }

  setEhValidoEmailTamanho(): boolean {
    let mensagem: string = '';
    if (this.parceiro.email && this.parceiro.email.length > 50) {
      mensagem = this.bibDialogo.ajudaEmailTamanho;
      let quantidadeEmail: number = 0;
      let emailParceiro: string = this.parceiro.email;
      if (emailParceiro && emailParceiro.length > 50) {
        if (emailParceiro.split(';').length > 0) {
          emailParceiro.split(';').forEach((email) => {
            if (email.replace(';', '')) {
              quantidadeEmail += 1;
            }
          });
        }
      }
      //mensagem += quantidadeEmail > 1 ? '. ' + this.util.substituir(this.bibDialogo.emailMensagensVarios, [this.bibDialogo.contato]) : "";
    }
    // if (mensagem != '') {
    //   this.utilSessao.setResultado(new Resultado(false, mensagem));
    //   return false;
    // }
    return true;
  }

  setEhValidoParceiroVeiculo(): boolean {
    let contagemParceiroVeiculo: number = 0;
    if (this.parceiro.parceiroVeiculos.length > 0) {
      this.parceiro.parceiroVeiculos.forEach((parceiroVeiculo) => {
        if (parceiroVeiculo.nome == null) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeNomeDoVeiculo));
          contagemParceiroVeiculo++;
        }
      });
    }
    if (contagemParceiroVeiculo > 0) {
      return false;
    }
    return true;
  }

  setEhValidoParceiroContaContabilEspecifica(): boolean {
    let contagemParceiroContaContabilEspecifica: number = 0;
    if (this.parceiro.parceiroContaContabilEspecificas.length > 0) {
      this.parceiro.parceiroContaContabilEspecificas.forEach((parceiroContaContabilEspecifica) => {
        if (parceiroContaContabilEspecifica.idLoja == null || parceiroContaContabilEspecifica.idContaContabil == null) {
          this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
          contagemParceiroContaContabilEspecifica++;
        }
      });
    }
    if (contagemParceiroContaContabilEspecifica > 0) {
      return false;
    }
    return true;
  }

  setEhValidoInscricaoEstadual(): boolean {
    const contemLetras: any = /(?=(?:.*?[A-Z]))/;
    if (this.parceiro.contribuinte == 1) {
      if (this.parceiro.inscricaoEstadual == null || contemLetras.exec(this.parceiro.inscricaoEstadual)) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.inscricaoEstadualNaoPodeConterLetras));
        return false;
      }
      if (this.parceiro.inscricaoEstadual != null && (this.parceiro.inscricaoEstadual.length < 2 || this.parceiro.inscricaoEstadual.length > 14)) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.inscricaoEstadualNumeroDigitos));
        return false;
      }
    }
    return true;
  }

  atualizarParceiro(id: number): void {
    if (id != null && id != 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.parceiro).subscribe((res) => {
        this.parceiro.numero = (this.plainToClass(Parceiro, res[0]) as any).numero;
        this.parceiro.idContaContabil = (this.plainToClass(Parceiro, res[0]) as any).idContaContabil;
        this.parceiro.idContaContabilFornecedor = (this.plainToClass(Parceiro, res[0]) as any).idContaContabilFornecedor;
        this.parceiro.idContaContabilEspecifica = (this.plainToClass(Parceiro, res[0]) as any).idContaContabilEspecifica;
      });
    }
  }

  definirIds(): void {
    this.definirId([this.parceiro], this.bibClasse.parceiro, true);
    this.definirId(this.parceiro.parceiroEnderecos, this.bibClasse.parceiroEndereco);
    this.definirId(this.parceiro.parceiroContatos, this.bibClasse.parceiroContato);
    this.definirId(this.parceiro.parceiroTelefones, this.bibClasse.parceiroTelefone);
    this.definirId(this.parceiro.parceiroOcorrencias, this.bibClasse.parceiroOcorrencia);
    this.definirId(this.parceiro.parceiroDestinos, this.bibClasse.parceiroDestino);
    this.definirId(this.parceiro.parceiroNegociacoes, this.bibClasse.parceiroNegociacao);
    this.definirId(this.parceiro.parceiroAtualizacoes, this.bibClasse.parceiroAtualizacao);
    this.definirId(this.parceiro.parceiroArts, this.bibClasse.parceiroArt);
    this.definirId(this.parceiro.parceiroHistoricoLimites, this.bibClasse.parceiroHistoricoLimite);
    this.definirId(this.parceiro.parceiroCobrancas, this.bibClasse.parceiroCobranca);
    this.definirId(this.parceiro.parceiroContaContabilEspecificas, this.bibClasse.parceiroContaContabilEspecifica);
  }

  limparListas(): void {
    this.excluirArts = [];
    this.excluirContatos = [];
    this.excluirDestinos = [];
    this.excluirEnderecos = [];
    this.excluirNegociacoes = [];
    this.excluirOcorrencias = [];
    this.excluirParceiroAtualizacoes = [];
    this.excluirParceiroCobrancas = [];
    this.excluirTelefones = [];
    this.excluirVeiculos = [];
    this.excluirParceiroContasContabilEspecifica = [];
    this.parceiro.parceiroHistoricoLimites = [];
    this.edicaoParceiroOcorrencias = [];
  }

  listarFamilia(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.familia).subscribe((res) => {
      this.familias = this.plainToClass(Generico, res) as any;
    });
  }

  setParceiro(parceiro: Parceiro, tipo: string, valor: string): void {
    switch (tipo.toUpperCase()) {
      case this.bibDialogo.cliente.toUpperCase():
        parceiro.tipoCliente = valor;
        break;
      case this.bibDialogo.fornecedor.toUpperCase():
        parceiro.tipoFornecedor = valor;
        break;
      case this.bibDialogo.transportadora.toUpperCase():
        parceiro.tipoTransportadora = valor;
        break;
      case this.bibDialogo.colaborador.toUpperCase():
        parceiro.tipoColaborador = valor;
        parceiro.tipoPessoa = 'F';
        break;
    }
    this.setVinculo();
  }

  setTipo(): void {
    switch (this.tipoParceiro) {
      case this.bibDialogo.fornecedor.toUpperCase():
        this.setParceiro(this.parceiro, this.bibDialogo.fornecedor.toLowerCase(), 'S');
        break;
      case this.bibDialogo.transportadora.toUpperCase():
        this.setParceiro(this.parceiro, this.bibDialogo.transportadora.toLowerCase(), 'S');
        break;
      case this.bibDialogo.colaborador.toUpperCase():
        this.setParceiro(this.parceiro, this.bibDialogo.colaborador.toLowerCase(), 'S');
        break;
      default:
        this.setParceiro(this.parceiro, this.bibDialogo.cliente.toLowerCase(), 'S');
        break;
    }
  }

  setVinculo(): void {
    if (this.parceiro.tipoFornecedor == 'S' || this.parceiro.tipoTransportadora == 'S') {
      this.tipoVinculo = "'F','-','T'";
    }
    if (this.parceiro.tipoCliente == 'S' || this.parceiro.tipoColaborador == 'S') {
      this.tipoVinculo = "'C','-','T'";
    }
  }

  setTipoPessoa(parceiro: Parceiro, valor: string): void {
    parceiro.tipoPessoa = valor;
    if (parceiro.tipoPessoa == 'J') {
      parceiro.dataNascimento = null;
      parceiro.sexo = null;
      parceiro.contribuinte = 1;
      parceiro.estadoCivil = null;
    } else {
      parceiro.contribuinte = 9;
      parceiro.cnpjCpf = null;
    }

    this.ehConsumidorFinal();
  }

  setCnpjCpf(parceiro: Parceiro, valor: string): void {
    parceiro.cnpjCpf = valor == '' ? null : valor;
    parceiro.ehValidoCnpjCpf = this.util.validarCnpjCpf(parceiro.cnpjCpf);
  }

  setValor(comissionado: string): void {
    this.parceiro.comissionado = comissionado;
    this.parceiro.percentualComissao = comissionado === 'N' ? 0 : this.parceiro.percentualComissao;
  }

  setRazaoSocial(nome: string): void {
    this.parceiro.razaoSocial = nome;
    if (this.parceiro.tipoColaborador == 'S') {
      this.parceiro.nomeFantasia = nome.substring(0, 3);
    }
  }

  visualizarHistorico(idParceiro: number): void {
    this.visualizaHistorico = this.visualizaHistorico == false ? true : false;
    if (this.visualizaHistorico) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIRO', idParceiro)), this.bibServico.parceiroHistorico).subscribe((res) => {
        this.parceiroHistoricos = this.plainToClass(ParceiroHistorico, res) as any;
      });
    }
  }

  visualizarResumoMovimentacao(idParceiro: number): void {
    this.resumo = this.resumo == false ? true : false;
    this.movimentacaoProdutos = [];
    this.operacoes = [];
    this.movimentacoes = [];
    this.movimentacaoPorOperacoes = {};
    if (this.resumo) {
      this.listarMovimentacao(idParceiro);
    }
  }

  listarMovimentacao(idParceiro: number): void {
    this.comunicacaoService.listar(new Transporte([new Criterio('ID_PARCEIRO', idParceiro), new Criterio('STATUS', 'S')]), this.bibServico.movimentacao).subscribe((res) => {
      this.movimentacoes = this.plainToClass(Movimentacao, res) as any;
      let idsMovimentacao: number[] = this.movimentacoes.map((movimentacao) => movimentacao.id);
      this.listarMovimentacaoProduto(idsMovimentacao);
    });
  }

  listarMovimentacaoProduto(idsMovimentacao: number[]): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACOES', idsMovimentacao.toString())), this.bibServico.movimentacaoProduto).subscribe((res) => {
      this.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
      this.setOperacoes();
    });
  }

  setOperacoes(): void {
    const IDENTIFICACAO_REQUISICAO: number = 5;
    this.movimentacoes.forEach((movimentacao) => {
      if (this.operacoes.find((operacao) => operacao.id == movimentacao.idOperacao) == null) {
        if ((movimentacao.identificacao == IDENTIFICACAO_REQUISICAO && this.parceiro.tipoCliente == 'S') == false) {
          const operacao: Operacao = new Operacao();
          operacao.id = movimentacao.idOperacao;
          operacao.nome = movimentacao.operacao;
          this.operacoes.push(operacao);
        }
      }
      if (this.movimentacaoPorOperacoes[movimentacao.operacao]) {
        this.movimentacaoPorOperacoes[movimentacao.operacao].push(movimentacao);
      } else {
        this.movimentacaoPorOperacoes[movimentacao.operacao] = [movimentacao];
      }
    });
    this.operacoes.sort((a, b) => (a['id'] == b['id'] ? 0 : a['id'] > b['id'] ? 1 : -1));
    this.vincularMovimentacaoProdutoOperacao();
  }

  vincularMovimentacaoProdutoOperacao(): void {
    this.operacoes.forEach((operacao) => {
      this.movimentacaoPorOperacoes[operacao.nome].forEach((movimentacao: Movimentacao) => {
        movimentacao.movimentacaoProdutos = this.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == movimentacao.id);
      });
    });
  }

  abrirModalHistorico(parceiro: Parceiro): void {
    event.preventDefault();
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', parceiro.id));
    this.utilSessao.setIdentificacao(new Identificacao('parceiro', parceiro.razaoSocial ? parceiro.razaoSocial : parceiro.nomeFantasia));
    this.componente = LimiteCreditoHistoricoComponent;
  }

  setCrea(crea: string): void {
    this.parceiro.crea = crea;
    this.atencaoCrea = true;
  }

  public usarLimite(simNao: string) {
    this.parceiro.usaLimiteCredito = simNao;
    if (simNao == 'N') {
      this.parceiro.limiteMaximo = 0;
      this.parceiro.limiteCredito = 0;
      this.parceiro.limiteDisponivel = 0;
    }
  }

  setNegociacao(idNegociacao: number): void {
    this.parceiro.idNegociacao = idNegociacao;
  }

  setMostrarCampoFaturarMatriz(selecionado: string = null): void {
    this.parceiro.mostrarCampoFaturarMatriz = selecionado;
  }

  editarCampo(): void {
    if (confirm(this.bibDialogo.alertaNumeroManual)) {
      this.informarNumero = true;
      this.focoNumero = Math.random();
    }
  }
}
