export class bibServicoBoletoTecnoSpeed {
  private url: string;
  private urlRemessa: string;
  private urlEmail: string;

  constructor(homologacao: boolean) {
    if (!homologacao) {
      this.url = '';
      this.url = 'https://plugboleto.com.br/api/v1';
    } else {
      this.url = 'https://homologacao.plugboleto.com.br/api/v1';
    }
  }

  getUrlBoletoPersistir(servico: string): string {
    return this.url + `/${servico}/lote`;
  }

  getUrlEnviarEmail(servico: string): string {
    return this.url + `/${servico}/lote`;
  }

  getUrlConsultaBoleto(servico: string, idIntegracao: string): string {
    return this.url + `/${servico}?idintegracao=${idIntegracao}`;
  }

  getUrlBoletoPdf(servico: string): string {
    return this.url + `/${servico}/impressao/lote`;
  }

  getUrlGerarBoletoPdf(servico: string, protocolo: string): string {
    return this.url + `/${servico}/impressao/${protocolo}`;
  }

  getUrlBoletoRemessa(servico: string): string {
    return this.url + `/${servico}/lote`;
  }

  getUrlBoletoCancelamento(servico: string): string {
    return this.url + `/${servico}/baixa/lote`;
  }

  getUrlCancelamento(servico: string, protocolo: string): string {
    return this.url + `/${servico}/baixa/lote/${protocolo}`;
  }

  getUrlAlterarBoleto(servico: string): string {
    return this.url + `/${servico}/altera/lote`;
  }

  getUrlBoletoAlterado(servico: string, protocolo: string): string {
    return this.url + `/${servico}/altera/lote/${protocolo}`;
  }
}
