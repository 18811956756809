const bibClasse = {
  aprovacao: 'Aprovacao',
  cheque: 'Cheque',
  classificacaoOperacao: 'classificacaoOperacao',
  cte: 'cte',
  cteDocumentoFiscal: 'cteDocumentoFiscal',
  cteInformacaoCarga: 'cteInformacaoCarga',
  comissao: 'Comissao',
  configuracaoBalancoPatrimonial: 'configuracaoBalancoPatrimonial',
  configuracaoDemonstrativoResultado: 'configuracaoDemonstrativoResultado',
  configuracaoFinanceiroContabil: 'configuracaoFinanceiroContabil',
  configuracaoFinanceiroTotalContabil: 'ConfiguracaoFinanceiroTotalContabil',
  configuracaoMovimentacaoContabil: 'configuracaoMovimentacaoContabil',
  configuracaoMovimentacaoTotalContabil: 'ConfiguracaoMovimentacaoTotalContabil',
  configuracaoTransferenciaTotalContabil: 'ConfiguracaoTransferenciaTotalContabil',
  criterio: 'Criterio',
  empresa: 'empresa',
  empresaFilial: 'empresaFilial',
  empresaOperacaoBloqueio: 'EmpresaOperacaoBloqueio',
  empresaOperacaoContabil: 'EmpresaOperacaoContabil',
  empresaOperacaoContabilFinanceiro: 'EmpresaOperacaoContabilFinanceiro',
  equipe: 'equipe',
  equipeParceiro: 'equipeParceiro',
  exception: 'Exception',
  filtro: 'Filtro',
  financeiro: 'Financeiro',
  financeiroBaixa: 'FinanceiroBaixa',
  financeiroBoleto: 'FinanceiroBoleto',
  grupoAcesso: 'grupoAcesso',
  grupoAcessoHorario: 'GrupoAcessoHorario',
  grupoAcessoLocal: 'GrupoAcessoLocal',
  grupoContaContabeil: 'GrupoContaContabeil',
  grupoContabil: 'grupoContabil',
  grupoIcms: 'GrupoIcms',
  grupoIcmsSt: 'GrupoIcmsSt',
  grupoParceiroComissao: 'GrupoParceiroComissao',
  grupoUsuario: 'GrupoUsuario',
  grupoUsuarioMenu: 'GrupoUsuarioMenu',
  grupoValor: 'GrupoValor',
  integracaoUsuario: 'IntegracaoUsuario',
  lancamentoContabil: 'LancamentoContabil',
  loja: 'loja',
  lojaEstado: 'LojaEstado',
  mdfe: 'mdfe',
  mdfeDocumentoFiscal: 'mdfeDocumentoFiscal',
  mdfePercurso: 'mdfePercurso',
  menuEmpresa: 'MenuEmpresa',
  menuModuloEmpresa: 'MenuModuloEmpresa',
  moduloEmpresa: 'moduloEmpresa',
  movimentacao: 'Movimentacao',
  movimentacaoCancelamento: 'MovimentacaoCancelamento',
  movimentacaoChave: 'MovimentacaoChave',
  movimentacaoLancamentoContabil: 'MovimentacaoLancamentoContabil',
  movimentacaoNfe: 'MovimentacaoNfe',
  movimentacaoParcela: 'MovimentacaoParcela',
  movimentacaoProduto: 'MovimentacaoProduto',
  movimentacaoProdutoImposto: 'MovimentacaoProdutoImposto',
  movimentacaoQuantidade: 'MovimentacaoQuantidade',
  parceiroCobranca: 'ParceiroCobranca',
  nomenclaturaEmpresa: 'NomenclaturaEmpresa',
  parceiro: 'parceiro',
  parceiroArt: 'ParceiroArt',
  parceiroAtualizacao: 'ParceiroAtualizacao',
  parceiroContato: 'ParceiroContato',
  parceiroContaContabilEspecifica: 'parceiroContaContabilEspecifica',
  parceiroDestino: 'ParceiroDestino',
  parceiroEndereco: 'ParceiroEndereco',
  parceiroHistoricoLimite: 'parceiroHistoricoLimite',
  parceiroNegociacao: 'ParceiroNegociacao',
  parceiroOcorrencia: 'ParceiroOcorrencia',
  parceiroTelefone: 'ParceiroTelefone',
  parceiroVeiculo: 'ParceiroVeiculo',
  patrimonial: 'Patrimonial',
  patrimonialLancamentoContabil: 'PatrimonialLancamentoContabil',
  produto: 'Produto',
  produtoEstoqueMinimoMaximo: 'ProdutoEstoqueMinimoMaximo',
  produtoFormulado: 'produtoFormulado',
  produtoFormuladoDose: 'ProdutoFormuladoDose',
  produtoFoto: 'ProdutoFoto',
  produtoIcms: 'ProdutoIcms',
  produtoIcmsEntrada: 'ProdutoIcmsEntrada',
  produtoIcmsSaida: 'produtoIcmsSaida',
  produtoIcmsSt: 'ProdutoIcmsSt',
  produtoPisCofins: 'ProdutoPisCofins',
  produtoParceiroComissao: 'ProdutoParceiroComissao',
  produtoUnidade: 'ProdutoUnidade',
  produtoValor: 'ProdutoValor',
  receituario: 'receituario',
  receituarioEletronico: 'receituarioEletronico',
  relatorioAdicionalEmpresa: 'RelatorioAdicionalEmpresa',
  relatorioGrupoUsuario: 'RelatorioGrupoUsuario',
  relatorioModuloEmpresa: 'RelatorioModuloEmpresa',
  relatorioUsuario: 'RelatorioUsuario',
  romaneioEntrega: 'romaneioEntrega',
  romaneioEntregaDocumentoFiscal: 'romaneioEntregaDocumentoFiscal',
  roteiroContabil: 'roteiroContabil',
  roteiroContabilRateio: 'RoteiroContabilRateio',
  tabelaPreco: 'tabelaPreco',
  tabelaPrecoCliente: 'TabelaPrecoCliente',
  tabelaPrecoColaborador: 'TabelaPrecoColaborador',
  tabelaPrecoConfiguracao: 'TabelaPrecoConfiguracao',
  tabelaPrecoGrupo: 'TabelaPrecoGrupo',
  tabelaPrecoProduto: 'TabelaPrecoProduto',
  tipoTitulo: 'tipoTitulo',
  tipoTituloCaixa: 'TipoTituloCaixa',
  transferencia: 'transferencia',
  transferenciaCheque: 'TransferenciaCheque',
  usuario: 'usuario',
  usuarioConta: 'UsuarioConta',
  usuarioGrupoUsuario: 'UsuarioGrupoUsuario',
  usuarioLoja: 'UsuarioLoja',
  usuarioMenu: 'UsuarioMenu',
  visita: 'Visita',
  visitaFoto: 'VisitaFoto',
  produtoFormuladoRestricao: 'ProdutoFormuladoRestricao',
};
export default bibClasse;
