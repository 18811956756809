<div class="valorTotalizador text-center" [ngClass]="[tipoTotalizador, alinhamento, clique ? 'clique' : '']" (click)="clicar()">
  <div class="d-flex justify-content-center align-items-center flex-direction-column">
    <div class="valorTotalizadorAtencao" *ngIf="atencaoTotalizador">
      <atencao [tipo]="tipoAtencaoTotalizador" [atencao]="atencaoTotalizador"></atencao>
    </div>
    <div>{{ valorTotalizador }}</div>
  </div>
</div>
<div *ngIf="clique" class="informacao-clique">
  <img [src]="'img/seta.png'" height="40px" width="54px" style="left: 500px;" />
  <atencao [atencao]="bibDialogo.cliqueMaisInformacao" [tipo]="bibPropriedade.atencao.informativo"></atencao>
</div>
