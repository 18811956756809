import { Component, Input } from '@angular/core';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibServico from 'src/app/biblioteca/bibServico';
import { ModalNotificacao } from 'src/app/essencial/modal/modal.notificacao';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Formulario } from '../formulario';
import { PesquisaComponent } from '../pesquisa/pesquisa/pesquisa.component';

@Component({
  selector: 'checkLista',
  templateUrl: './checkLista.component.html',
  styleUrls: ['./checkLista.component.css'],
})
export class CheckListaComponent extends Formulario {
  public _lista: any[] = [];
  public bibDialogo = bibDialogo;
  public componente: any = null;
  public ehModoClaro: boolean = this.utilSessao.getUsuario().usaModoClaro == 'S';
  public todos: boolean = false;

  @Input() criterios: Criterio[] = [];

  @Input()
  set lista(lista: any[]) {
    this._lista = lista;
    if (lista && lista.length == 1 && this.obrigatorio) {
      this.emitirAlteracao(this._lista);
    }
  }

  private _tipo: string = '';
  @Input()
  set tipo(tipo: string) {
    if (tipo) {
      this._tipo = tipo;
      if (this._lista.length == 0) {
        this.sugerirLoja();
      }
    }
  }

  constructor(public modalNotificacao: ModalNotificacao) {
    super();
  }

  ngOnInit(): void {
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this._tipo) {
        this._lista = identificacao.conteudo;
        this.alterar();
      }
    });
  }

  sugerirLoja(): void {
    if (this._tipo == bibServico.loja) {
      if (this.utilSessao.getLojas().length == 1) {
        if (this.utilSessao.getIdLoja()) {
          const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == this.utilSessao.getIdLoja());
          loja.razaoSocial = loja.nomeFantasia;
          this._lista = [loja];
        } else if (this.obrigatorio) {
          this._lista = this.utilSessao.getLojas();
          this.todos = true;
        }
        this.alterar();
      }
    }
  }

  alterar(): void {
    this.emitirAlteracao(this._lista);
  }

  abrirModal(): void {
    if (!this.desabilitado) {
      this.utilSessao.setIdentificacao(new Identificacao('servico', this._tipo));
      this.utilSessao.setIdentificacao(new Identificacao('lista-' + this._tipo, this._lista ? this._lista : []));
      this.utilSessao.setIdentificacao(new Identificacao('nomePesquisa', this._rotulo));
      this.utilSessao.setIdentificacao(new Identificacao('criterios', this.criterios));
      this.utilSessao.setIdentificacao(new Identificacao('filtro', false));
      this.componente = PesquisaComponent;
    }
  }

  limpar(): void {
    if (this.obrigatorio) {
      this.todos = true;
    }
    this._lista = [];
    this.campoAtual = '';
    this.emitirAlteracao(this._lista);
  }
}
