<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoGrupoEmt)="agrupar(roteiroContabeis, $event)" (filtroEmt)="listarRelatorio($event)" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <th class="r-1">{{ bibDialogo.numero }}</th>
      <th class="r">{{ bibDialogo.nome }}</th>
      <th class="r-3">{{ menuCategoriaRoteiroContabilApelido }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let roteiroContabil of roteiroContabeis; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo" [ngClass]="usaLancamentos ? 'sem-borda' : ''">
          <td>{{ roteiroContabil.numero }}</td>
          <td>{{ roteiroContabil.nome }}</td>
          <td>{{ roteiroContabil.categoriaRoteiroContabil }}</td>
        </tr>
        <tr *ngIf="usaLancamentos" r2004itens [roteiroContabeis]="roteiroContabil.roteiroContabilRateios"></tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="roteiroContabeis?.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
