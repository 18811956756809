import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { FinanceiroFrmComponent } from 'src/app/pagina/financeiro/frm/financeiroFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { ParceiroFrmComponent } from 'src/app/pagina/parceiro/frm/parceiroFrm.component';
import { ProdutoFrmComponent } from 'src/app/pagina/produto/frm/produtoFrm.component';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';

@Component({
  templateUrl: './r1132.component.html',
})
export class R1132Component extends RelatorioComponent {
  public filtros = filtros;
  public totais = totais;
  public grupos = grupos;
  public movimentacaoProdutos: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public modal: boolean = false;
  public componente: any;
  public agrupaLoja: boolean = false;
  public agrupaCliente: boolean = false;
  public agrupaProduto: boolean = false;
  public usaValorPendente: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', 'N'));
    criterios.push(new Criterio('ID_EMPRESA', this.utilSessao.getEmpresa().id));
    super.listar(criterios).subscribe(() => {
      this.movimentacaoProdutos = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'MovimentacaoProduto').lista;
      this.totalizar(this.movimentacaoProdutos);
      this.atualizarTotalizador();
      this.utilNotificacao.agrupamento.emit({ id: null });
    });
  }

  abrirModalCliente(idCliente: number): void {
    const MENU_CLIENTE = 5;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idCliente)];
    this.componente = this.util.abrirModal(ParceiroFrmComponent, identificacoes, MENU_CLIENTE);
  }

  abrirModalProduto(idProduto: number): void {
    const MENU_PRODUTO = 7;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idProduto)];
    this.componente = this.util.abrirModal(ProdutoFrmComponent, identificacoes, MENU_PRODUTO);
  }

  abrirModalMovimentacao(idMovimentacao: number): void {
    const MENU_PEDIDO_VENDA = 17;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idMovimentacao)];
    this.componente = this.util.abrirModal(MovimentacaoFrmComponent, identificacoes, MENU_PEDIDO_VENDA);
  }

  abrirModalMovimentacaoFiscal(idMovimentacaoFiscal: number): void {
    const MENU_NOTA_FISCAL = 23;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idMovimentacaoFiscal)];
    this.componente = this.util.abrirModal(MovimentacaoFrmComponent, identificacoes, MENU_NOTA_FISCAL);
  }

  agruparRelatorio(lista: any[], agrupa: any): void {
    this.agrupaLoja = agrupa && agrupa.campo == 'loja';
    this.agrupaCliente = agrupa && agrupa.campo == 'cliente';
    this.agrupaProduto = agrupa && agrupa.campo == 'produto';
    this.agrupar(lista, agrupa, true);
    this.atualizarTotalizador();
  }

  atualizarTotalizador(): void {
    this.posicionarTotalizador('quantidade', this.agrupaProduto, this.agrupaLoja || this.agrupaCliente || this.agrupaProduto ? -1 : 0);
    this.posicionarTotalizador('quantidadeFaturada', this.agrupaProduto, this.agrupaLoja || this.agrupaCliente || this.agrupaProduto ? -1 : 0);
    this.posicionarTotalizador('quantidadePendenteFaturamento', this.agrupaProduto, this.agrupaLoja || this.agrupaCliente || this.agrupaProduto ? -1 : 0);
    this.posicionarTotalizador('valorPendenteFaturamento', this.usaValorPendente, this.agrupaLoja || this.agrupaCliente || this.agrupaProduto ? -1 : 0);
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaValorPendente = this.definirRegra(relatorioAdicional, 281, this.usaValorPendente);
    this.atualizarTotalizador();
  }
}