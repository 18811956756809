import { bibServicoNs } from 'src/app/biblioteca/bibServicoNs';
import { Pis } from 'src/app/integracao/ns/nfe/pis';
import { NsSat } from 'src/app/integracao/ns/sat/nsSat';
import { NsSatEmitente } from 'src/app/integracao/ns/sat/nsSatEmitente';
import { NsSatImposto } from 'src/app/integracao/ns/sat/nsSatImposto';
import { NsSatLocalEntrega } from 'src/app/integracao/ns/sat/nsSatLocalEntrega';
import { NsSatPagamento } from 'src/app/integracao/ns/sat/nsSatPagamento';
import { NsSatProduto } from 'src/app/integracao/ns/sat/nsSatProduto';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { Cst } from 'src/app/modelo/cst';
import { Estado } from 'src/app/modelo/estados';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Produto } from 'src/app/modelo/produto';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { ComunicacaoNsService } from 'src/app/servico/comunicacaoNs.service';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';
import { UtilDocumentoEletronico } from './utilDocumentoEletronico';
import { UtilDocumentoEletronicoNs } from './utilDocumentoEletronicoNs';

export class UtilDocumentoEletronicoNsSat {

  constructor(public comunicacaoService: ComunicacaoService, public utilSessao: UtilSessao, public util: Util, public comunicacaoNsService: ComunicacaoNsService, public bibServicoNs: bibServicoNs, public bibDialogo, public bibServico, public bibClasse, public utilDocumentoEletronico: UtilDocumentoEletronico) { }

  private razaoSocialHomologacao: string = 'NF-E EMITIDA EM AMBIENTE DE HOMOLOGACAO - SEM VALOR FISCAL';
  public ehConsumidorFinalNfce: boolean = false;
  public enviarIbpt: boolean = true;

  transformarMovimentacaoEmSat(movimentacao: Movimentacao): NsSat {
    const utilDocumentoEletronicoNs: UtilDocumentoEletronicoNs = new UtilDocumentoEletronicoNs(this.comunicacaoService, this.utilSessao, this.util, this.comunicacaoNsService, this.bibServicoNs, this.bibDialogo, this.bibServico, this.bibClasse, this.utilDocumentoEletronico);
    const nsSat: NsSat = new NsSat();
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    nsSat.tipo = 'NOTAFISCAL|\nBSAT|001';
    nsSat.identificador = "B|" + movimentacao.id.toString() + "|";
    nsSat.nsSatEmitente = this.gerarEmitenteSat(loja);
    nsSat.nsSatDestinatario = this.gerarDestinatarioSat(movimentacao.idParceiro, movimentacao.idParceiroDestino);
    // nsSat.nsSatLocalEntrega = this.gerarLocalEntregaSat(movimentacao.idParceiro, movimentacao.idParceiroDestino);
    nsSat.nsSatProdutos = this.gerarProdutoSat(movimentacao.id);
    let somaItens: number = 0;
    let somaParcelas: number = 0;
    nsSat.nsSatProdutos.forEach((produto) => somaItens += Number(Number(produto.vUnCom) * Number(produto.qCom)));
    const movimentacaoParcelas = this.utilDocumentoEletronico.movimentacaoParcelas.filter((movimentacaoParcela) => movimentacaoParcela.idMovimentacao == movimentacao.id);
    movimentacaoParcelas.forEach((parcela) => somaParcelas += parcela.getValorParcela());
    const diferenca: number = somaItens - somaParcelas;
    nsSat.nsSatProdutos[nsSat.nsSatProdutos.length - 1].vUnCom = (Number(nsSat.nsSatProdutos[nsSat.nsSatProdutos.length - 1].vUnCom) - (diferenca != 0 ? (diferenca / Number(nsSat.nsSatProdutos[nsSat.nsSatProdutos.length - 1].qCom)) : 0)).toString()
    nsSat.tributos = "\nWSAT|" + (movimentacao.ibptEstadualValor + movimentacao.ibptNacionalValor).toString();
    nsSat.nsSatPagamentos = this.gerarPagamentoSat(movimentacao.id);
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiro = this.utilDocumentoEletronico.parceiros.find((parceiro) => parceiro.id == movimentacao.idParceiro);
    const parceiroEndereco: any = movimentacao.idParceiroDestino ? this.utilDocumentoEletronico.parceiroDestinos.find((parceiroDestino) => (parceiroDestino.idParceiro = parceiro.id)) : this.utilDocumentoEletronico.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.utilDocumentoEletronico.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    nsSat.infCpl = "\n||Z|" + this.utilDocumentoEletronico.gerarInformacaoComplementar(movimentacao, estado.abreviacao).replace(/[\n\r|]/g, ' ');
    return nsSat;
  }

  criarArquivoTxtSat(nsSat: any) {
    let sat: string = this.lerConteudo("", nsSat);;
    this.downloadArquivo(sat, 'text/plain;charset=utf-8', 'txt', nsSat.identificador);
  }

  lerConteudo(sat: string, conteudo: any, separador: string = "|") {
    const camposParaQuebrarLinha: string[] = ['IM', 'tipo', 'numeroCaixa', 'identificador', 'cNPJ', 'cPF', 'uf', 'indRatISSQN', 'separador', 'reservado8', 'tributosIbpt', 'ICMS', 'PIS', 'separador1'];
    for (let campo in conteudo) {
      let valor: any = conteudo[campo];
      if (typeof valor === 'object') {
        sat = this.lerConteudo(sat, valor);
      } else {
        if (campo == 'tipo' || campo == 'identificador') {
          sat += valor;
        } else {
          sat += valor + separador;

        }
      }
      if (camposParaQuebrarLinha.includes(campo)) {
        sat += '\n'
      }
    }
    return sat;
  }

  downloadArquivo(dados: string, tipo: string, extensao: string, nomeArquivo: string): void {
    const arquivo: Blob = new Blob([dados], { type: tipo });
    const elemento: any = window.document.createElement('a');
    elemento.href = window.URL.createObjectURL(arquivo);
    elemento.download = `${nomeArquivo}.${extensao}`;
    document.body.appendChild(elemento);
    elemento.click();
    document.body.removeChild(elemento);
  }


  gerarEmitenteSat(loja: Loja): NsSatEmitente {
    const nsSatEmitente: NsSatEmitente = new NsSatEmitente();
    nsSatEmitente.registro = 'C';
    nsSatEmitente.reservado1 = '';
    nsSatEmitente.reservado2 = '';
    nsSatEmitente.IE = this.util.retirarCaracteresInvalidos(loja.inscricaoEstadual) + '||||';
    nsSatEmitente.reservado3 = '';
    nsSatEmitente.IM = "";
    nsSatEmitente.cRegTribISSQN = "CSAT|1";
    nsSatEmitente.indRatISSQN = "N";
    nsSatEmitente.cNPJ = 'C02|' + this.util.retirarCaracteresInvalidos(loja.cnpj);
    return nsSatEmitente;
  }

  verificaEhConsumidorFinalNfce(parceiro: Parceiro, idParceiroDestino: number): void {
    let cnpjCpfDestinatario: string = '';
    if (idParceiroDestino) {
      cnpjCpfDestinatario = this.util.retirarCaracteresInvalidos(parceiro.parceiroDestinos[0].cnpjCpf ? parceiro.parceiroDestinos[0].cnpjCpf : parceiro.cnpjCpf);
    } else {
      cnpjCpfDestinatario = this.util.retirarCaracteresInvalidos(parceiro.cnpjCpf);
    }
    this.ehConsumidorFinalNfce = cnpjCpfDestinatario == '11111111111' ? true : false;
  }

  gerarDestinatarioSat(idParceiro: number, idParceiroDestino: number): string {
    const parceiro = this.utilDocumentoEletronico.parceiros.find((parceiro) => parceiro.id == idParceiro);
    this.verificaEhConsumidorFinalNfce(parceiro, idParceiroDestino);
    parceiro.parceiroDestinos = this.utilDocumentoEletronico.parceiroDestinos.filter((parceiroDestino) => parceiroDestino.id == idParceiroDestino);
    const cnpjCpf = this.util.retirarCaracteresInvalidos(idParceiroDestino ? parceiro.parceiroDestinos[0].cnpjCpf ? parceiro.parceiroDestinos[0].cnpjCpf : parceiro.cnpjCpf : parceiro.cnpjCpf);
    return this.ehConsumidorFinalNfce ? '' : "E|" + parceiro.razaoSocial + (cnpjCpf.length == 11 ? "\nE03|" + cnpjCpf : "\nE02|" + cnpjCpf);;
  }

  gerarLocalEntregaSat(idParceiro: number, idParceiroDestino: number): NsSatLocalEntrega {
    const nsSatLocalEntrega: NsSatLocalEntrega = new NsSatLocalEntrega();
    const ENDERECO_PRINCIPAL: number = 3;
    const parceiroEndereco: any = idParceiroDestino ? this.utilDocumentoEletronico.parceiroDestinos.find((parceiroDestino) => (parceiroDestino.idParceiro == idParceiro)) : this.utilDocumentoEletronico.parceiroEnderecos.find((parceiroEnderecoBusca) => parceiroEnderecoBusca.idVinculo == ENDERECO_PRINCIPAL);
    const estado: Estado = this.utilDocumentoEletronico.estados.find((estado) => estado.id == parceiroEndereco.idEstado);
    nsSatLocalEntrega.xLgr = parceiroEndereco.tipo + ' ' + parceiroEndereco.endereco;
    nsSatLocalEntrega.nro = parceiroEndereco.numero;
    nsSatLocalEntrega.xCpl = "";
    nsSatLocalEntrega.xBairro = parceiroEndereco.bairro;
    nsSatLocalEntrega.xMun = parceiroEndereco.cidade;
    nsSatLocalEntrega.uf = estado.abreviacao;
    return nsSatLocalEntrega;
  }

  gerarProdutoSat(idMovimentacao: number): NsSatProduto[] {
    let nsSatProdutos: NsSatProduto[] = [];
    const movimentacaoProdutos = this.utilDocumentoEletronico.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idMovimentacao == idMovimentacao);
    movimentacaoProdutos.forEach((movimentacaoProduto, i) => {
      const nsSatProduto: NsSatProduto = new NsSatProduto();
      nsSatProduto.separador = '\nH|' + (i + 1).toString() + '||';
      nsSatProduto.registro = 'I';
      const produto: Produto = this.utilDocumentoEletronico.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
      const cfopEmpresa: CfopEmpresa = this.utilDocumentoEletronico.cfopEmpresas.find((cfopEmpresaBusca) => cfopEmpresaBusca.id == movimentacaoProduto.idCfopEmpresa);
      nsSatProduto.cProd = produto.numero.toString();
      nsSatProduto.cEAN = produto.codigoBarra ? produto.codigoBarra : '';
      nsSatProduto.xProd = produto.nomeFiscal;
      nsSatProduto.NCM = produto.ncm ? produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim() : '';
      nsSatProduto.reservado1 = '';
      nsSatProduto.CFOP = cfopEmpresa.idCfop.toString();
      nsSatProduto.uCom = movimentacaoProduto.abreviacao;
      nsSatProduto.qCom = movimentacaoProduto.quantidade.toString();
      const icmsDesoneradoUnitario: number = movimentacaoProduto.getIcmsDesonerado() > 0 ? (movimentacaoProduto.getIcmsDesonerado() / movimentacaoProduto.getQuantidade()) : 0;
      nsSatProduto.vUnCom = this.util.arredondar((movimentacaoProduto.getValorUnitario() - icmsDesoneradoUnitario), 3).toString();
      nsSatProduto.reservado2 = '';
      nsSatProduto.reservado3 = '';
      nsSatProduto.reservado4 = '';
      nsSatProduto.reservado5 = '';
      nsSatProduto.reservado6 = '';
      nsSatProduto.reservado7 = '';
      nsSatProduto.vDesc = (movimentacaoProduto.descontoRateado + movimentacaoProduto.descontoValor + (movimentacaoProduto.descontoValorUnitario * movimentacaoProduto.quantidade) > 0) ? movimentacaoProduto.descontoRateado + movimentacaoProduto.descontoValor + (movimentacaoProduto.descontoValorUnitario * movimentacaoProduto.quantidade).toFixed(2).toString() : '';
      nsSatProduto.vOutro = (movimentacaoProduto.outroRateado + movimentacaoProduto.despesaRateado) > 0 ? (movimentacaoProduto.outroRateado + movimentacaoProduto.despesaRateado).toFixed(2).toString() : '';
      nsSatProduto.CEST = "0.00";
      nsSatProduto.reservado8 = '';
      nsSatProduto.separador1 = 'ISAT|A';
      nsSatProduto.imposto = this.gerarImpostoSat(movimentacaoProduto);
      nsSatProdutos.push(nsSatProduto);
    });
    return nsSatProdutos;
  }

  gerarImpostoSat(movimentacaoProduto: MovimentacaoProduto, sat: boolean = false): NsSatImposto {
    const imposto = new NsSatImposto();
    imposto.tributosIbpt = 'M|' + (this.enviarIbpt ? this.util.arredondar(movimentacaoProduto.ibptEstadualValor + movimentacaoProduto.ibptNacionalValor).toString() : "0.00");
    imposto.ICMS = this.gerarIcms(movimentacaoProduto);
    imposto.PIS = this.gerarPis(movimentacaoProduto);
    imposto.COFINS = this.gerarCofins(movimentacaoProduto);
    return imposto;
  }

  gerarIcms(movimentacaoProduto: MovimentacaoProduto): string {
    const CSTS_TRIBUTADOS = ["00", "20", "90"];
    const CSTS_NAO_TRIBUTADOS = ["40", "41", "50", "60"];
    const CSOSN_TRIBUTADOS = ["102", "300", "400", "500"];
    const CSOSN_NAO_TRIBUTADOS = ["900"];
    const cstIcms: Cst = this.utilDocumentoEletronico.cstIcmss.find((cstIcmsBusca) => cstIcmsBusca.id == movimentacaoProduto.idCstIcms);
    switch (true) {
      case CSTS_TRIBUTADOS.includes(cstIcms.cst.substring(1)):
        return this.gerarIcmsN02(cstIcms, movimentacaoProduto);

      case CSTS_NAO_TRIBUTADOS.includes(cstIcms.cst.substring(1)):
        return this.gerarIcmsN06(cstIcms, movimentacaoProduto);

      case CSOSN_TRIBUTADOS.includes(cstIcms.cst.substring(1)):
        return this.gerarIcmsN10d(cstIcms, movimentacaoProduto);

      case CSOSN_NAO_TRIBUTADOS.includes(cstIcms.cst.substring(1)):
        return this.gerarIcmsN10h(cstIcms, movimentacaoProduto);

      default:
        return "";
    }
  }

  gerarIcmsN02(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): string {
    return "N02|" + cstIcms.cst[0] + "|" + cstIcms.cst.substring(1) + "|||" + movimentacaoProduto.getIcmsAliquota().toString();
  }

  gerarIcmsN06(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): string {
    return "N06|" + cstIcms.cst[0] + "|" + cstIcms.cst.substring(1);
  }

  gerarIcmsN10d(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): string {
    return "N010d|" + cstIcms.cst[0] + "|" + cstIcms.cst.substring(1);
  }

  gerarIcmsN10h(cstIcms: Cst, movimentacaoProduto: MovimentacaoProduto): string {
    return "N10h|" + cstIcms.cst[0] + "|" + cstIcms.cst.substring(1) + "|||" + movimentacaoProduto.getIcmsAliquota().toString();
  }

  gerarPis(movimentacaoProduto: MovimentacaoProduto): string {
    const pis: Pis = new Pis();
    const cstPis: Cst = this.utilDocumentoEletronico.cstPisCofinss.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstPis);
    const CSTS_TRIBUTADOS = ["01", "02"];
    const CSTS_NAO_TRIBUTADOS = ["04", "05", "06", "07", "08", "09"];
    const CSTS_TRIBUTADOS_POR_QUANTIDADE = ["03"];
    const CSTS_OUTROS = ["49"];
    switch (true) {
      case CSTS_TRIBUTADOS.includes(cstPis.cst):
        return this.gerarPisAliquota(cstPis.cst, movimentacaoProduto);

      case CSTS_TRIBUTADOS_POR_QUANTIDADE.includes(cstPis.cst):
        return this.gerarPisQuantidade(cstPis.cst, movimentacaoProduto);

      case CSTS_NAO_TRIBUTADOS.includes(cstPis.cst):
        return this.gerarPisNaoTributado(cstPis.cst, movimentacaoProduto);

      case CSTS_OUTROS.includes(cstPis.cst):
        return this.gerarPisOutro(cstPis.cst, movimentacaoProduto);

      default:
        return "";
    }
  }

  gerarPisAliquota(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "Q02|" + cst + "|" + movimentacaoProduto.getPisBase().toFixed(2).toString() + "|" + movimentacaoProduto.getPisAliquota().toFixed(2).toString();
  }

  gerarPisQuantidade(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "Q03|" + cst + "|" + movimentacaoProduto.quantidade.toFixed(2).toString() + "|" + movimentacaoProduto.getPisAliquota().toFixed(2).toString();
  }

  gerarPisNaoTributado(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "Q04|" + cst;
  }

  gerarPisOutro(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "Q05|" + cst + "\nQ07" + movimentacaoProduto.getPisBase().toFixed(2).toString() + "|" + movimentacaoProduto.getPisAliquota().toFixed(2).toString();
  }

  gerarCofins(movimentacaoProduto: MovimentacaoProduto): string {
    const cstCofins: Cst = this.utilDocumentoEletronico.cstPisCofinss.find((cstPisCofinsBusca) => cstPisCofinsBusca.id == movimentacaoProduto.idCstCofins);
    const CSTS_TRIBUTADOS = ["01", "02"];
    const CSTS_NAO_TRIBUTADOS = ["04", "05", "06", "07", "08", "09"];
    const CSTS_TRIBUTADOS_POR_QUANTIDADE = ["03"];
    const CSTS_OUTROS = ["49"];
    switch (true) {
      case CSTS_TRIBUTADOS.includes(cstCofins.cst):
        return this.gerarCofinsAliquota(cstCofins.cst, movimentacaoProduto);

      case CSTS_TRIBUTADOS_POR_QUANTIDADE.includes(cstCofins.cst):
        return this.gerarCofinsQuantidade(cstCofins.cst, movimentacaoProduto);

      case CSTS_NAO_TRIBUTADOS.includes(cstCofins.cst):
        return this.gerarCofinsNaoTributado(cstCofins.cst, movimentacaoProduto);

      case CSTS_OUTROS.includes(cstCofins.cst):
        return this.gerarCofinsOutro(cstCofins.cst, movimentacaoProduto);

      default:
        return "";
    }
  }

  gerarCofinsAliquota(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "S02|" + cst + "|" + movimentacaoProduto.getCofinsBase().toFixed(2).toString() + "|" + movimentacaoProduto.getCofinsAliquota().toFixed(2).toString();
  }

  gerarCofinsQuantidade(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "S03|" + cst + "|" + movimentacaoProduto.quantidade.toFixed(2).toString() + "|" + movimentacaoProduto.getCofinsAliquota().toFixed(2).toString();
  }

  gerarCofinsNaoTributado(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "S04|" + cst;
  }

  gerarCofinsOutro(cst: string, movimentacaoProduto: MovimentacaoProduto): string {
    return "S05|" + cst + "\nS07" + movimentacaoProduto.getPisBase().toFixed(2).toString() + "|" + movimentacaoProduto.getPisAliquota().toFixed(2).toString();
  }

  gerarPagamentoSat(idMovimentacao: number): NsSatPagamento[] {
    let nsSatPagamentos: NsSatPagamento[] = [];
    const movimentacaoParcelas = this.utilDocumentoEletronico.movimentacaoParcelas.filter((movimentacaoParcela) => movimentacaoParcela.idMovimentacao == idMovimentacao);
    movimentacaoParcelas.forEach((movimentacaoParcela, i) => {
      const nsSatPagamento: NsSatPagamento = new NsSatPagamento();
      const dinheiro: string = '01';
      const cheque: string = '02';
      const cartaoCredito: string = '03';
      const cartaoDebito: string = '04';
      const outro: string = '99';
      nsSatPagamento.cMP = '\nYA01|';
      switch (movimentacaoParcela.idEspecie) {
        case 1:
          nsSatPagamento.cMP += dinheiro;
          break;

        case 4:
          nsSatPagamento.cMP += cheque;
          break;

        case 10:
          nsSatPagamento.cMP += cartaoCredito;
          break;

        case 2:
          nsSatPagamento.cMP += cartaoDebito;
          break;

        default:
          nsSatPagamento.cMP += outro;
          break;
      }
      nsSatPagamento.vMP = movimentacaoParcela.getValorParcela();
      nsSatPagamentos.push(nsSatPagamento);
    });
    return nsSatPagamentos;
  }

  gerarCancelamentoUltimaVenda(chave: string): void {
    this.downloadArquivo('EVENTO|\nB|||' + chave + '|\nBSAT|001|', 'text/plain;charset=utf-8', 'txt', 'CANCELAMENTO_' + chave);
  }

  gerarReimpressao(chave: string): void {
    this.downloadArquivo('REIMPRIME|1\nA|' + chave + '|', 'text/plain;charset=utf-8', 'txt', 'REIMPRESSAO_' + chave);
  }

}
