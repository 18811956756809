import { Component } from '@angular/core';
import { R1128Component } from '../r1128/r1128.component';
import filtros from './acessorio/filtro';
import { Empresa } from 'src/app/modelo/empresa';

@Component({
  templateUrl: './r2.component.html',
  styleUrls: ['./r2.component.css'],
})
export class R2Component extends R1128Component {
  public filtros = filtros;
  public empresa: Empresa;

  ngOnInit(): void {
    this.empresa = this.plainToClass(Empresa, this.utilSessao.getEmpresa());
    this.idAdicionalDuplicar = 170;
    this.idAdicionalValorUnitario = 171;
    this.idAdicionalDesconto = 259;
    this.idAdicionalValorUnitarioComDesconto = 260;
  }

  montarFormaPagamento(idMovimentacao: number): string {
    const movimentacaoParcelas: any[] = this.movimentacaoParcelas.filter((movimentacaoParcela) => (movimentacaoParcela.idMovimentacao = idMovimentacao));
    if (movimentacaoParcelas.length > 0) {
      if (movimentacaoParcelas.length > 1) {
        if (movimentacaoParcelas[0].tipoTitulo != movimentacaoParcelas[1].tipoTitulo) {
          return 'Entrada ' + this.tratarTipoTitulo(movimentacaoParcelas[0].tipoTitulo) + ', outra(s) ' + this.tratarTipoTitulo(movimentacaoParcelas[1].tipoTitulo);
        }
      }
      return this.tratarTipoTitulo(movimentacaoParcelas[0].tipoTitulo);
    }
    return '';
  }

  tratarTipoTitulo(tipoTitulo: string): string {
    return tipoTitulo && tipoTitulo.length > 0 ? tipoTitulo : 'não informada';
  }

}
