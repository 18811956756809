import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { FinanceiroFrmComponent } from 'src/app/pagina/financeiro/frm/financeiroFrm.component';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';
import { MovimentacaoFrmComponent } from 'src/app/pagina/movimentacao/frm/estrutura/movimentacaoFrm.component';
import { ParceiroFrmComponent } from 'src/app/pagina/parceiro/frm/parceiroFrm.component';

@Component({
  templateUrl: './r2033.component.html',
})
export class R2033Component extends RelatorioComponent {
  public filtros = filtros;
  public totais = totais;
  public grupos = grupos;
  public financeiros: any[] = [];
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public componente: any;
  public usaAtencipacao: boolean = false;
  public agrupaLoja: boolean = false;
  public agrupaCliente: boolean = false;
  public agrupaDataVencimento: boolean = false;

  listarRelatorio(criterios: Criterio[]): void {
    criterios.push(new Criterio('ID_EMPRESA', this.utilSessao.getEmpresa().id));
    super.listar(criterios).subscribe(() => {
      this.usaAtencipacao = criterios.find((criteiro) => criteiro.nome == 'CALCULAR_ANTECIPACAO' && criteiro.valor == 'S') != null;
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.totalizar(this.financeiros);
      this.atualizarTotalizador();
      this.utilNotificacao.agrupamento.emit({ id: null });
    });
  }

  abrirModalCliente(idCliente: number): void {
    const MENU_CLIENTE = 5;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idCliente)];
    this.componente = this.util.abrirModal(ParceiroFrmComponent, identificacoes, MENU_CLIENTE);
  }

  abrirModalFinanceiro(id: number): void {
    const MENU_RECEITA = 28;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('parametro', "R"), new Identificacao('objeto', id)];
    this.componente = this.util.abrirModal(FinanceiroFrmComponent, identificacoes, MENU_RECEITA);
  }

  abrirModalMovimentacao(idMovimentacao: number): void {
    const MENU_PEDIDO_VENDA = 17;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idMovimentacao)];
    this.componente = this.util.abrirModal(MovimentacaoFrmComponent, identificacoes, MENU_PEDIDO_VENDA);
  }

  abrirModalMovimentacaoFiscal(idMovimentacaoFiscal: number): void {
    const MENU_NOTA_FISCAL = 23;
    const identificacoes: Identificacao[] = [new Identificacao('modalBloqueada', 'S'), new Identificacao('objeto', idMovimentacaoFiscal)];
    this.componente = this.util.abrirModal(MovimentacaoFrmComponent, identificacoes, MENU_NOTA_FISCAL);
  }

  agruparRelatorio(lista: any[], agrupa: any): void {
    this.agrupaLoja = agrupa && agrupa.campo == 'loja';
    this.agrupaCliente = agrupa && agrupa.campo == 'cliente';
    this.agrupaDataVencimento = agrupa && agrupa.campo == 'dataVencimento';
    this.agrupar(lista, agrupa, true);
    this.atualizarTotalizador();
  }

  atualizarTotalizador(): void {
    this.posicionarTotalizador('valorRestante', true, this.agrupaCliente || this.agrupaLoja ? -1 : this.agrupaDataVencimento ? -2 : 0);
    this.posicionarTotalizador('descontoAntecipacao', this.usaAtencipacao, this.agrupaCliente || this.agrupaLoja ? -1 : this.agrupaDataVencimento ? -2 : 0);
    this.posicionarTotalizador('valorRestante-descontoAntecipacao', this.usaAtencipacao, this.agrupaCliente || this.agrupaLoja ? -1 : this.agrupaDataVencimento ? -2 : 0);
  }
}